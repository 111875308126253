.options {
  display: flex;
  flex: 1;
  border-radius: 5px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  margin-bottom: 20px;
}

.options .option {
  padding: 10px;
  cursor: pointer;
  flex: 1;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.options .option.active {
  border-radius: 5px;
  border: 1px solid var(--PRIMARY-BLUE);
  color: var(--PRIMARY-BLUE);
}
