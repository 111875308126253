.boxView {
  width: 280px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  text-align: center;
  padding: 10px 0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}

.quickReplies > .boxView {
  margin-top: 10px;
}

.quickReplies > .boxView:first-child {
  margin-top: 0px;
}
