.singleVariant {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.singleVariant .quantity {
  padding-top: 16px;
  color: #6ec072;
}

.singleVariant .stepper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 14px;
}

.singleVariant .stepper .label {
  color: var(--BLACK);
  opacity: 0.8;
  font-weight: 400;
  padding-right: 14px;
}

.singleVariant .addToCart {
  margin-top: 24px;
  padding: 12px !important;
  width: 230px !important;
  justify-content: center;
}

.singleVariant .addToCart:hover {
  box-shadow: none !important;
}
