.inputWrapper {
  width: 100%;
}

.input {
  position: relative;
}

.input::before {
  content: "";
  position: absolute;
  background: url("../../../../assets/images/calendar.svg") no-repeat;
  width: 20px;
  height: 20px;
  right: 5%;
  top: 37%;
}

.pickerWrapper {
  background-color: var(--WHITE);
  box-shadow: 0px 0px 10px 3px var(--GRAY-SHADOW);
  border-radius: 5px;
}

.footer {
  padding: 8px;
}

.checkbox {
  margin-bottom: 8px;
}
