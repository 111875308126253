.wrap {
}
.sectionHeader {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 15px;
}

.header {
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.header .button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header :global(.ui.loader) {
  display: block;
  top: 70%;
}
.details {
}

.detail {
  margin: 0 0 14px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "name controls"
    "value controls";
  align-items: center;
}

.detail:last-child {
  margin-bottom: 0;
}

.detail .name {
  grid-area: name;
  align-self: end;
  display: block;
  font-size: 13px;
  color: var(--GRAY-MID);
}
.detail :global(.dummy) {
  width: 200px;
}
.detail .value {
  align-self: start;
  grid-area: value;
  display: block;
  color: var(--GRAY-DARK);
  position: relative;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.detail :global(.ui.button) {
  grid-area: controls;
  justify-self: end;
  visibility: hidden;
  width: 48px;
}
.detail:hover :global(.ui.button) {
  visibility: visible;
}

.messageContent {
  text-decoration: none;
  margin-right: 8px;
}

.messageContent a {
  color: var(--PRIMARY-BLUE);
  font-weight: 500;
  overflow-wrap: anywhere;
}

.messageContent :global(.ui.placeholder) {
  min-width: 10vw;
}
