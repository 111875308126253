.container {
  padding: 3px 0 24px;
}

.containerHeader {
  font-weight: 500;
  font-size: 18px;
  padding-bottom: 16px;
}

.container :global(.DateRangePicker) {
  margin-left: unset !important;
}

.actions {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 17px;
}

.wabaDropdown:global(.ui.dropdown) {
  width: 200px;
  height: 40px;
  border: 1px solid var(--DISABLED-BORDER-COLOR) !important;
  margin: 0 20px;
  padding: 12px 20px 11px !important;
  display: flex;
  align-items: center;
}

.wabaDropdown:global(.ui.dropdown > .icon.dropdown) {
  top: 13px !important;
}

.exportButton {
  width: 200px;
  height: 41px;
  justify-content: center;
}

.content {
  display: flex;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  border-radius: 10px;
  width: 640px;
}

.contentBox {
  padding: 24px;
  flex: 1 1 50%;
}

.contentBox .head {
  font-weight: 500;
}

.contentBox:first-child {
  border-right: 1px solid var(--DISABLED-BORDER-COLOR);
}

.borderBottom {
  border-bottom: 1px solid var(--DISABLED-BORDER-COLOR);
  width: 271px;
}

.cell {
  text-align: left;
  padding: 16px 0;
}

.cell:global(.lastItem) {
  padding-bottom: 0 !important;
}

.cellHeader {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}

.cellContent {
}

.subContent {
  padding-left: 12px;
  color: var(--GRAY-MID);
}

.cellTitle {
  font-size: 12px;
  font-weight: 500;
}

.cellSubTitle {
  font-weight: 400;
  font-size: 12px;
}

.cellValue {
  color: var(--BLACK);
}

.cellItem {
  display: flex;
  justify-content: space-between;
}

.leftSelection {
  display: flex;
  align-items: center;
}

.cell img {
  cursor: pointer;
  width: 14px;
  height: 14px;
  margin-left: 4px;
}
