.panes {
  display: flex;
  flex-direction: row;
}

.container {
  display: grid;
  grid-template-areas: "sticky" "scrollable";
  grid-template-rows: 1fr minmax(0, auto);
  height: 100%;
  max-height: 412px;
  flex: 1 1 50%;
  overflow: hidden auto;
}

.channelContainer {
}

.labelContainer {
}

.divider {
  border-left: 1px solid var(--DISABLED-BORDER-COLOR);
}

.searchInput :global(.input input) {
  flex: 1 1 auto !important;
  margin: 5px 20px 5px 10px;
}

.searchInput :global(.input .icon.close) {
  top: 15px !important;
  right: 25px !important;
}

.stickyBlock {
  grid-area: sticky;
  background: var(--WHITE);
  z-index: 1;
}

.stickyBlock :global(.header) {
  margin-top: 0 !important;
  padding-top: 8px !important;
}

.scrollable {
  grid-area: scrollable;
  overflow: hidden auto;
}
