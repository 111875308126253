.nestedMenu {
  justify-items: stretch;
  padding-top: 0;
  padding-bottom: 0;
  top: 38px;
  left: -13px;
}

.nestedMenu :global(> .item) {
  padding: 9px 35px 11px !important;
}

.nestedMenu :global(> .item > a) {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
}

.nestedMenu :global(> .item > a .large) {
  padding: 4px 8px;
  margin-left: 8px;
  background: var(--PRIMARY-BLUE);
}

:global(.ui.dropdown) .item.item.item.item {
  padding: 9px 35px 11px !important;
}

.item a {
  display: flex !important;
  align-items: center;
  flex-flow: row nowrap;
}

.root {
}

.root:global(.item.item),
.root:hover:global(.item.item),
.root:hover:global(.item.item.selected),
.root:global(.item.item.selected) {
  padding: 0px 8px !important;
}

.root :global(.ui.ui.ui.ui.dropdown),
.root:hover :global(.ui.ui.ui.ui.dropdown) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  padding: 8px 0px;
  font-weight: 500;
}

.root :global(.ui.ui.ui.ui.dropdown > .text) {
  margin-top: 0;
  color: GRAY_DARK;
  text-overflow: initial;
  font-weight: 500;
}

html[lang|="zh"] .root :global(.ui.ui.ui.ui.dropdown .text) {
  width: auto !important;
}

.root :global(.ui.ui.ui.ui.dropdown > .icon.dropdown),
.root :global(.ui.ui.ui.ui.dropdown > .icon.chevron) {
  color: inherit;
  left: 0px;
  margin-right: 0;
  position: relative;
  margin-left: 4px;
  margin-bottom: 2px;
}

.root :global(.ui.ui.ui.ui.dropdown > .icon-custom) {
  display: inline-flex;
  margin-left: 7px;
}

.root:global(.selected) .nestedMenu,
.root:hover .nestedMenu {
  padding: 0;
  width: auto;
}

.root:global(.selected .ui.dropdown),
.root:hover :global(.ui.dropdown) {
  padding: 8px;
  background: GRAY_LIGHT;
  border-radius: 5px;
}

.root:global(.selected .ui.dropdown .text) {
  font-weight: 500;
}

.chevron:global(.icon.icon.icon) {
  transform: rotate(-90deg);
  margin: 0 0 0 auto !important;
}

.chevron:global(.icon):before {
  content: url("../../../assets/images/ui/dropdown-chevron-default.svg") !important;
}

.nestedMenu .icon {
  display: inline-flex;
  margin: 0 7px 0 0 !important;
  padding: 0 !important;
}

.nestedMenu .menu {
  width: auto !important;
  right: auto !important;
}

.trigger {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}
