.close {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  cursor: pointer;
  background-color: transparent;
  border-radius: 10px;
}

.close:hover {
  background-color: var(--BODY-BG);
}

.close i {
  width: 16px;
  height: 16px;
  margin: 0;
}

.close.top {
  position: absolute;
  right: 0;
  top: 5px;
}
