.title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 7px;
}

.content {
  font-size: 14px;
  color: var(--GRAY-MID);
  margin-bottom: 14px;
}

.container button {
  width: fit-content;
  background-color: var(--PRIMARY-BLUE) !important;
  color: var(--WHITE) !important;
  padding: 11.5px 16.5px !important;
}

.container button img {
  margin-right: 8px;
}
