.labelPopup:global(.ui.popup.dialog) {
  min-width: 400px !important;
  padding: 10px 11px 4px 11px !important;
  display: flex;
  flex-direction: column;
}

.contentContainer {
  display: flex;
}

.conditionContainer {
  flex: 1 1 50%;
  border-right: 1px solid var(--DISABLED-BORDER-COLOR);
}

.labelListContainer {
  flex: 1 1 50%;
}

.labelListContainer :global(.tag-text) {
  white-space: pre-wrap !important;
}

.labelListContainer :global(.search.segment) {
  margin-left: 9px !important;
}

.labelListContainer :global(.body.segment) {
  padding-bottom: 2px;
}

.labelListContainer :global(.body.segment > .ui.menu) {
  margin: 0 !important;
}
.labelListContainer :global(.body.segment > .ui.menu > .item) {
  padding: 8px 9px !important;
}

.labelListContainer :global(.body.segment .ui.checkbox) {
  margin-right: 3.5 !important;
}

.labelListContainer :global(.body.segment .ui.label.hashtag) {
  margin-left: 3.5px !important;
}

.buttonContainer {
  border-top: 1px solid var(--DISABLED-BORDER-COLOR);
  padding-top: 11px;
  display: flex;
  justify-content: flex-end;
}
