.container {
  background: #0d122c !important;
  color: var(--WHITE) !important;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 14px;
  font-weight: 500;
  cursor: pointer;
}
.header .title {
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.header .title :global(.ui.image) {
  margin-left: 8px;
}
.content {
  font-size: 12px;
  font-weight: 400;
  max-width: 252px;
  white-space: pre-line;
}
