.inner {
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: flex-start;
}

.pane,
:global(.ui.segment) > .pane {
  flex: 1 0 auto !important;
}
