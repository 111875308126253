.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 21px;
}
.header :global(.text) {
  display: flex;
  padding-left: 21px;
  flex-direction: column;
}
.header :global(.text > .header) {
  font-size: 28px;
  font-weight: 500;
}
.wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}
.wrap :global(.back) {
  width: 630px;
}
.container {
  padding: 30px;
  background: #fff;
  border-radius: 8px;
  margin-top: 42px;
  width: 630px;
}
.content :global(.header) {
  font-weight: 500;
  font-size: 16px;
}
.content :global(ul) {
  list-style: none;
  padding: 0px;
  margin-bottom: 21px;
}
.content :global(li) {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.content :global(li svg) {
  margin-right: 9px;
}
.container :global(.ui.button) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px !important;
  width: 100%;
}
