.container {
  position: relative;
  overflow: hidden auto !important;
}

.container :global(.ui.divider:not(.ui.segment .ui.divider)) {
  position: absolute;
  top: 122px;
  width: 100%;
  margin: 0;
}

.header :global(.ui.header) {
  margin: 0;
}

.contentInfo {
  padding: 0;
}

.contentInfo :global(.ui.menu) {
  min-height: unset;
  padding: 0 35px;
}

.contentInfo :global(.ui.menu .item) {
  font-size: 16px;
  padding: 0 0 7px 0 !important;
  margin-right: 49px !important;
  font-weight: 500;
  position: relative;
}

.contentInfo :global(.ui.menu .item:last-child) {
  margin-left: 70px;
}

.contentInfo :global(.ui.menu .item:not(.active)) {
  color: var(--GRAY-DARK);
}

.contentInfo :global(.ui.menu .ui.label) {
  min-height: 22px !important;
  font-size: 13px;
  background: var(--BLACK) !important;
  position: absolute;
  left: 100%;
  bottom: 4px;
}

.contentInfo :global(.ui.segment) {
  flex-direction: column;
  padding: 21px 44px 0 44px;
}

.contentInfo :global(.ui.segment > div) {
  padding: 0;
}

.contentInfo :global(.ui.segment > .ui.divider) {
  margin-top: 21px;
  margin-bottom: -1px;
}

.contentInfo :global(.ui.checkbox label) {
  padding-left: 27px;
}

.title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

.subTitle {
  font-weight: 500;
  margin-top: 22px;
}

.description {
  margin-top: 14px;
  font-weight: 400;
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  min-width: unset !important;
  max-width: 160px;
}

.checkboxGroup :global(.ui.checkbox:not(:first-child)) {
  margin-top: 7px;
}

.container :global(.ui.image) {
  display: inline-block;
}
