.management {
  flex: 1 1;
  background-color: #fff;
  border-radius: 10px;
}

.management :global(.ui.table) {
  margin: 0 0 10px;
}

.wrap {
  display: grid;
  grid-template-rows: auto auto 1fr;
  height: 100%;
}
.body {
  margin: 0 30px;
  justify-self: stretch;
  overflow: auto;
}
.search {
  margin: 30px 30px 0;
}
.addLink {
  color: var(--PRIMARY-BLUE);
}

.header {
  min-height: 86px;
  border-bottom: 1px solid var(--DISABLED-BORDER-COLOR);
}
.headerWrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0 30px;
  height: 100%;
}
.header .title {
  font-size: 20px;
  font-weight: 500;
  flex: 1 1 auto;
}

.header .actions {
  align-self: flex-start;
  margin-top: 30px;
  flex: 0 1 auto;
}

.tableWrap {
  overflow: auto;
}
