.root {
  display: grid;
  height: 100%;
  grid-template-rows: minmax(10em, 35vh) min-content;
}

.code {
  white-space: pre-wrap;
  font-size: 12px;
  overflow-y: auto;
}

.form {
  padding: 24px 0 0;
}

.label {
  font-weight: 500;
  padding: 0 0 7px;
}

.entry {
  padding: 0 0 24px;
}

.entry.last {
  padding-bottom: 0;
}

.row {
  padding: 0 0 24px;
}

.row.last {
  padding-bottom: 0;
}
