.container {
  padding: 30px;
}

.header {
  position: relative;
}

.saveButton {
  position: absolute;
  right: 0;
  padding: 10px 12px !important;
}

.paneContainer {
  display: flex !important;
  flex-direction: column !important;
  margin: unset !important;
}
