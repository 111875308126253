.replyComment {
  line-height: 16.71px;
}

.labelSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  height: 45px;
  margin-right: 38px;
}

.title {
  display: flex;
  align-content: center;
  align-items: center;
}

.title :global(label) {
  margin-right: 7px;
  padding-bottom: 0px !important;
}

.title :global(img) {
  cursor: pointer;
}

.subTitle {
  font-weight: 500;
  margin-bottom: 7px;
}

.button {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.button :global(label) {
  font-weight: 500;
  margin-bottom: 7px;
  margin-right: 20px;
  padding-bottom: 0 !important;
}

.replyComment :global(textarea) {
  height: 140px !important;
}

.errorMessage {
  margin-top: 14px !important;
}
