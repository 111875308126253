.addOnSectionWrapper {
  width: 100%;
}

.addOnContainer {
  display: grid;
  grid-template-columns: 300px 1fr;
  padding: 32px 30px;
}

.addOnContainerCoreFeatures {
  padding-bottom: 32px;
  border-bottom: 1px solid var(--DISABLED-BORDER-COLOR);
}
