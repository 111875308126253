.splash {
  display: grid;
  grid-template-columns: 1fr minmax(auto, 580px) 1fr;
  grid-template-rows: auto;
  grid-template-areas: ". body .";
  margin: 0 auto;
  max-width: 1110px;
  width: 100%;
}

.title {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 6px;
}

.body {
  grid-area: body;
  padding-top: 58px;
}

.items {
  margin: 7px 0 7px;
}

.item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid var(--DISABLED-BORDER-COLOR);
  padding-bottom: 14px;
  margin-bottom: 11px;
}
.item:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.itemImage {
  flex: 0 0 125px;
  max-width: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemDetails {
}

.deviceType {
  font-weight: 500;
  margin-bottom: 5px;
}

.deviceDate {
  margin-bottom: 5px;
  color: var(--GRAY-MID);
}

.deviceName {
  margin-bottom: 5px;
  color: var(--GRAY-MID);
}

.footer {
  margin-bottom: 37px;
}

.appendix {
}
.appendix .prompt {
  font-size: 20px;
  line-height: 1.7;
  font-weight: 500;
}

.actions :global(.ui.button.button-link) {
  padding: 0 !important;
}
.actions :global(.ui.button.button-link) i {
  margin: 0 0 0 7px !important;
  position: relative;
  top: 2px;
}
