.popup {
}

.popup:global(.inverted) {
  background-color: var(--BLACK) !important;
}

.popup:global(.inverted):before {
  background-color: var(--BLACK) !important;
  width: 40px;
  height: 40px;
  margin-left: -21px !important;
  z-index: -1;
}

.controls {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.controls :global(.button) {
  margin-left: 7px;
}
