.wait {
}

.addWait {
  align-self: flex-start;
}

.inputs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 14px 28px;
  align-items: center;
  margin-bottom: 14px;
  position: relative;
}

.readonly .inputs {
  grid-template-columns: 3fr 5fr 4fr;
}

.cell {
}

.cell :global(.ui.input) {
  display: flex;
}

.action {
  display: flex;
  align-items: center;
}

.errors {
  grid-column: 1 / 5;
}

.errorsWrap {
  margin-top: -14px;
}

.wait.readonly :global(.ui.input) {
  opacity: 1;
}

.wait.readonly :global(.ui.input) input,
.wait.readonly :global(.ui.input) .ui.dropdown {
  border: 1px solid #eaeaea !important;
  background-color: #eaeaea !important;
}
