.grid {
  display: grid;
  grid-template-rows: auto;
  grid-gap: 21px;
  overflow-x: visible;
  margin: 0 5px;
  scroll-snap-type: y mandatory;
}

.grid.single {
  grid-template-columns: 1fr;
}

.grid.double {
  grid-template-columns: 1fr 1fr;
}

.grid.triple {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid .item {
  display: grid;
  grid-template-rows: min-content min-content auto;
  padding: 42px 35px 42px 42px;
  cursor: pointer;
  border: 2px solid var(--DISABLED-BORDER-COLOR);
  border-radius: 8px;
  scroll-snap-align: start;
  scroll-padding: 5px;
}

.grid .item:hover {
  border: 2px solid var(--FOCUS-INPUT-BORDER-BLUE);
  box-shadow: var(--FOCUS-INPUT-BORDER-SHADOW);
}

.grid.tagged .item {
  grid-template-rows: min-content min-content auto min-content;
}

.grid .pictogram {
  height: 42px;
  margin-bottom: 7px;
  align-content: start;
  --iconColor: var(--PRIMARY-BLUE);
}
.grid .pictogram svg {
  width: 40px;
}

.grid .item .header {
  font-size: 20px;
  margin: 0 0 12px;
  text-align: left;
  font-weight: 500;
}

.grid.small .item .header {
  font-size: 14px;
  line-height: 1.2;
}

.grid .body {
  color: var(--DISABLED-FONT-COLOR);
}

.grid .footer {
  margin-top: 7px;
}
