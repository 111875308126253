.sortIcon {
  --image: url("../../../assets/images/icons/sorting.svg");
  --width: 21px;
  --height: 19px;
}

.orderByDropdown {
  display: flex;
  align-items: center;
  margin: 0 3px 0 13px;
  cursor: pointer;
}
