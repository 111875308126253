.wrap {
  position: absolute;
  inset: 0;
  transform: translate(400px, 0);
  transition: transform 300ms ease-in, opacity 200ms 150ms linear;
  background-color: #fff;
  padding: 12px 25px 0;
  opacity: 0;
  z-index: -1;
}
.wrap.active {
  transform: translate(0, 0);
  transition: transform 300ms ease-out, opacity 300ms;
  opacity: 1;
  z-index: 999;
}

.panel {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  height: 100%;
}

.panel :global(.back-link) {
  margin-bottom: 14px;
  background-color: transparent;
  margin-left: -10px;
}

.panel :global(.back-link:hover) {
  background-color: var(--GRAY-LIGHT);
}

.panel :global(.back-link .back-link__icon) {
  top: 1px;
}

.fixed {
  flex: 0 1 auto;
}

.resultsCounter {
  margin-bottom: 28px;
  color: var(--GRAY-MID);
  font-weight: 500;
}
.resultsList {
  overflow: hidden;
}

.results {
  overflow: hidden auto;
  flex: 1 0 auto;
  margin: 0 -25px;
}
