.body {
  /*width: 100%;*/
  padding: 0 !important;
  display: grid;
  grid-template-rows: min-content minmax(0, 1fr) min-content;
  grid-template-columns: 1fr;
  height: 100%;
}

.filter {
  padding: 0 30px;
  margin-bottom: 24px;
}

.grid {
  overflow: auto;
  position: relative;
}

.footer {
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dimmer {
  padding: 0 !important;
}
