.actions:global(.ui.ui.ui) {
  margin-left: auto;
  padding: 0 !important;
  border: solid 1px #eaeaea !important;
  border-radius: 4px;
  align-self: center;
}

.actions .iconWrap {
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.hint {
  color: #9aa8bd;
  font-size: 12px;
  margin-bottom: 7px;
}
