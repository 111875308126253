.filters {
  display: flex;
  justify-content: space-between;
  margin: 0 0 24px;
  position: relative;
}
.filters:global(.margin-top) {
  margin-top: 24px;
}

.inputs {
  display: flex;
  flex-flow: row nowrap;
}

.inputs .item {
  margin-right: 9px;
}

.tabs {
  display: flex;
  flex-flow: row nowrap;
}

.tabs .item {
  flex: 0 0 auto;
  padding: 0 0 10px;
  border-bottom: 2px solid transparent;
  margin-right: 26px;
  font-size: 16px;
}

.tabs .item .label {
  cursor: pointer;
  font-weight: 500;
}

.tabs .item:hover .label {
  color: var(--PRIMARY-BLUE);
}

.tabs .item.selected {
  color: var(--PRIMARY-BLUE);
  border-bottom: 2px solid var(--PRIMARY-BLUE);
}

:global(.DateRangePicker_picker) {
  z-index: 2;
}
