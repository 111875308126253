.cart {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.cart .orders {
  flex: 1 1 auto;
  overflow: hidden auto;
  border-right: 0.5px solid var(--DISABLED-BORDER-COLOR);
  padding-right: 20px;
}

.cart .orders .quantity {
  padding: 14px 0 24px;
  font-weight: 500;
  font-size: 16px;
  color: var(--BLACK);
}

.cartItems .cartItem {
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;
  align-items: flex-start;
}

.cartItems .cartItem .options {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
}

.cartItems .cartItem .options .option label {
  font-weight: 500;
}

.cartItems .cartItem .image {
  border-radius: 8px;
  width: 108px;
  object-fit: contain;
}

.cartItems .cartItem .description {
  padding-left: 16px;
  flex: 1;
}

.cartItems .cartItem .description .title {
  font-weight: 500;
  color: var(--BLACK);
  padding-bottom: 8px;
}

.cartItems .cartItem .description .id {
  color: var(--DISABLED-FONT-COLOR);
  padding-bottom: 8px;
}

.cartItems .cartItem .description .quantity {
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.cartItems .cartItem .description .quantity .amount {
  font-weight: 500;
  font-size: 14px;
  color: var(--BLACK);
}

.cartItems .cartItem .description .quantity .deleteIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  cursor: pointer;
  margin-left: 24px;
}

.cartItems .cartItem .description .quantity .deleteIcon:hover {
  border-radius: 5px;
  background-color: var(--GRAY-SHADOW);
}

.cartItems .cartItem .description .discount {
  display: grid;
  grid-template-areas:
    "label input"
    "error  error";
  grid-template-columns: 1fr 148px;
  align-items: center;
}

.cartItems .cartItem .description .discount .label {
  grid-area: label;
  font-size: 12px;
}

.cartItems .cartItem .description .discount .label :global(label) {
  padding: 5px 10px 5px 10px;
  background: #d3f2cd;
  color: #2c6047;
  border-radius: 5px;
  text-align: center;
}

.cartItems .cartItem .description .discount .discountInput {
  grid-area: input;
}

.cartItems .cartItem .description .discount .error {
  grid-area: error;
  height: auto;
  display: flex;
}

.cartItems .cartItem .description .discount .label {
  font-size: 12px;
}

.cartItems
  .cartItem
  .description
  .discount
  .discountInput
  .input
  :global(input) {
  padding-left: 32px;
  text-align: right;
  width: 148px;
}

.cartItems .cartItem .description .afterDiscount {
  padding-top: 8px;
  float: right;
  display: flex;
  flex-direction: row;
  align-items: center;
}
