.centerContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  height: calc(100% - 80px);
}

.contentInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  max-width: 490px;
}

.title {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 7px;
}

.description {
  color: var(--GRAY-MID);
  padding-top: 7px;
  padding-bottom: 7px;
}

.contentInfo > ul {
  color: var(--GRAY-MID);
  list-style-type: none !important;
  padding-inline-start: 0 !important;
  margin: unset !important;
  padding-top: 7px;
  padding-bottom: 21px;
}

.contentInfo > ul > li:not(:last-child) {
  padding-bottom: 7px;
}

.contentInfo > ul > li > svg {
  margin-right: 7px;
}

.contentInfo button {
  padding: 10px 30px !important;
}
