.alert {
  border-left: 8px solid var(--bdColor, transparent);
  background-color: var(--bgColor);
  color: var(--color, inherit);
  padding: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.alert:global(.center) > div {
  justify-content: center;
  align-items: center;
}

.alert.warning svg {
  width: var(--iconWidth, 24px);
  height: auto;
  margin-right: 8px;
}

.alert.warning :global(.channel-warning) {
  line-height: 1.6;
}

.alert.warning :global(.channel-warning:last-child) {
  padding-top: 14px;
}

.alert.warning :global(.channel-warning .header) {
  font-weight: 500;
}

.alert.warning :global(.channel-warning .content) {
  background: transparent;
}

.alert.warning :global(.channel-warning .bold) {
  font-weight: 500;
}

.alert.warning :global(.channel-warning .link) {
  font-weight: 500;
  color: var(--PRIMARY-BLUE);
}

.alert.warning :global(.channel-warning .link:hover) {
  color: #4d67f9;
  cursor: pointer;
}

.alert.warning > :global(div:first-child) {
  display: flex;
  font-weight: 500;
  color: #cd3d64;
  margin-bottom: 14px;
}

.alert.warning > :global(div:last-child) {
  color: #3c4257;
}

.warning {
  --bdColor: #ff8d00;
  --bgColor: rgba(255, 141, 0, 0.1);
}

.success {
  --bdColor: #6ec072;
  --bgColor: rgba(110, 192, 114, 0.1);
  --color: #3c4257;
}

.info {
  --bdColor: #6078ff;
  --bgColor: rgba(96, 120, 255, 0.1);
  --color: #3c4257;
  --iconWidth: 20px;
}
