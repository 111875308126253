.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.buttons :global(.ui.button) {
  width: 120px !important;
}
.cancelButton {
  --bg-color: none;
  --border-color: #fff;
  margin-top: 0 !important;
}
.cancelButton:hover {
  --bg-hover-color: #fff;
}
