.boundary {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: column nowrap;
}

.content {
  max-width: 485px;
  margin: 130px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: column nowrap;
}

.content img {
  margin: 0 0 68px;
  user-select: none;
}

.content h1 {
  margin: 0 0 21px;
  font-size: 28px !important;
  font-weight: 500;
}

.details {
  margin: 0 0 21px;
}
.details p {
  margin: 0 0 7px;
  text-align: center;
}

.errorInfo {
  width: 500px;
  height: 300px;
  overflow: auto;
}
