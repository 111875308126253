.containerWrapper {
  flex-direction: column !important;
  display: flex;
  align-items: center !important;
  justify-content: flex-start !important;
  max-width: 1440px !important;
  width: 100%;
  overflow: auto;
}

.backButton {
  text-decoration: none !important;
  color: #000 !important;
  align-self: flex-start;
  margin-bottom: 60px;
  display: flex;
  align-items: center;
}

.backIcon {
  --color: #000;
  margin-right: 12px;
}

.modalWrapper {
  background: #fff;
  width: auto;
  border-radius: 10px;
}

.addOnPageHeader {
  font-size: 20px;
  font-weight: 500;
  padding: 40px;
  text-align: center;
  border-bottom: 1px solid var(--DISABLED-BORDER-COLOR);
}

.contentWrapper {
  display: flex;

  flex-direction: column;
  align-items: center;
  padding: 32px 49px;
}

.ctaWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 40px;
}

.ctaButtonLink {
  width: 100%;
  text-decoration: none !important;
}

.ctaButton {
  width: 100%;
  justify-content: center;
  margin-bottom: 16px !important;
}

.currentQuotaLabel {
  margin-bottom: 40px;
}

.hint {
  max-width: 548px;
  margin-top: 22px;
  margin-bottom: 20px;
  text-align: center;
}
