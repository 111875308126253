.root {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 26px 30px;
}

.head {
  margin: 0 0 14px;
  font-weight: 500;
}

.chart {
}

.chart .label {
  font-size: 14px;
  font-weight: 500;
  font-family: var(--DEFAULT-FONT-FAMILY) !important;
}

.dummy {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-flow: row nowrap;
  color: #ccc;
}
