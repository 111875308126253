.modal.modal {
  width: 880px !important;
  height: 640px !important;
  --border-radius: 16px;
}

.modal .content {
  height: 100%;
  overflow: hidden;
}
