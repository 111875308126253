.content {
  margin: 32px 0 56px;
  min-height: 200px;
}

.desc {
  margin-top: 22px;
}

.list {
  margin: 24px 0;
  list-style: none;
}

.button {
  margin-top: 16px;
}

.checkedItem {
  margin-bottom: 7px;
  padding-left: 8px;
  position: relative;
}

.checkedItem:before {
  content: url("../../assets/images/check.svg");
  position: absolute;
  left: -25px;
  top: 1px;
}

.exitIcon {
  margin-left: 10px;
  vertical-align: middle;
  --image: url("../../assets/images/exit-top-right-white.svg");
  --width: 14px;
  --height: 15px;
}

.tips {
  margin-top: 24px;
  border-left: 8px solid var(--PRIMARY-BLUE);
  background-color: #eff2ff;
  padding: 18px 18px 24px;
  max-width: 700px;
}

.tipsTitle {
  font-weight: 500;
  margin-bottom: 16px;
}

.tipsIcon {
  margin-left: 12px;
  vertical-align: middle;
  --image: url("../../assets/images/tips.svg");
  --width: 22px;
  --height: 24px;
}

.block {
  margin-top: 28px;
}
.blockTitle {
  font-weight: 500;
}

.linkTableWrapper {
  margin: 10px -30px;
}

:global(.ui.table).linkTable {
  border-radius: 0;
  border-left: none;
  border-right: none;
}

:global(.ui.table thead th).linkTableHeader {
  color: var(--GRAY-MID);
  text-transform: uppercase;
}

:global(.ui.table td).linkTableCell {
  padding: 16px;
}

:global(.ui.table thead th).linkTableHeader.first,
:global(.ui.table td).linkTableCell.first {
  padding-left: 30px;
}

:global(.ui.table thead th).linkTableHeader.last,
:global(.ui.table td).linkTableCell.last {
  padding-right: 30px;
}

:global(.ui.button).copyButton {
  --bg-color: #e7eaff;
  --border-hover-color: #e7eaff;
  --text-color: var(--PRIMARY-BLUE);
  margin-left: 54px;
}

.commissionBlock {
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  padding: 24px;
  display: inline-block;
  margin: 12px 0;
  min-width: 200px;
}

.commissionTitle {
  font-size: 12px;
  color: var(--GRAY-MID);
  margin-bottom: 4px;
}
.commissionValue {
  font-size: 16px;
  font-weight: 500;
}
