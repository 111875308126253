.title {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 14px;
}

.subtitle {
  margin-bottom: 14px;
}

.grid {
  display: grid;
  grid-template-columns: min-content auto;
  column-count: 2;
  grid-gap: 14px 21px;
  margin: 0 0 70px;
}

.gridLabel {
  white-space: nowrap;
  font-weight: 500;
}

.gridValue {
  font-weight: 500;
}
