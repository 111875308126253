.placeholder {
  background: url("./assets/picture.svg") 50% 50% no-repeat;
  background-color: #f6f5f5;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  display: block;
}

.placeholder.small {
  background: url("./assets/picture-sm.svg") 50% 50% no-repeat;
}
