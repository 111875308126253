.orderByPopup:global(.ui.dropdown.static) {
  padding: 8px !important;
}

.orderByPopup :global(ul) {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  padding-inline-start: 0 !important;
  cursor: default !important;
}

.orderByPopup :global(ul, li) {
  list-style-type: none;
}

.title {
  padding: 20px 17px 10px 17px;
  color: var(--GRAY-MID);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
}

.orderByPopup :global(li) {
  padding: 11px 16px 10px 16px;
  border-radius: 5px;
  line-height: 16.41px;
  font-weight: 400;
  color: var(--GRAY-DARK);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.orderByPopup :global(li:hover) {
  background-color: var(--GRAY-LIGHT);
}

.orderByPopup :global(li.active) {
  background-color: #f1f3ff;
  color: var(--PRIMARY-BLUE);
}

.orderByPopup :global(li.active::after) {
  content: url("../../../assets/images/icons/tick-circle-blue.svg");
  width: 12px;
  height: 12px;
  margin-left: 24px;
}
