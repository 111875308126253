.container {
  padding-top: 8px;
}
.title {
  font-size: 16px;
  font-weight: 500;
}
.success {
  color: var(--SUCCESS-GREEN);
  padding: 8px 0px;
}
.warning {
  color: #b01717;
  padding: 8px 0px;
}
.action {
  margin-top: 16px;
}
.modal {
  width: 460px !important;
  text-align: center !important;
}
.modal :global(.header) {
  justify-content: center !important;
}
.modal :global(.actions) {
  justify-content: center !important;
}
.infoAlert {
  padding-top: 16px;
}
.infoAlert :global(.tipHeader) {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
