.actions {
  margin: 21px 0 0;
}

.prompt :global(.button) {
  margin-right: 14px;
}

.prompt :global(.button.ui.ui.active),
.prompt :global(.button.ui.ui.active:hover) {
  border: 1px solid var(--PRIMARY-BLUE) !important;
}
.stepperWrap {
  min-height: 268px;
}
