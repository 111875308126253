.search {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  max-width: 500px;
  margin-bottom: 11px;
}

.input {
  flex: 1 1 auto;
  display: grid;
  grid-template-areas: "main";
  margin-right: 7px;
  width: 410px;
}

.input input {
  height: 35px !important;
  min-height: 35px !important;
  grid-area: main;
}

.actions {
  flex: 0 0 auto;
}

.actions :global(.ui.button) {
  margin: 0 !important;
  height: 35px;
}

.reset {
  width: 16px;
  height: 16px;
  grid-area: main;
  position: absolute;
  align-self: center;
  justify-self: end;
  transform: translate(-8px, 0);
  display: flex;
  align-items: center;
  justify-content: center;
}
