.item {
  display: flex;
}

.text {
  margin-left: 8px;
}

.selectedDropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 14px;
  right: 30px;
  display: flex;
  align-items: center;
}
