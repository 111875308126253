.label {
  display: inline-flex;
  align-items: center;
  flex-flow: row nowrap;
  background-color: #f6f5f5;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  margin-right: 7px;
  margin-bottom: 7px;
  padding: 0 14px 0 7px;
  height: 29px;
}

.label.singleRow {
  margin-bottom: 0;
}

.icon {
  margin-right: 7px;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
}

.icon img {
  width: 21px;
  height: 21px;
}

.text {
  font-weight: 500;
}
