.content {
  margin-top: 17px;
  background-image: url("./assets/PreviewBackground.svg");
  padding: 16px;
  flex: 1;
}

.messageContent {
  border-radius: 14px;
  background-color: var(--WHITE);
  padding: 21px 16px;
  word-break: break-all;
}

.messageContent .link {
  color: var(--PRIMARY-BLUE);
  cursor: pointer;
  font-weight: 500;
}
