.wrapper {
  max-width: 1200px;
  width: 100%;
}

.header {
  display: flex;
  justify-content: center;
  margin: 50px 0;
}

.header .logo {
  width: 200px;
}

.profile {
  display: flex;
  width: 100%;
}

.personalInfo {
  flex: 1;
  margin-right: 8px;
  padding: 40px 24px;
  display: flex;
  min-height: 180px;
  min-width: 200px;
}

.photo {
  width: 96px;
  height: 96px;
  flex: none;
  overflow: hidden;
}

.photoImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  margin-left: 28px;
}

.name {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px;
  line-height: 120%;
}

.company {
  color: var(--GRAY-MID);
  margin-bottom: 16px;
}

.editIcon {
  --image: url("./assets/pencil.svg");
  --width: 12px;
  --height: 12px;
  margin-right: 8px;
}

.plan {
  flex: 2;
  margin-left: 8px;
  min-height: 180px;
}

.quickStart {
  margin-top: 56px;
}

.sectionTitle {
  font-size: 28px;
  font-weight: 500;
}

.progress {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

.barWrapper {
  display: inline-block;
  width: 240px;
  margin: 0 18px;
}

.barWrapper .bar {
  --bg-color: var(--WHITE);
}

.tasks {
  margin-bottom: 24px;
  min-height: 116px;
}

.loader {
  min-height: 116px;
}

.faq {
  margin-top: 40px;
}

.divider:global(.ui.divider) {
  margin-bottom: 36px;
}

.question {
  margin-bottom: 24px;
}

.answer {
  margin-top: 8px;
  white-space: pre-line;
  font-size: 16px;
}
