.catalogTitleWrapper {
  border: 1px solid var(--GRAY-SHADOW);
  padding: 16px;
  margin-bottom: 16px;
}
.catalogTitleLabel {
  color: var(--GRAY-MID);
  font-size: 12px;
  margin-bottom: 4px;
}
.catalogTitle {
  font-weight: 500;
  font-size: 14px !important;
  margin: 0;
}
