.container {
  padding-top: 16px;
}
.tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 280px;
  position: relative;
}
.border {
  position: absolute;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  border-radius: 5px;
  width: 280px;
  height: 100%;
  z-index: 1;
}
.tab {
  padding: 12px;
  width: 140px;
  text-align: center;
  cursor: pointer;
  z-index: 3;
}
.tab:hover {
  color: var(--PRIMARY-BLUE);
}
.tab.selectedTab {
  border-radius: 5px;
  color: var(--PRIMARY-BLUE);
  border: 1px solid var(--PRIMARY-BLUE);
}
.table {
  margin-top: 20px;
  max-height: 580px;
  overflow-y: auto;
}
.table .link {
  color: var(--PRIMARY-BLUE);
}
.paid {
  color: #18c963;
  position: relative;
  padding-left: 18px;
}
.paid:before {
  background-color: #18c963;
  position: absolute;
  top: 5px;
  left: 0;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  content: "";
}
.table .link:hover {
  color: var(--PRIMARY-BLUE) !important;
  text-decoration: underline !important;
}
