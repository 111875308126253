.root {
}

.trigger {
  color: var(--PRIMARY-BLUE);
  font-weight: 500;
  cursor: pointer;
}

.popup {
  padding: 18px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 12px 2px rgba(0, 102, 255, 0.1);
  width: 340px;
}

.form {
}

.form .header {
  font-weight: 500;
  margin: 0 0 8px;
}

.form .body {
}

.form .footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  gap: 8px;
}

.form .inputGroup {
  display: grid;
  grid-template-columns: auto 24px;
}

.form .input {
  grid-column: 1 / 3;
  grid-row: 1;
  z-index: 1;
}

.form .input input {
  padding-right: 30px;
}

.form .inputGroup {
  margin: 0 0 16px;
}

.form .inputGroup .icon {
  grid-row: 1;
  grid-column: 2 / 3;
  justify-self: end;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  background: #fff;
  margin: 2px;
}
