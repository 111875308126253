.item {
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-template-rows: min-content;
  grid-gap: 11px;
  margin: 0 0 14px;
  font-size: 12px;
}

.item .pic {
  position: relative;
  height: 50px;
  background-color: var(--GRAY-LIGHT);
  border-radius: 8px;
}

.item.noImage .pic {
  background-image: url("assets/catalog-img.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.item .pic img {
  max-width: 100%;
  object-fit: cover;
}

.item .pic .quantitiy {
  display: inline-flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: #63687a;
  color: #fff;
  font-size: 12px;
  bottom: -5px;
  right: -5px;
  position: absolute;
  width: 20px;
  height: 20px;
}

.item .title {
  font-weight: 500;
}

.item .subtitle {
  color: var(--GRAY-MID);
}
