.payment {
  margin: 0 0 14px;
  border-bottom: 1px solid #eaeaea;
  padding: 0 0 14px;
}

.payment.last {
  border-bottom: none;
}

.row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 0 0 4px;
  font-size: 12px;
}

.row .label {
  flex: 1 0 min-content;
  white-space: nowrap;
  color: var(--GRAY-MID);
}

.row .value {
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row .inlineBlocks {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  margin-right: -5px;
}

.row .inlineBlocks .block {
  margin-right: 7px;
}

.actions {
  display: flex;
}

.actions .links {
  display: flex;
  margin-left: auto;
}

.actions .links .link {
  display: inline-block;
  margin-left: 16px;
  color: var(--PRIMARY-BLUE);
  font-weight: 500;
}

.actions .links .link.disabled {
  color: var(--GRAY-MID);
  cursor: not-allowed;
}
