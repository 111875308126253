.trigger {
  display: inline-block;
}

.subMenu {
  margin-left: 17px;
  margin-right: 17px;
}

.subMenu:first-child {
  margin-top: 9px;
}

.subMenu:last-child {
  margin-top: 19px;
}

.subMenu :global(.header) {
  color: var(--GRAY-MID);
  font-size: 12px;
  line-height: 14.32px;
}

.subMenu :global(.item) {
  padding-top: 9px;
  padding-bottom: 9px;
  cursor: pointer;
}
