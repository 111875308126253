.content {
  width: 100%;
}

.nav {
  flex: 0 0 auto;
  margin: 0 0 7px;
}

.actions {
  padding: 15px 0;
}
