.optional {
  color: var(--GRAY-MID);
  margin-left: 5px;
}

.optional :global(label) {
  padding-bottom: 6px !important;
}

.hintContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 7px;
}

.hint {
  color: var(--GRAY-MID);
}

.hintContainer :global(img) {
  margin-left: 5px;
  cursor: pointer;
}

.dropdownContainer {
  display: flex;
  align-items: center;
  margin-top: 7px;
}

.dropdownContainer :global(.dropdown) {
  max-width: 316px !important;
}

.clearAll {
  margin-left: 23px;
  color: var(--PRIMARY-BLUE);
  cursor: pointer;
}

.selectedValues {
  margin-top: 5px;
}
