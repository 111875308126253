.container {
  background: var(--WHITE);
  padding: 40px;
  margin-top: 14px;
  border-radius: 5px;
}
.header {
  font-weight: 500;
}
.button:hover {
  text-decoration: none !important;
}
.button .redirectIcon :global(g) {
  fill: #fff;
}
.content {
  padding: 14px 0px;
  color: var(--GRAY-MID);
  width: 50%;
}
.reminder {
  padding-top: 21px;
  display: flex;
  flex-flow: row nowrap;
  color: var(--GRAY-MID);
}
.link {
  color: var(--PRIMARY-BLUE) !important;
  margin-left: 7px;
}
.link:hover {
  color: var(--LINK-HOVER) !important;
}
.redirectIcon g {
  fill: var(--PRIMARY-BLUE);
}
