.card {
  padding: 20px;
  background: var(--WHITE);
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  border-radius: 5px;
}

.card:first-of-type {
  margin-right: 23px;
}

.content {
  padding-top: 7px;
  color: var(--GRAY-MID);
}
