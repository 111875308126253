.topSection {
  flex: 0 0 auto;
  border-bottom: 1px solid var(--GRAY-UI);
  margin-top: -30px;
  margin-left: -30px;
  margin-right: -30px;
  padding: 30px;
}

.topSection :global(.back-link__link) {
  margin-left: -8px;
}

.headerItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.headerItem :global(.ui.dropdown) {
  color: var(--GRAY-DARK);
  padding: 5px 10px 6px;
  min-height: 33px;
  border-radius: 5px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.headerItem :global(.ui.dropdown .icon) {
  position: static;
  width: 12px;
  height: 12px;
  margin-left: 6px;
}

.headerItem :global(.back-link) {
  background-color: #fff;
}

.headerItem :global(.back-link:hover) {
  background-color: var(--GRAY-LIGHT);
}

.contentSection {
  margin-top: 25px;
  flex: 0 1 auto;
  overflow: auto;
}
