.root {
}

.padCloseBtn {
  padding-right: 40px;
}

.contentGrid {
  margin-top: 21px;
  display: grid;
  grid-template-areas:
    "header ."
    "content variable"
    "note .";
  grid-template-columns: 1fr 260px;
}

.contentGrid .header {
  grid-area: header;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 0 14px;
}

.contentGrid .content {
  grid-area: content;
  position: relative;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
}

.contentGrid .variables {
  grid-area: variable;
}

.contentGrid .note {
  padding-top: 14px;
  grid-area: note;
  color: var(--GRAY-MID);
}

.label {
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 14px;
}

.label :global(label) {
  padding-bottom: 0px !important;
}

.channelList {
  display: block;
}

.channels {
  display: flex;
  flex-wrap: wrap;
}

.selectedChannelHeader {
  padding-top: 28px;
  display: flex;
  flex-flow: column wrap;
}

.selectedChannelHeader .channels {
  flex-flow: column wrap;
}

.selectedChannelHeader .channels .channel {
  padding: 4px 10px;
  margin: 0px 14px 14px 0px;
  border-radius: 5px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  display: inline-flex;
  flex-flow: row nowrap;
  flex: auto;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  background: var(--GRAY-LIGHT);
}

.selectedChannelHeader .channels .channel .name {
  margin-left: 9px;
  font-weight: 500;
}

.selectedChannelHeader .channels .channel :global(img) {
  width: 21px;
  height: 21px;
}

.link {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 14px;
  color: var(--PRIMARY-BLUE);
  --color: var(--PRIMARY-BLUE);
  font-weight: 500;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
  color: var(--LINK-HOVER);
}

.dimmer {
  flex: 1;
  display: flex;
}

.dimmer :global(.dimmer) {
  padding: 20px 20px !important;
  margin-left: -20px !important;
}

.readonly .templateControls {
  display: none;
}

.readonly .contentGrid {
  grid-template-columns: 1fr;
}
.readonly .selectedChannelHeader .channels .label {
  margin-right: 14px;
}
.readonly .selectedChannelHeader .channels {
  flex-flow: row nowrap;
}
.readonly .selectedChannelHeader .channels .channel {
  margin-right: 7px;
}
