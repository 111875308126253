.topSection {
  display: flex;
  padding-bottom: 32px;
  border-bottom: 1px solid var(--GRAY-UI);
}

.installContainer {
  display: flex;
  gap: 16px;
}

.installContainer h1 {
  font-size: 28px !important;
  margin: 0;
}

.installContainer p {
  font-size: 20px !important;
}

.duration {
  display: flex;
  align-items: center;
  color: var(--GRAY-MID);
  margin-top: 24px;
}

.duration img {
  width: 14px;
  margin-right: 8px;
}

.bottomSection {
  display: flex;
  padding-top: 32px;
  gap: 16px;
}

.descriptionSection {
  flex: 2;
}

.longDescription {
  margin-top: 16px;
  line-height: 1.65;
}

.featuresSection {
  display: flex;
  gap: 16px;
}

.featuresSection > div {
  flex: 1;
}

.reminderCard {
  border: 1px solid var(--GRAY-UI);
  border-radius: 8px;
  padding: 16px;
  flex: 1;
}

.reminderCard .listTitle {
  margin-bottom: 6px;
}

.reminderCard ul {
  padding-left: 16px;
  margin-bottom: 24px;
}

.reminderCard ul li {
  margin-top: 4px;
}
