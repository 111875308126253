.input {
  margin-right: 7px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  height: 34px;
}

.list {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-right: 7px;
  height: 34px;
  min-width: 34px;
  padding: 0 5px;
  border: 1px solid #ebecf0 !important;
  border-radius: 5px;
}

.list:hover {
  background-color: #f2f2f2;
}

.user {
  flex: 0 0 20px;
  margin: 0 5px 0 0;
  position: relative;
}

.user:last-child {
  margin-right: 0;
}

.user .pic {
  overflow: hidden;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}

.user .pic img {
  flex: 1 1 auto;
  object-fit: cover;
}

.user .pic :global(.sb-avatar__text),
.user .pic :global(.sb-avatar .sb-avatar--text) {
  width: 20px !important;
  height: 20px !important;
}

.user .pic :global(.sb-avatar__text) > div {
  font-size: 9px !important;
}

.user :global(.icon.close-xs) {
  position: absolute;
  right: -6px;
  top: -9px;
  cursor: pointer;
  width: 8px;
  height: 8px;
}

.buttonWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
  border-radius: 5px;
  border: 1px solid #ebecf0 !important;
  height: 34px;
  width: 34px;
  box-shadow: none !important;
}

.button {
  display: inline-block;
  height: 14px;
}

.button > img {
  height: 15px !important;
  width: 15px !important;
}

.buttonWrap:hover {
  border: none;
  box-shadow: none !important;
  background-color: #f2f2f2;
}

.buttonWrap:hover > img {
  color: var(--PRIMARY-BLUE);
}

.button i:global(.icon) {
  margin: 0;
  width: auto;
  height: 14px;
}

.close {
  position: absolute;
  right: -2px;
  top: -10px;
  cursor: pointer;
}
