.beta {
  display: flex;
  align-items: center;
  margin-left: 8px;
  padding: 4px 8px;
  font-size: 10px;
  border-radius: 5px;
  font-weight: 700;
  background: var(--PRIMARY-BLUE);
  color: white;
}
