.root {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 400px;
  grid-template-rows: minmax(0, 1fr);
  grid-column-gap: 32px;
}

.head {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 12px;
}

.description {
  margin-bottom: 24px;
}

.main {
  display: grid;
  grid-template-rows: minmax(0, auto) min-content;
  /*height: 100%;*/
}

.main .body {
  padding: 0 0 24px;
}

.form :global(.field) {
  margin-bottom: 10px !important;
}

.form :global(.field-error.visible),
.form :global(.field-error.hidden) {
  margin-top: 8px !important;
  min-height: 1.3em;
  margin-bottom: 0 !important;
}

.form textarea {
  resize: none !important;
}

.form .error.error :global(textarea),
.form .error.error :global(.ui.ui.dropdown),
.form .error.error :global(input) {
  background-color: #fff;
  border: solid 1px var(--FIELD-ERROR-RED) !important;
  box-shadow: 0 0 1px 3px var(--FIELD-ERROR-SHADOW-RED) !important;
}

.aside {
  /*height: 100%;*/
}

.actions {
}
