.container {
  /*width: 384px;*/
  /*height: 227px;*/
  padding: 14px;
  background-color: #fff;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  display: grid;
  justify-items: stretch;
  grid-gap: 0 21px;
  grid-template-rows: 22px auto 47px;
  grid-template-columns: 120px 220px auto;
  grid-template-areas:
    "ranges input close"
    "ranges input ."
    "ranges actions .";
}

.ranges {
  grid-area: ranges;
  align-self: stretch;
  row-span: 5;
}

.ranges .header {
  color: var(--GRAY-MID);
}
.ranges .range {
  height: 33px;
  display: flex;
  align-items: center;
  margin-left: -14px;
  padding: 0 14px;
  cursor: pointer;
}
.ranges .range:hover,
.ranges .range.selected {
  background-color: #f6f6f6;
}

.inputWrap {
  grid-area: input;
  align-self: start;
}

.inputWrap .header {
  font-weight: 500;
  margin-bottom: 7px;
}

.inputWrap .input {
  justify-self: start;
}
.inputWrap .datepicker {
  width: auto;
}

.close {
  grid-area: close;
  align-self: start;
  justify-self: end;
  /*margin: 14px;*/
  cursor: pointer;
  display: flex;
  place-content: center;
}
.close :global(.close-button) {
  margin: 0;
  width: 16px;
  height: 14px;
  cursor: pointer;
}
.close :global(.close-button:hover) {
  background: #f0f2f5;
}

.actions {
  grid-area: actions;
}
.actions .button {
  justify-content: center;
}
