.fieldWrapper {
  display: flex;
  align-items: center;
  border: 1px solid var(--GRAY-UI);
  padding: 20px;
  border-radius: 8px;
  gap: 16px;
}

.inputSection {
  flex: 1;
}

.inputSection :global(.ui.input) {
  width: 80%;
}

.inputSection p {
  margin-top: 8px;
}
