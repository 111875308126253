.tabs {
  display: flex;
  flex-flow: row nowrap;
  place-content: center center;
}

.tabs :global(.ui.menu.ui.ui .item) {
  padding: 0 0 10px;
  margin: 0 42px 0 0;
  border-bottom-width: 4px;
  font-weight: 500;
  color: var(--BLACK);
}

.tabs :global(.ui.menu.ui.ui .item.active) {
  color: var(--PRIMARY-BLUE);
}

.shopify {
  margin-right: 7px;
  --image: url(../../../../assets/images/channels/shopify.svg);
  --width: 17px;
  --height: 17px;
}

.facebook {
  margin-right: 7px;
  --image: url(../../../../assets/images/channels/facebook-messenger.svg);
  --width: 17px;
  --height: 17px;
}

.instagram {
  margin-right: 7px;
  --image: url(../../../../assets/images/channels/Instagram.svg);
  --width: 17px;
  --height: 17px;
}

.warning {
  background-color: #fff4e5;
  border-left: 8px solid #ff8d00;
  padding: 21px 35px;
  margin: 30px 5px 0;
}

.warning .header {
  margin: 0 0 14px;
  color: var(--FIELD-ERROR-RED);
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
}

.warning .header:before {
  content: url("../../../../assets/images/warning.svg");
  margin-right: 7px;
  transform: translateY(2px) scale(0.9);
}

.warning .body b {
  font-weight: 500;
}

.headerIcon {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
}
