.body {
  /*margin: 0 7px;*/
}

.row {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 0 10px;
}

.row:last-child {
  margin-bottom: 0;
}

.label {
  color: var(--GRAY-MID);
  margin-right: 7px;
  line-height: 1.3;
  white-space: nowrap;
}

.value {
  color: var(--GRAY-DARK);
  margin-left: auto;
  text-align: right;
  line-height: 1.3;
  overflow: hidden visible;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.value :global(.flag.flag) {
  vertical-align: middle;
}

.status {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
}

.status:before {
  display: inline-block;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 8px;
  vertical-align: middle;
  margin-right: 7px;
}

.status.online:before {
  background-color: #6ec072;
}

.status.offline:before {
  background-color: var(--GRAY-MID);
}
