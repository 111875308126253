.redirectIcon {
  margin-left: 7px;
}

.redirectIcon g {
  fill: #9aa8bd;
}
.headerClickArea {
  display: flex;
  flex-direction: row;
  align-items: center !important;
}
.headerClickArea > :global(.ui.header) {
  margin: 0px;
}
.headerClickArea:hover {
  cursor: pointer;
}
.headerClickArea:hover > :global(.ui.header) {
  color: #6078ff;
}
.headerClickArea:hover > .redirectIcon g {
  fill: #6078ff;
}
