.menu :global(.item) {
  padding: 0 0 7px !important;
  margin: 0 42px 0 0 !important;
  font-weight: 500;
  color: var(--BLACK) !important;
  min-height: 0;
}
.menu :global(.item.active) {
  color: var(--PRIMARY-BLUE) !important;
}

.menu.large :global(.item) {
  font-size: 16px;
}

.menu.thick :global(.item) {
  border-bottom-width: 4px;
}
