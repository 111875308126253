.root {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.section {
  margin-right: 24px;
}
.section :global(.ui.dropdown) {
  width: 200px;
}
.section a {
}

.searchGroup {
  display: flex;
  flex-flow: row nowrap;
}

.searchGroup .button {
  margin-left: 16px;
}
