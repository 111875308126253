.connect .header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.connect .icon {
  width: 56px;
  margin-right: 20px;
}
.connect .title {
  font-size: 28px !important;
  margin: 0;
}
.connect .subTitle {
  font-size: 14px !important;
  margin: 0;
}

.connect .description {
  margin-top: 24px;
}
.connect .listTitle {
  margin: 24px 0;
  font-size: 16px;
  font-weight: 500;
}
.connect .list {
  list-style: none;
  padding-left: 0;
}

.connect .listItem {
  padding-left: 0;
  line-height: 120%;
  margin-bottom: 8px;
}

.connect .listItem::before {
  content: "";
  display: inline-block;
  background-image: url("../../../assets/images/check.svg");
  background-position: center;
  background-size: cover;
  width: 15px;
  height: 11px;
  margin-right: 8px;
}
.connect .button {
  margin-top: 16px;
}

.connect .linkWrapper {
  margin-top: 20px;
  text-align: center;
}

.connect .link,
.connect .link:hover {
  color: var(--PRIMARY-BLUE);
}
