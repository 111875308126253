.wabaName {
  padding: 9px 16px;
  margin: 8px;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.wabaName .name {
  padding-right: 7px;
}
.wabaName:first-child {
  margin-left: 0px;
}
.wabaName:hover,
.selected {
  background-color: var(--GRAY-LIGHT) !important;
}
