.action {
}

.controls {
  display: grid;
  grid-template-columns: 4fr 4fr 4fr 4fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "head body body buttons"
    "errors errors errors errors ";
  grid-gap: 0 28px;
}

.updateCustomFields .controls {
  grid-template-columns: 3fr 3fr 6fr 4fr;
  grid-template-areas:
    "head body body buttons"
    "errors errors errors errors";
}

.shortFields .controls {
  grid-template-areas:
    "head input1 input2 buttons"
    "errors errors errors errors";
}

.head {
  grid-area: head;
}

.body {
  grid-area: body;
  grid-row: 1 / 3;
  align-items: start;
}

.body :global(.ui.dropdown) {
}

.shortFields .inputPrimary {
  grid-area: input1;
}

.shortFields .inputSecondary {
  grid-area: input2;
}

.buttons {
  grid-area: buttons;
  display: flex;
  align-items: center;
}

.errors {
  grid-area: errors;
  /*margin-top: -14px;*/
}

.updateCustomFields .fieldsControls {
  display: grid;
  grid-template-columns: 4fr 5fr;
  grid-gap: 14px 28px;
}

.updateCustomFields .fieldsControls .fieldControl {
}

.updateCustomFields .fieldsControls .fieldControl.boolean {
  display: flex;
  align-items: center;
}

.updateCustomFields .fieldValue :global(.custom-field-label) {
  display: none !important;
}

.updateCustomFields .fieldValue :global(.field) {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.updateCustomFields .fieldValue :global(.phone-number .code-choice) {
  flex-basis: 6em;
}

.updateCustomFields .fieldValue :global(.phone-number .ui.fluid.input) {
  flex-basis: 50%;
}

.updateCustomFields .fieldValue :global(.ui.fluid.input) {
  flex: 1 0 100%;
}

.action.readonly .controls {
  grid-template-areas: "head body body";
  grid-template-columns: 3fr 5fr 4fr;
}

.action.readonly .buttons {
  display: none;
}

.updateCustomFields.readonly .controls {
  grid-template-columns: 3fr 4fr 5fr;
  grid-template-areas: "head body body";
}

.shortFields.readonly .controls {
  grid-template-columns: repeat(3, 4fr);
}

.action.readonly .errors {
  display: none;
}

.action.readonly :global(.ui.input) input,
.action.readonly :global(textarea),
.action.readonly :global(.ui.dropdown),
.action.readonly :global(.ui.ui.ui.ui.selection.dropdown),
.action.readonly :global(.ui.ui.ui.selection.dropdown) {
  border: 1px solid #eaeaea !important;
  background-color: #eaeaea !important;
  color: #9fa2ac !important;
  opacity: 1 !important;
  user-select: text;
}
.action.readonly :global(.ui.input.disabled) {
  opacity: 1;
}

.action.readonly :global(textarea) {
  padding: 7px 14px;
}

.action.readonly :global(textarea:hover) {
  box-shadow: none !important;
}

.action.readonly :global(.ui.ui.ui.ui.ui.ui.dropdown) {
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.action.readonly :global(.ui.ui.ui.selection.dropdown .text) {
  color: #9fa2ac !important;
}

.action.readonly :global(.ui.ui.ui.dropdown .search.icon),
.action.readonly :global(.ui.ui.ui.dropdown .dropdown.icon) {
  display: none;
}

.action.readonly.shortFields .controls {
  grid-template-columns: 3fr 5fr 4fr;
  grid-template-areas: "head input1 input2";
}

.action.readonly :global(.ui.label .close.icon),
.action.readonly :global(.ui.label .delete.icon) {
  display: none;
}
