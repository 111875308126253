.overlay {
  background: #fff;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.or {
  color: var(--GRAY-MID);
  margin: 5px 0;
}

.trigger {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 14px;
  color: var(--PRIMARY-BLUE);
  --color: var(--PRIMARY-BLUE);
  font-weight: 500;
  cursor: pointer;
}
.trigger:hover {
  text-decoration: underline;
  color: var(--LINK-HOVER);
}
