.content {
  flex-direction: column;
}

.completedTag {
  color: var(--SUCCESS-GREEN);
  font-weight: 500;
  font-size: 12px;
  margin-left: 25px;
  display: flex;
  align-items: center;
}

.checkIcon {
  --image: url("../../../assets/images/icons/tick-circle.svg");
  --width: 20px;
  --height: 20px;
  margin-right: 8px;
}

.rules {
  margin: 12px 0;
  display: flex;
  margin-left: -40px;
}

.rule {
  flex: 1;
  margin-right: 16px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  padding: 40px;
}

.rule.lastOne {
  margin-right: 0;
}

.header {
  width: 174px;
  margin-bottom: 16px;
}

.title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.desc {
  font-size: 14px;
  color: var(--GRAY-MID);
  margin-bottom: 21px;
}
.link {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.arrowIcon {
  --image: url("../../../assets/images/icons/arrow-right-action.svg");
  --width: 15px;
  --height: 11px;
  margin-left: 9px;
}
