.subModal {
  max-width: 496px;
  --border-radius: 16px;
}

.content {
  padding: 40px 48px !important;
  display: flex;
  justify-content: center;
  text-align: center;
}

.title {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 24px;
}

.listNameLabel {
  text-align: left;
  font-weight: 500;
  display: block;
  padding-bottom: 7px;
}

.listNameInput {
  width: 100%;
  margin-bottom: 24px;
}

.listNameInput:global(.error) {
  border-color: var(--FIELD-ERROR-RED);
  margin-bottom: 12px;
}

.errorMsg {
  color: var(--WARNING-TEXT);
  font-size: 12px;
  margin-bottom: 12px;
}

.actions {
  display: flex;
  justify-content: center;
}

.actions button:first-child {
  margin-right: 24px;
}
