.documentWrapper {
  width: 312px;
  height: 120px;
  display: flex;
  flex-direction: column;
}

.proxyDocumentWrapper {
  width: 312px;
  height: calc(300px - 24px - 46px);
  display: flex;
  flex-direction: column;
}

.proxyDocumentWrapper div {
  overflow: hidden;
}

.documentWrapper div {
  overflow: hidden;
}

.fileName {
  background-color: var(--WHITE);
  height: 46px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  overflow: hidden;
}

.fileInfo {
  height: 20px;
  margin-top: 12px;
  color: var(--GRAY-MID);
  display: flex;
  align-items: center;
}

.dot {
  width: 3px;
  height: 3px;
  border-radius: 3px;
  background-color: var(--GRAY-MID);
  margin: 0 8px;
  display: inline-block;
}

.page > canvas {
  border-radius: 8px;
}

.documentPreview {
  max-height: 660px;
  overflow: auto;
}

.previewPage {
  margin: 12px 0;
}

.placeholder {
  width: 312px;
  height: 120px;
  background-color: var(--GRAY-LIGHT);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.previewPlaceholder {
  width: 600px;
  height: 400px;
  background-color: var(--GRAY-LIGHT);
}

.placeholder.contentCenter,
.previewPlaceholder.contentCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
