.container {
  display: flex;
}

.container :global(.ui.input input) {
  width: 67px;
  height: 72px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  border-radius: 8px;
  font-weight: 600 !important;
  font-size: 25px !important;
  margin-right: 14px;
  color: rgba(60, 66, 87, 0.8) !important;
}

.container :global(.ui.input input.error) {
  border-color: var(--FIELD-ERROR-RED);
  background-color: #fff4f4;
}

.container :global(.ui.input:last-child input) {
  margin-right: 0;
}
