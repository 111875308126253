.whatsappContainer {
  flex-direction: column;
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
}

.container :global(.create-form) {
  width: 46%;
  margin: 0 auto;
}

.container:global(.full-width) {
  width: 100%;
  height: 100%;
}

.planSubscriptionHeaderContainer {
  width: 46%;
  margin: 0 auto 21px;
}

.extraTag {
  color: #217051;
  border: 1px solid #cbf4c9;
  border-radius: 5px;
  background: #cbf4c9;
  width: 120px;
  text-align: center;
  margin-bottom: 14px;
  margin-left: 75px;
}

.notes {
  padding-left: 75px;
  font-weight: 500;
}

.pricingTable {
  display: flex;
  flex-direction: column;
  padding-top: 21px;
}

.pricingTable :global(.table-row) {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: 1;
}

.pricingTable :global(.table-content) {
  flex: 1;
  padding: 14px 20px;
}

.pricingTable :global(.table-content .icon-row) {
  display: flex;
  align-items: center;
}

.pricingTable :global(.table-content .icon-row img) {
  margin-right: 4px;
}

.pricingTable :global(.table-content .icon-row img) {
  margin-right: 4px;
  height: 16px;
  width: auto;
}

.pricingTable :global(.table-content .icon-row .helper-text) {
  color: #9aa8bd;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  margin-top: 7px;
  line-height: 1.3;
}

.pricingTable :global(.table-content .icon-row .small-icon) {
  display: flex;
  align-items: center;
}

.bold {
  font-weight: 500;
}

.pricingTable :global(.table-row:first-child .table-content) {
  border-top-right-radius: 8px;
}

.pricingTable :global(.table-row:last-child .table-content) {
  border-bottom-right-radius: 8px;
}

.pricingTable :global(.table-row:nth-child(odd) .table-content) {
  background: #f0f2f5;
}

.pricingTable :global(.table-header) {
  background: #3c4257;
  color: #fff;
  padding: 15px 32px;
  flex: 0 1 212px;
}

.pricingTable :global(.header-title) {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pricingTable :global(.header-title svg) {
  margin-right: 10px;
  width: 17px;
}

.pricingTable :global(.table-row:first-child .table-header) {
  border-top-left-radius: 8px;
}

.pricingTable :global(.table-row:last-child .table-header) {
  border-bottom-left-radius: 8px;
}

.contentContainer {
  background: #fff;
  padding: 34px 31px;
  overflow-y: auto;
  border-radius: 8px;
  width: 100%;
}

.contentContainer:global(.topup) {
  height: 100%;
}

.contentContainer :global(.status-alert) {
  margin: 21px 0px;
}

.contentContainer > :global(.header) {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 21px;
}

.contentContainer :global(.header .text) {
  padding-left: 21px;
}

.contentContainer :global(.header .text .header) {
  font-size: 28px;
  font-weight: 500;
  padding-bottom: 7px;
  line-height: 1.2;
}

.contentContainer :global(.field-error) {
  font-size: 14px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 1.6em;
  margin-top: 8px;
  color: var(--FIELD-ERROR-RED);
  padding: 0 0 0 19px;
  position: relative;
  visibility: hidden;
  text-align: left;
  opacity: 0;
  transition: all 0.5s;
}

.contentContainer :global(.field-error:empty) {
  display: none;
}

.contentContainer :global(.field-error.visible) {
  visibility: visible;
  height: 1.6em;
  opacity: 1;
}

.contentContainer :global(.field-error.visible:before) {
  display: inline-block;
  content: url("../../assets/images/icons/warning.svg");
  width: 14px;
  height: 14px;
  position: absolute;
  left: 0px;
  top: 2px;
}

.contentContainer :global(.field-error > div) {
  flex: 1 0 auto;
  margin-right: 8px;
}

.contentContainer :global(.extra-info) {
  font-size: 12px;
  color: rgba(60, 66, 87, 0.8);
}

.contentContainer :global(label .sub-label) {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.6;
}

.link {
  font-weight: 500;
  color: var(--PRIMARY-BLUE) !important;
}

.link:hover {
  color: #4d67f9 !important;
  cursor: pointer;
}

.contentContainer :global(.upload-dropzone-input__body) {
  min-height: 150px;
}

.contentContainer :global(.upload-dropzone-images .image-item) {
  width: 130px;
  height: 130px;
  flex-basis: 130px;
  margin-bottom: 0;
}

.contentContainer :global(.upload-dropzone-input__wrap_empty) {
  padding: 7px 0px 0px;
}

.contentContainer :global(.upload-dropzone-images .image-item .delete-img) {
  width: 30px;
  min-height: auto;
}

.verificationDescription {
  padding-bottom: 28px;
}

.verificationDescription :global(.header) {
  font-weight: 500;
}

.verificationDescription :global(.subHeader) {
  font-size: 13px;
}

.next {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 21px !important;
  padding: 13px !important;
}

.footer {
  font-size: 14px;
}

.signupContainer {
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 30px 52px;
  justify-content: center;
  align-items: flex-start;
  background: #fff;
}

.signupContainer :global(.header) {
  font-size: 28px;
  font-weight: 500;
}

.signupContainer :global(.subHeader) {
  padding-bottom: 21px;
  color: var(--GRAY-MID);
}

.signupContainer :global(form) {
  width: 100%;
}

.signupContainer :global(.ui.button) {
  margin: 21px 0px 14px;
  padding: 8px !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signupContainer :global(.link2) {
  font-weight: 500;
  color: var(--PRIMARY-BLUE) !important;
}

.signupContainer :global(.link2:hover) {
  color: #4d67f9 !important;
  cursor: pointer;
}

.signupContainer :global(.field-error) {
  font-size: 14px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 1.6em;
  margin-top: 8px;
  color: var(--FIELD-ERROR-RED);
  padding: 0 0 0 19px;
  position: relative;
  visibility: hidden;
  text-align: left;
  opacity: 0;
  transition: all 0.5s;
}
.signupContainer :global(.field-error:empty) {
  display: none;
}

.signupContainer :global(.form .field-error.visible) {
  visibility: visible;
  height: 1.6em;
  opacity: 1;
}

.contentContainer :global(.form .field-error.visible:before) {
  display: inline-block;
  content: url("../../assets/images/icons/warning.svg");
  width: 14px;
  height: 14px;
  position: absolute;
  left: 0px;
  top: 2px;
}

.newNumberContainer {
  min-height: 400px;
  min-width: 890px;
}

.newNumberContainer :global(.ui.loader) {
  position: relative !important;
  display: block !important;
}

.newNumberContainer .connectGridContainer {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: repeat(auto-fill, auto);
  padding-bottom: 28px;
}

.newNumberContainer .unconnectGridContainer {
  display: grid;
  grid-template-columns: 68px auto auto auto;
  grid-template-rows: repeat(auto-fill, auto);
}

.newNumberContainer .title {
  font-weight: 500;
  size: 16px;
  margin: 28px auto;
}

.newNumberContainer > .title:first-child {
  margin-top: 7px;
}

.newNumberContainer .th {
  background-color: var(--GRAY-LIGHT);
  color: var(--GRAY-MID);
  padding: 15px 24px;
  font-weight: 500;
}

.newNumberContainer .radio {
  padding: 12px 24px;
}

.newNumberContainer .td {
  padding: 15px 24px;
}

.newNumberContainer .noFoundContainer {
  display: grid;
  grid-column: 1/5;
  justify-items: center;
  color: var(--GRAY-MID);
  font-weight: 500;
  margin: 24px auto;
}

.newNumberContainer .noFoundContainer svg {
  margin-bottom: 8px;
}

.setChannelNameForm {
  margin-bottom: 28px;
}

.connectionFeeContainer {
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  padding: 26px;
  border-radius: 10px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr auto;
  align-items: center;
}
.connectionFeeContainer .title {
  margin: 0px;
}
.connectionFeeContainer .description {
  color: var(--GRAY-MID);
}
.connectionFeeContainer .connectedPrice {
  color: var(--GRAY-MID);
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.connectionFeeContainer .connectedPrice .price {
  font-size: 16px;
  color: var(--BLACK);
  margin-right: 5px;
}

.setAutoTopUpForm {
  margin-bottom: 28px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  border-radius: 10px;
}

.setAutoTopUpForm .setAutoUpFormContent {
  padding: 26px;
}
.setAutoTopUpForm .setAutoUpFormFooter {
  padding: 26px;
  border-top: 1px solid var(--DISABLED-BORDER-COLOR);
  text-align: center;
}

.setAutoTopUpForm .title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}
.setAutoTopUpForm .subTitle {
  color: var(--GRAY-MID);
  margin-top: 0 !important;
}

.setAutoTopUpForm .feeDesc {
  margin-bottom: 8px;
}
.setAutoTopUpForm .fee {
  font-weight: 700;
}

.setAutoTopUpForm .calculator {
  font-size: 12px;
}

.laterBtn {
  margin-top: 16px !important;
  --bg-color: #e7eaff;
  --border-color: #e7eaff;
  --text-color: var(--PRIMARY-BLUE);
  --border-hover-color: #e7eaff;
}
