/*
  A mixin for icons that requires extra properties:
  --width
  --height
  --image
*/
.icon {
  content: "";
  display: inline-block;
  width: var(--widthOverride, var(--width, 16px));
  height: var(--heightOverride, var(--height, 16px));
  flex-basis: var(--widthOverride, var(--width, 16px));
  max-width: var(--widthOverride, var(--width, 16px));
  max-height: var(--heightOverride, var(--height, 16px));
  background-image: var(--image);
  background-size: var(--widthOverride, var(--width, 16px))
    var(--heightOverride, var(--height, 16px));
  background-repeat: no-repeat !important;
}

.icon.bg {
  background-position: 0 0;
  background-repeat: no-repeat !important;
  background-size: var(--widthOverride, var(--width, 16px))
    var(--heightOverride, var(--height, 16px));
  background-image: var(--image);
}

.icon.colored {
  background-image: none;
  background-color: var(--iconColor, var(--color, inherit));
  mask-image: var(--image);
  mask-position: 0 0;
  mask-size: var(--widthOverride, var(--width, 16px))
    var(--heightOverride, var(--height, 16px));
  mask-repeat: no-repeat;
}

.icon.bg {
  background-position: 0 0;
  background-repeat: no-repeat !important;
  background-size: var(--widthOverride, var(--width, 16px))
    var(--heightOverride, var(--height, 16px));
  background-image: var(--image);
}

.icon.colored {
  background-color: var(--iconColor, var(--color, inherit));
  mask-image: var(--image);
  mask-position: 0 0;
  mask-size: var(--widthOverride, var(--width, 16px))
    var(--heightOverride, var(--height, 16px));
  mask-repeat: no-repeat;
}

/*
  Overrides the Semantic icon with custom image and size
*/
:global(.icon).icon {
  content: "";
  display: inline-block;
  background-position: 0 0;
  background-repeat: no-repeat !important;
  background-size: var(--widthOverride, var(--width, 16px))
    var(--heightOverride, var(--height, 16px)) !important;
  background-image: var(--image, inherit);
  width: var(--widthOverride, var(--width, 16px)) !important;
  height: var(--heightOverride, var(--height, 16px)) !important;
}

/* Implementations */
.arrow {
  --image: url(./assets/arrow-right-lite.svg);
  --width: 15px;
  --height: 11px;
}

.arrow.right {
}

.arrow.left {
  transform: rotateZ(180deg);
}

.deleteBin {
  --width: 13px;
  --height: 15px;
  --image: url(./assets/delete-bin.svg);
}

.dropdownIcon {
  --width: 10px;
  --height: 6px;
  --image: url(./assets/dropdown-chevron.svg);
}

.dotsIcon {
  --width: 2px;
  --height: 10px;
  --image: url(../../../assets/images/ui/btn-dots-v.svg);
}

.dotsIcon.horizontal {
  transform: rotate(90deg);
}

.eye {
  --image: url(./assets/eye.svg);
  --width: 21px;
  --height: 17px;
}

.payment {
  --image: url("../../../assets/images/icons/payment-link.svg");
  --width: 8px;
  --height: 14px;
}

.info {
  --image: url("../../../assets/images/info_gray.svg");
  --width: 12px;
  --height: 12px;
}

.extLinkWhite {
  --image: url("../../../assets/images/icons/external-link-primary.svg");
  --width: 12px;
  --height: 12px;
}

.extLinkBlue {
  --image: url("../../../assets/images/icons/external-link.svg");
  --width: 12px;
  --height: 12px;
}

.errorCircle {
  --image: url("../../../assets/images/message-status/error.svg");
  --width: 15px;
  --height: 15px;
}

.refresh {
  --image: url("../../../assets/images/chat/refresh.svg");
  --width: 14px;
  --height: 14px;
}

.cross {
  --image: url("../../../assets/images/cross.svg");
  --width: 20px;
  --height: 20px;
}

.tick {
  --image: url("../../../assets/images/icons/tick-white.svg");
  --width: 12px;
  --height: 9px;
}

.tips {
  --image: url("../../../assets/images/tips.svg");
  --width: 22px;
  --height: 24px;
}

.lock {
  --image: url("../../../assets/images/lock-01.svg");
  --width: 13.3px;
  --height: 15px;
}
