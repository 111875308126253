.searchBar {
  display: flex;
  flex-direction: column;
  padding-top: 21px;
  padding-bottom: 2px;
}

.top {
  display: flex;
  justify-content: center;
}

.bottom {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  padding-top: 8px;
  font-size: 13px;
  color: var(--GRAY-MID);
}

.bottom > img {
  width: 23px !important;
  height: 21px !important;
  margin-right: 8px;
}

.inputWrapper {
  flex: 1 1 auto;
}

.inputWrapper input {
  margin-right: 14px !important;
}

.searchBar button {
  flex: 0 0 auto;
  max-height: 40px;
  color: var(--WHITE) !important;
}

.redirectIcon {
  margin-left: 8px;
  --color: var(--WHITE);
}
