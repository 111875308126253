.container {
  padding: 10px 0px 4px;
}
.container .header {
  color: #6a6e81 !important;
}
.note {
  color: var(--GRAY-MID);
  padding-top: 12px;
  font-size: 13px;
}
.storeSelection {
  padding-top: 24px;
}
.storeSelection .dropdown {
  margin-top: 12px;
}
