:root {
  --VALID: #178852;
  --INVALID: #9a4f21;
}

.banner {
  position: sticky;
  top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding: 13px 16px;
  border-radius: 8px;
}

.banner.within {
  color: var(--VALID);
  background-color: #eeffeb;
}
.banner.over {
  color: var(--INVALID);
  background-color: #fff7e0;
}

.infoIcon {
  --size: 14px;
  margin-right: 8px;
  flex: 0 0 auto;
}

.infoIcon.within {
  --color: var(--VALID);
}

.infoIcon.over {
  --color: var(--INVALID);
}

.description b {
  margin: 0 2px;
}
.description {
  font-size: 12px;
  margin-right: 12px;
}

.banner a,
.banner a:link,
.banner a:visited,
.banner a:visited:hover {
  text-decoration: underline !important;
  color: var(--INVALID) !important;
  font-weight: 500;
}

.button {
  margin-left: 11.5px;
  text-decoration: underline;
  font-size: 13px;
  cursor: pointer;
  font-weight: 500;
}
