.root {
  margin: 15px -10px 0 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  min-height: min-content;
  max-height: min-content !important;
}

.root :global(.label) {
  display: flex;
  justify-content: stretch;
  flex-basis: 22%;
  position: relative;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 0;
  padding: 6px;
  overflow-wrap: anywhere;
  height: 3.9em;
}

.root :global(.label > span) {
  text-overflow: ellipsis;
  overflow: hidden;
}

.close {
  cursor: pointer;
  flex: 0 0 auto;
  margin: 0 0 0 auto;
  padding: 0 0 0 8px;
}
.fileIcon {
  align-self: center;
  margin-right: 8px;
  margin-left: 3px;
}

.image {
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image img {
  top: 0;
  left: 0;
  height: auto !important;
  width: auto !important;
}
