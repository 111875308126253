.root {
  display: grid;
  height: 100%;
  grid-template-rows: min-content minmax(0, auto) min-content;
  grid-template-areas: "head" "items" "footer";
}

.head {
  grid-area: head;
  background-color: #fff;
}

.head .title {
  font-size: 20px;
  font-weight: 500;
  padding: 0 0 24px;
}

.settings {
  padding: 0 0 24px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
}

.settings .currency {
  flex: 0 1 auto;
  margin-right: 70px;
  transform: translateY(2px);
  position: relative;
  z-index: 10;
}

.currency :global(.ui.ui.dropdown) {
  min-height: 41.2px !important;
}

.head .label {
  font-size: 14px;
  font-weight: 500;
  padding-right: 16px;
  margin: 0 0 10px;
}

.popup {
  width: 300px;
}

.items {
  position: relative;
  grid-area: items;
  overflow-y: auto;
}

.item {
  margin: 0 0 20px;
}

.add {
  position: sticky;
  bottom: 0;
  padding: 20px 0;
  background-color: #fff;
  --color: var(--BLACK);
}

.add svg {
  width: 14px;
  height: 14px;
  margin-right: 8px;
}

.footer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--DISABLED-BORDER-COLOR);
  padding: 21px 0 0;
}

.status {
  visibility: hidden;
}

.status.visible {
  visibility: visible;
}

.status .subtotal {
  visibility: hidden;
  margin: 0 0 5px;
}

.status .subtotal.visible {
  visibility: visible;
}

.status .totals {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.status .text {
  margin-right: 14px;
  flex: 0 1 auto;
}

.status .discount {
  flex: 0 1 auto;
}

.status .errors .error {
  margin-top: 1px;
}

.actions {
}
