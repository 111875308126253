.sendDirectMessage {
  line-height: 16.71px;
}

.sendDirectMessage :global(.control-text) {
  flex: 1 0 auto;
  align-self: stretch;
  margin-right: 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
}

.labelSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 38px;
  margin-bottom: 24px;
}

.title {
  display: flex;
  align-items: center;
}

.title :global(label) {
  margin-right: 87px;
  padding-bottom: 0px !important;
}

.title :global(.ui.dropdown) {
  display: flex !important;
  width: 208px;
  border: 1px solid var(--DISABLED-BORDER-COLOR) !important;
}

.sendDirectMessage :global(textarea) {
  height: 320px !important;
  flex: 1 1 auto;
  padding: 25px 35px 45px;
  overflow: auto;
  word-break: break-word;
  white-space: pre-wrap;
  margin-bottom: 0;
  border-width: 2px;
  resize: none;
}

.sendDirectMessage :global(.text-controls) {
  flex: 0 0 auto;
  align-self: flex-start;
  padding: 0 10px;
  height: 40px;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: -40px;
  position: relative;
}

.subTitle {
  margin-bottom: 7px;
  font-weight: 500;
}

.errorMessage {
  margin-top: 14px !important;
}
