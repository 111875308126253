.templateLang {
  margin-top: 12px;
}

.templateContent {
  padding-top: 8px;
}

.actionBtnWrapper {
  justify-content: flex-end !important;
}

.reminder {
  color: var(--GRAY-MID);
  margin-bottom: 12px;
  margin-top: -4px;
}

.optional {
  margin-left: 22px;
  color: var(--GRAY-MID);
}

.headerInputLabel {
  margin-left: 8px;
}

.headerTypeWrapper {
  display: flex;
  flex-wrap: wrap;
}

.headerTypeDropdown {
  width: 160px;
}

.headerTypeInput {
  flex: 1;
  margin-left: 8px;
}

.buttonTypeDropdown {
  width: 50%;
}

.categoryOpt {
  padding: 8px 0;
}

.categoryOptTitle {
  font-weight: 700;
}

.categoryOptDesc {
  font-weight: normal;
  margin-top: 12px;
}

.categoryOptExample {
  font-weight: normal;
  margin-top: 12px;
  color: var(--GRAY-MID);
}

.isRequiredSampleReminder {
  color: var(--GRAY-MID);
}

.headerWithActions {
  grid-area: header;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-bottom: 11px;
}

:global(.content).updateMode {
  padding-top: 0 !important;
}
