.header {
  display: flex;
}

.header img {
  cursor: pointer;
}

.title {
  margin-right: 7px;
  font-weight: 600;
  font-size: 14px;
}

.description {
  color: var(--GRAY-MID);
}
