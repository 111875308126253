.fileInput {
  display: none !important;
}

.imageLabel {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.imageLabel p {
  color: var(--PRIMARY-BLUE);
}

.previewImage {
  width: 80px !important;
  height: 80px !important;
  object-position: center !important;
  object-fit: cover !important;
  border: 1px solid var(--GRAY-UI);
  border-radius: 100%;
}

.imagePlaceholder {
  width: 80px;
  height: 80px;
  border: 1px dashed var(--GRAY-MID);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
}

.imagePlaceholder i {
  width: 20px;
  height: 20px;
  margin: 0 0 4px 0 !important;
}
