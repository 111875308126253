.action {
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #eaeaea;
  position: relative;
}

.action:last-child {
  margin-bottom: 0;
}

.action.readonly:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.action .close {
  position: absolute;
  right: 0;
  top: 12px;
  --cross-color: var(--GRAY-MID);
  cursor: pointer;
}

.action .close:hover {
  --cross-color: var(--GRAY-DARK);
}

.action .close svg {
  width: 18px;
  height: 18px;
}

.list {
  margin-bottom: 30px;
}

.title {
  display: block;
  font-weight: 500;
  padding-bottom: 1em;
  margin-bottom: 0;
}

.section {
  margin-bottom: 30px;
}

.section:last-child {
  margin-bottom: 0;
}

.section .ui.dimmer {
  box-sizing: content-box;
  padding: 20px 80px;
  margin-left: -80px;
  margin-top: -20px;
}

.section label {
  font-weight: 500;
  padding-bottom: 1em;
  display: block;
}
