.noAccount {
  margin-top: 100px;
}

.noAccount .header {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.noAccount .icon {
  width: 80px;
}

.noAccount .title {
  font-size: 20px !important;
  margin-bottom: 8px;
  text-align: center;
}

.noAccount .description {
  margin-bottom: 24px;
}
