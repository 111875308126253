.tableRow :global(td) {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.qrCodeChannel {
  overflow: unset !important;
  min-width: 224px;
  vertical-align: top !important;
}

.qrCodeChannel :global(.ui.channels-dropdown.dropdown.selection) {
  border: 1px solid var(--DISABLED-BORDER-COLOR) !important;
  border-radius: 3px !important;
  min-height: 37px;
  max-height: 37px;
  padding: 9px 10px 8px !important;
}

.qrCodeChannel :global(.channels-dropdown .text) {
  position: relative;
  line-height: 16.71px;
  color: var(--BLACK);
  font-weight: 400 !important;
  max-width: 90%;
}

.qrCodeChannel :global(.selected-dropdown img) {
  max-width: 20px !important;
  max-height: 20px !important;
  width: 20px !important;
  height: 20px !important;
}

.qrCodeChannel :global(.channels-dropdown .icon) {
  top: 2px !important;
  right: 10px !important;
  padding: 0 !important;
  margin: 0 !important;
  width: 10px !important;
  height: 10px !important;
}

.qrCodeChannel :global(.channels-dropdown .menu) {
  border: 1px solid var(--DISABLED-BORDER-COLOR) !important;
  background-color: var(--WHITE);
  padding: 13px 7px !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  margin-top: 6px !important;
  width: 100%;
}

.assignment {
  overflow: unset !important;
  min-width: 224px;
}

.assignment :global(.ui.grid) {
  margin: unset !important;
}

.assignment :global(.ui.grid > .row) {
  padding: unset !important;
}

.assignment :global(.ui.ui.ui.ui.assignment-staff) {
  border: 1px solid var(--DISABLED-BORDER-COLOR) !important;
  border-radius: 3px !important;
  min-height: 37px !important;
  padding: 10px !important;
}

.assignment :global(.dropdown .text) {
  position: relative !important;
  line-height: 16.71px !important;
  color: var(--BLACK) !important;
  font-weight: 400;
  max-width: 90%;
}

.assignment :global(.dropdown .icon) {
  top: 2px !important;
  right: 10px !important;
  padding: 0 !important;
  margin: 0 !important;
  width: 10px !important;
  height: 10px !important;
}

.assignment :global(.input) {
  width: 224px;
}

.assignment :global(.arrow.left.icon) {
  max-width: 16px !important;
  min-width: 16px !important;
  margin-right: 11px !important;
  font-size: unset !important;
}

.singleDownload {
  text-align: center !important;
}

.singleDownload :global(.active) {
  --color: var(--BLACK);
}

.singleDownload :global(.disabled) {
  --color: var(--DISABLED-BORDER-COLOR);
}

.downloadAll {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: calc(14px - 8px) !important;
}

.saveButton {
  margin-right: calc(14px - 8px) !important;
}

.qrCodeIcon {
  --color: var(--BLACK);
  margin-right: 10px;
  width: 14px;
  height: 14px;
}
