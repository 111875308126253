.root {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 20px;
}

.button {
  flex: 0 0 auto;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  width: 30px;
}

.button:first-of-type {
  padding-left: 0;
}

.button:last-of-type {
  padding-right: 0;
}

.button.disabled {
  opacity: 0.6;
}

.button.left span {
  transform: rotateZ(90deg);
}

.button.double span + span {
  margin-left: -4px;
}

.button.right span {
  transform: rotateZ(-90deg);
}

.info {
  flex: 1 0 auto;
  text-align: center;
  font-weight: 500;
}
