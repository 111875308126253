.contactSupportHeaderHelpSection {
  display: flex;
  flex-direction: column;
  font-weight: 400;
}

.contactSupportTitleLabel {
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 16px;
}

.contactSupportNeedHelp {
  font-size: 14px;
  color: var(--DISABLED-FONT-COLOR);
}
