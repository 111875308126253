.root {
  display: flex;
  align-items: center;
}

.root > :global(.ui.form) {
  min-width: 200px;
}

.form {
  flex: 1 1 auto;
}

.periods {
  margin-left: 10px;
  flex: 1 0 auto;
}
