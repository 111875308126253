.label {
  display: inline-flex;
  border-radius: 5px;
  padding: 0px 10px;
  font-size: 10px;
  font-weight: 500;
  background-color: #f3e6be;
  color: #9a4f21;
  min-height: 22px;
  text-align: center;
  align-items: center;
}
