.channel {
  position: relative;
  flex: 0 1 410px;
  min-height: 97px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: stretch;
  padding: 0 21px 2px 21px;
  margin: 0 12px 20px;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #eaeaea;
}

.channel:hover {
  border: 1px solid var(--FOCUS-INPUT-BORDER-BLUE);
  box-shadow: 0 0 1px 3px var(--FOCUS-INPUT-BORDER-BLUE-SHADOW) !important;
}

.channel.noMargin {
  margin: 0;
}

.imagePlaceholder {
}

.image {
  flex: 0 0 40px;
  margin-right: 25px;
  height: 40px;
  display: flex;
}

.buttons {
  display: inline-flex;
}

.buttons :global(.button),
.buttons :global(.ui.dropdown.ui.ui.ui) {
  margin-left: 7px;
  margin-right: 0;
}

.buttons :global(.button:first-child) {
  margin-left: 0;
}

.addChannel {
  margin: 0 auto;
}

.actionsMenu {
  position: absolute;
  right: 16px;
  padding: 0 10px 8px;
  border: solid 1px #eaeaea !important;
}

.actionsMenu > span {
  font-weight: 500;
}

.actionsMenu > span i.icon {
  margin-left: 5px;
}

.actionsMenu:hover .icon.button-dots {
  content: url("../../assets/images/ui/btn-dots-v-hover.svg");
}

.price {
  position: absolute;
  top: 15px;
  right: 25px;
  color: var(--GRAY-MID);
  font-size: 12px;
}

.actionsTrigger {
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.actionsTrigger .icon {
  width: auto;
  height: auto;
  margin: 0;
}

.description {
  flex: 1 1 210px;
  margin: 0 7px 0 0;
  padding: 7px 0px;
}

.description > :global(.ui.placeholder) {
  margin-top: -1em;
}

.nameWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
}

.installMode {
  text-transform: capitalize;
  font-size: 10px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  margin-bottom: 5px;
  display: inline-flex;
  align-items: center;
  padding: 0 10px;
  justify-content: center;
  min-height: 22px;
}

.installMode.easy {
  color: #0f6245;
  background: #cbf4c9;
}

.installMode.moderate {
  color: #a34b19;
  background: #f6e6b9;
}

.installMode.advanced {
  color: #a51d50;
  background: #fce2dd;
}

.name {
  font-weight: 500;
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label {
  text-align: center;
  border-radius: 5px;
  font-size: 10px;
  margin-right: 10px;
  cursor: pointer;
  display: inline-flex;
  min-height: 22px;
  line-height: 1.1;
  align-items: center;
  padding: 0 10px;
}

.label.vendor {
  color: #3c4257;
  background-color: #f0f0f0;
}

.label.none {
  background-color: #d8d8d8;
  color: #4a4a4a;
}

.label.connecting {
  background-color: #ecefff;
  color: #6078ff;
}

.label.authenticated,
.label.synced {
  color: #0f6245;
  background-color: #cbf4c9;
}
.label.discontinued,
.label.loading,
.label.queueisfull,
.label.invalid,
.label.reconnectinstance {
  color: #a51d50;
  background-color: #fce2dd;
}

.label.rebooting {
  color: #a34b19;
  background-color: #f6e6b9;
}

.label.syncing {
  color: #676dbf;
  background-color: #d8ebff;
}

.label.nohistory {
  color: #ffe6e6;
  background-color: #ff7474;
}

.params {
  color: var(--GRAY-MID);
  line-height: 1.3;
  font-size: 13px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 7px;
}

.labels {
}

.params > span {
  display: flex;
}

.paramLabel {
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  max-width: 180px;
  text-overflow: ellipsis;
}

.divider {
  display: inline-block;
  margin-left: 0.4em;
  margin-right: 0.4em;
}

.statusTooltip:global(.ui.popup) {
  top: 0 !important;
  left: 0 !important;
}

.channel :global(.placeholder) {
  flex: 1 1 auto;
}

.freeTrialTag {
  background-color: #e9f1f8 !important;
  color: #5ebada !important;
  font-size: 12px !important;
  font-weight: 500;
}
