.resetPasswordAction {
  padding-top: 5px;
}

.modalContent {
  padding: 32px;
  position: relative;
}

.modalHeader {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 32px;
}
.closeButton {
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
}
.modalBody {
  color: var(--GRAY-MID);
  margin-bottom: 32px;
  line-height: 160%;
  white-space: pre-line;
}

.description {
  margin-bottom: 32px;
}

.or {
  color: var(--GRAY-MID);
  margin: 5px 0;
  height: 22px;
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.or::before {
  content: "";
  height: 12px;
  flex: 1;
  border-bottom: 1px solid var(--DISABLED-BORDER-COLOR);
  margin-right: 8px;
  justify-self: flex-start;
}

.or::after {
  content: "";
  height: 12px;
  flex: 1;
  border-bottom: 1px solid var(--DISABLED-BORDER-COLOR);
  margin-left: 8px;
}

.modalAction {
  display: flex;
  justify-content: flex-end;
}
