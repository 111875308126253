.wrapper {
  width: 100%;
  height: auto !important;
}
.container {
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 30px 52px;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  border-radius: 8px;
}
.container :global(.congratulation:after) {
  content: "\1F389";
  width: 62px;
  height: 62px;
  font-size: 62px;
}
.container :global(.header) {
  padding-top: 35px;
  font-weight: 500;
  font-size: 28px;
}
.container :global(.content) {
  color: var(--GRAY-MID);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 4px;
}
.container :global(.content .paragraph) {
  padding-top: 16px;
}
.container :global(.ui.button) {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 21px auto 0px;
  padding: 12px !important;
}
