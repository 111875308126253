.component {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.close {
  --color: var(--BLACK);
  position: absolute;
  top: 30px;
  right: 50px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.close svg {
  width: 24px;
  height: 24px;
}
