.root {
}

.container {
  background: #fff;
  padding: 34px 31px;
  overflow-y: auto;
  border-radius: 8px;
}

.intro {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.cheers:after {
  content: "🎉";
  width: 62px;
  height: 62px;
  font-size: 62px;
  text-align: center;
  margin: 0 0 28px;
}

.intro .header {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 14px;
  text-align: center;
}

.intro .headerSingle {
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 500;
}

.accessLevel {
  color: var(--GRAY-MID);
  margin-bottom: 30px;
  text-align: center;
}

.accessLevel .label {
  margin-left: 7px;
}

.footer {
  font-size: 14px;
  text-align: center;
}

.footerLink {
  font-weight: 500;
  color: var(--PRIMARY-BLUE);
}

.footerLink .extLink {
  margin-left: 7px;
}

.secondaryActions {
  display: flex;
  justify-content: flex-end;
}

.actions {
  margin: 0 0 28px;
}

.actionWrap {
  margin: 0 0 14px;
  border-radius: 5px;
  padding: 21px 35px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
}

.info {
  --image: url("../../../assets/images/info_gray.svg");
  --width: 12px;
  --height: 12px;
  margin-left: 7px;
  vertical-align: middle;
}

.extLink {
  --image: url("../../../assets/images/icons/external-link-blue.svg");
  --width: 12px;
  --height: 12px;
}
