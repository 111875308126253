.close {
  position: absolute;
  right: -40px;
  top: 3px;
}

.close .icon {
  --image: url("../../shared/modal/assets/close.svg");
  --width: 18px;
  --height: 18px;
  cursor: pointer;
}
