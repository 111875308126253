.modal {
  max-height: calc(100vh - 80px) !important;
  border-radius: 16px;
}

.modal.full {
}

.modal.brief {
  max-width: 640px;
  --border-radius: 16px;
}

.body {
  display: grid;
  grid-template-areas: "head" "body" "footer";
  grid-template-rows: auto minmax(1fr, auto) auto;
  padding: 25px 25px 30px;
}

.modal.brief .body {
  padding-top: 17px;
}

.head .title {
  font-weight: 500;
  font-size: 20px;
  margin: 0 0 25px;
}

.head .total {
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 5px;
}

.tip {
  margin: 0 0 30px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0 0;
  border-top: 1px solid var(--GRAY-SHADOW);
}

.footer .total {
  flex: 0 1 auto;
}

.footer .actions {
  flex: 0 1 auto;
  margin-left: auto;
}

.list {
  margin-bottom: 20px;
}

.full .list .item {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  margin: 0 0 8px;
}

.full .list .item .pic {
  border-radius: 8px;
  background-color: #ceebff;
  margin-right: 12px;
  width: 80px;
  height: 80px;
}

.full .list .item .pic.empty {
  background-color: var(--GRAY-LIGHT);
}

.full .list .item .pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.full .list .item .price {
  margin-left: auto;
}

.brief .list {
  display: table;
  margin-bottom: 25px;
}

.brief .list .item {
  display: table-row;
}

.brief .list .item > div {
  padding: 14px 0;
  display: table-cell;
  border-bottom: 1px solid #eaeaea;
}

.brief .list .item.last > div {
  border-bottom: none;
}

.brief .list .item .id {
  padding-right: 90px;
}

.brief .list .item .quantity {
  padding-right: 40px;
}

.brief .list .item .price {
  text-align: right;
}

.productName {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  max-height: 4em;
}

.hoverable._._._:global(.button.disabled):hover {
  background-color: #d8d8d8 !important;
  opacity: 1 !important;
  box-shadow: none !important;
  cursor: not-allowed;
}
