.modal {
  width: 849px !important;
  height: 640px !important;
  padding: 40px 68px 37px 56px !important;
  margin: unset !important;
}

.modal:global(.visible.transition) {
  display: flex !important;
}

.modal :global(.close) {
  top: 0 !important;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main {
  flex: 1 1 auto;
}

.title {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 4px;
}

.description {
  padding-top: 4px;
  color: rgba(60, 66, 87, 0.8);
  padding-bottom: 24px;
  line-height: 1.6;
}

.topicHint {
  color: var(--GRAY-MID);
  margin-left: 8px;
}

.message {
  margin-bottom: 24px !important;
}

.message > textarea {
  height: 80px !important;
  min-height: 80px !important;
}

.labelHint {
  color: var(--GRAY-MID);
  line-height: 1.6;
}

.preview {
  margin-left: 29px;
  flex: 0 0 auto;
  background-image: url("../../../../../assets/images/inbox-facebook/fb-notify-me-preview.png");
  background-position: center;
  background-repeat: no-repeat;
  width: 246px;
  height: 492px;
  position: relative;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
}

.preview :global(.loader) {
  position: relative;
}

.top {
  margin-left: 32px;
  margin-top: 6px;
}

.top :global(.avatar) {
  width: 22px !important;
  height: 22px !important;
  margin-right: 7px;
}

.top :global(.channelName) {
  font-weight: 700;
  font-size: 10px;
}

.center {
  text-align: center;
  margin-top: 17px;
}

.center :global(.avatar) {
  width: 64px !important;
  height: 64px !important;
  margin-bottom: 4px;
}

.center :global(.channelName) {
  margin-top: 4px;
  font-size: 14px;
  font-weight: 700;
}

.brand {
  margin-top: 7px;
  color: var(--GRAY-MID);
  font-size: 10px;
}

.bottom {
  position: absolute;
  bottom: 54px;
  left: 10px;
  display: flex;
  align-items: flex-end;
}

.bottom :global(.avatar) {
  width: 20px !important;
  height: 20px !important;
}

.messageContainer {
  padding: 8px;
  min-height: 106px;
  width: 170px;
  margin-right: 38px;
  background-color: #f1f1f1;
  border-radius: 8px;
  margin-left: 8px;
}

.messageContainer :global(.message) {
  min-height: 24px;
  border-radius: 4px;
  margin-bottom: 4px;
  word-wrap: break-word;
  display: block;
  width: 154px;
}

.messageContainer :global(.message.empty) {
  border: 1px dashed rgba(60, 66, 87, 0.8);
}

.messageContainer :global(.message.text) {
  border: none;
  line-height: 12px;
  margin-bottom: 7px;
  font-size: 10px;
}

.messageContainer :global(.hint) {
  margin-bottom: 8px;
  color: #7d7d7d;
  font-size: 8px;
}

.messageContainer :global(.button) {
  background: #fbfbfb;
  border-radius: 8px;
  padding: 6px 53px;
  font-size: 10px;
  font-weight: 600;
  white-space: nowrap;
}

.messageContainer .prefix {
  padding-right: 2px;
}

.label {
  margin-bottom: 24px !important;
}

.topic {
  margin-bottom: 25px !important;
}

.messageError:global(.visible) {
  margin-top: 8px !important;
}

.messageError:global(.hidden) {
  padding-left: 0 !important;
  margin-top: 0 !important;
  width: 0 !important;
}

.InActivatedError {
  margin-top: 16px !important;
}

.InActivatedError:global(.hidden) {
  padding-left: 0 !important;
  margin-top: 0 !important;
  width: 0 !important;
}
