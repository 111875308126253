.container {
  background: var(--WHITE);
  border-radius: 5px;
  margin-top: 20px;
}
.container .header {
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--DISABLED-BORDER-COLOR);
}
.container .list {
  display: flex;
  flex-direction: column;
}
.container .header .title {
  font-size: 20px;
  font-weight: 500;
}
.container .channelSelection {
  padding: 16px 40px;
}

.container .channelSelection .title {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 8px;
}
.container .channelSelection .subTitle {
  color: var(--GRAY-MID);
}
.container .channelSelection :global(.channel-list) {
  margin-top: 24px;
}
