.contactCount {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
}

.conditionTitle {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}

.conditionDesc {
  font-weight: 500;
  margin-bottom: 14px;
}

.conditionRow {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.conditionField,
.conditionValue {
  width: 30%;
}

.conditionEqual,
.conditionDelete {
  padding: 0 21px;
  display: flex;
  align-items: center;
}

.crossIcon {
  --image: url(../../../../../assets/images/cross.svg);
  --width: 18px;
  --height: 18px;
  cursor: pointer;
}

.limitInfoWrapper {
  padding: 24px 20px;
  background-color: #fff4e6;
  border-left: 8px solid #ff8d00;
}

.limitInfoTitle {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: var(--FIELD-ERROR-RED);
  margin-bottom: 8px;
}

.limitInfoDesc {
  white-space: pre-line;
}

.infoIcon {
  --image: url(../../../../../assets/images/warning.svg);
  --width: 24px;
  --height: 24px;
  margin-right: 8px;
}
