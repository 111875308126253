.share {
  flex: 1 1 50%;
  border-left: 0.5px solid var(--DISABLED-BORDER-COLOR);
  padding: 0 15px 24px 24px;
}

.share .button {
  margin-top: 20px;
  width: 100%;
  justify-content: center;
  padding: 10px !important;
}
