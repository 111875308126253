.wrapField .label .required {
  color: var(--FIELD-ERROR-RED);
  padding-left: 5px;
}

.wrapField .label {
  position: relative;
}

.wrapField .label .limit {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--GRAY-MID);
}
.hasTime :global(.react-datepicker) {
  min-width: 304px;
}
