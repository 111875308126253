.labels {
  max-height: 257px;
  overflow: hidden auto;
}
.labels .item {
  margin: 0 0 12px;
  cursor: pointer;
}

.labels .item .label {
  cursor: pointer;
}

.addLabelDropdown :global(.ui.grid > .row) {
  padding-top: unset !important;
  padding-bottom: unset !important;
}

.addLabelDropdown :global(.field) {
  margin-bottom: 0 !important;
  padding-bottom: 8px !important;
}

.addLabelDropdown :global(.selected.item.addition > .text > b) {
  padding-left: 5px !important;
}

.error:global(.hidden) {
  margin-top: 0 !important;
  padding-left: 0 !important;
}
.error:global(.visible) {
  margin-top: 11px !important;
  margin-bottom: 16px !important;
}
