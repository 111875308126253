.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000000;
  background-color: #fff;
  padding: 25px 20px 10px;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
}

.logo {
  margin: 0 0 30px;
}

.logo img {
  max-width: 145px;
  width: 90%;
}

.logo img {
  user-select: none;
}

.body {
  background-color: #f2f7ff;
  border-radius: 32px;
  padding: 48px 27px 52px;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  gap: 24px;
  margin: 0 0 20px;
}

.head {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.25;
}

.downloadLink {
  text-transform: uppercase;
  color: #6a6e81;
  font-size: 14px;
  font-weight: 600;
}

.apps {
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
}

.apps img {
  max-width: 134px;
  width: 50%;
}

.home {
  display: inline-block;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.home .link {
  color: #576e93 !important;
  font-weight: 600;
  cursor: pointer;
}

.home .link:hover {
  text-decoration: underline;
}
