.link {
  color: var(--PRIMARY-BLUE);
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
  color: var(--LINK-HOVER);
}

.backLink {
  color: var(--PRIMARY-BLUE);
  cursor: pointer;
  background: transparent;
  padding-left: 0px;
}

.backLink :global(img) {
  padding-right: 11px;
}

.backLink:hover {
  background: transparent;
  text-decoration: underline;
  color: var(--LINK-HOVER);
}

.variantGrid {
  display: grid;
  grid-template-areas:
    "back header"
    "image content";
  gap: 18px;
  grid-template-columns: 170px auto;
  grid-template-rows: auto 1fr;
  height: 100%;
}

.variantGrid .backLink {
  grid-area: back;
}

.content {
  grid-area: content;
  padding-left: 10px;
  overflow: hidden;
}

.content .description {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 14px;
}

.content .description .id {
  padding-right: 10px;
  color: var(--DISABLED-FONT-COLOR);
}

.content .description .link {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.content .description .link.disabled {
  color: var(--GRAY-MID);
  opacity: 50%;
  cursor: not-allowed;
}

.content .description .link.disabled :global(.loader.ui) {
  margin-right: 4px;
}

.content .description .link.disabled :global(.loader.ui):before {
  border-color: var(--GRAY-MID);
}

.content .description .link .text {
  padding-left: 10px;
}

.content .amount {
  color: var(--BLACK);
  font-weight: 500;
}

.table:global(.hide-scrollable-table) {
  grid-area: table;
  padding-top: 16px;
}

.table :global(table.ui.table thead tr th) {
  background: var(--GRAY-LIGHT);
  text-transform: uppercase;
  color: var(--GRAY-MID) !important;
  text-align: center;
}

.image {
  grid-area: image;
  border-radius: 8px;
  width: 100%;
}

.header {
  grid-area: header;
  padding-left: 10px;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: var(--BLACK);
}
