.root {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  margin-right: -5px;
}

.text {
  margin-right: 7px;
}
