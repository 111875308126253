.form {
  display: flex;
  flex-flow: row nowrap;
  margin: 0 0 21px;
}

.form.hideLabel {
  max-width: 85%;
}

.form.hideLabel .control .label {
  display: none;
}

.form.noGap {
  margin-bottom: 0;
}

.form.hideLabel .control .label {
  display: none;
}

.control {
  margin: 0 20px 0 0;
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  flex-flow: column nowrap;
  align-items: stretch;
}

.control .label {
  font-weight: 500;
  margin-bottom: 7px;
}

.control .input > :global(.input) {
  width: 100%;
  height: 43px;
}

.control.button {
  flex: 0 1 auto;
  display: flex;
  align-items: flex-end;
  margin-right: 0;
}

.reset {
  padding: 8px 15px !important;
}
