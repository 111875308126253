.container {
}

.box {
  display: grid;
  grid-template-areas:
    "message preview"
    "note .";
  grid-template-columns: 1fr 420px;
  padding: 48px 0px;
}

.box .message .title {
  font-size: 16px;
  font-weight: 500;
}

.box .message .subTitle {
  color: var(--GRAY-MID);
}

.box .message .content {
  padding-top: 24px;
  position: relative;
}

.box .message {
  padding-right: 32px;
  grid-area: message;
}

.box .preview {
  width: 320px;
  display: flex;
  flex-direction: column;
  grid-area: preview;
}

.box .preview .title {
  font-weight: 500;
}

.paymentButton {
  grid-area: note;
}

.paymentButton .note {
  padding: 23px 0px 16px;
  color: var(--GRAY-MID);
}

.box:last-child {
  border-top: 1px solid var(--DISABLED-BORDER-COLOR);
}

.saveButton {
  margin-bottom: 14px !important;
}

.textarea {
  padding-top: 24px;
}
