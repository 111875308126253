.checkboxGroup {
  margin-top: 28px;
}

.checkboxGroup :global(.field) {
  display: flex;
  align-items: center;
}

.infoIcon {
  --image: url("../../../../assets/images/icons/info_bg_gray.svg");
  --width: 14px;
  --height: 14px;
  margin-left: 8px;
  cursor: pointer;
}
