.content {
  position: relative;
  display: grid;
  grid-template-rows: 1fr min-content;
  grid-template-columns: 1fr;
}

.textarea {
  grid-column: 1;
  grid-row: 1 / 3;
  padding-bottom: 26px !important;
  margin-bottom: 0 !important;
  font-weight: 500;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  border-radius: 5px;
  height: 160px !important;
  resize: none !important;
}

.control {
  grid-column: 1;
  grid-row: 2 / 3;
  margin: 0 1px 1px;
  border-radius: 0 0 6px 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding: 4px 21px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.count {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  color: var(--GRAY-MID);
}
