.title {
  text-align: center;
  margin-top: 16px;
  font-size: 20px;
  font-weight: 500;
}

.desc {
  text-align: center;
  margin: 12px 0 0;
}

.paymentDetail {
  width: 100%;
  background-color: #f3f8ff;
  border-radius: 5px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
}

.companyName,
.price {
  flex: 1;
  font-weight: 700;
}

.price {
  text-align: right;
  text-transform: uppercase;
}

.footer {
  margin-top: 120px;
  display: flex;
}

@media screen and (max-width: 500px) {
  .footer {
    margin-top: 32px;
  }
}
