.root {
  --maxCurtainHeight: 400px;
}

.frame {
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  background: #fff;
}

.curtain {
  max-height: var(--maxCurtainHeight);
  animation-duration: 400ms;
  animation-fill-mode: both;
  margin: 0 0 10px;
  animation-timing-function: ease-in-out;
}

.root.visible .curtain {
  animation-name: collapseIn;
}

.root.hidden .curtain {
  animation-name: collapseOut;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.groupLabel {
  font-size: 12px;
  font-weight: 500;
}

.item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.item .tick {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.item .label {
  white-space: nowrap;
}

.item.valid {
  color: #6a6e81;
}

.item.valid .tick {
  background: url("./assets/tick-green.svg") 50% 50% no-repeat;
}

.item.invalid {
  color: #bfc1cc;
}

.item.invalid .tick {
  border: 2px solid #e0e6f0;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes collapseIn {
  0% {
    overflow-y: hidden;
    height: 0;
    opacity: 0;
    display: none;
    margin-bottom: 0;
  }
  10% {
    display: inherit;
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  99% {
    overflow-y: hidden;
  }
  100% {
    margin-bottom: 20px;
    display: inherit;
    overflow-y: visible;
    height: var(--maxCurtainHeight);
  }
}

@keyframes collapseOut {
  0% {
    margin-bottom: 20px;
    height: var(--maxCurtainHeight);
    overflow-y: visible;
    opacity: 1;
    display: inherit;
  }
  1% {
    overflow-y: hidden;
  }
  80% {
    opacity: 1;
  }
  90% {
    opacity: 0;
    display: inherit;
  }
  100% {
    margin-bottom: 0;
    overflow-y: hidden;
    height: 0;
    display: none;
  }
}

@keyframes flowOut {
  from {
    display: block;
  }
  to {
    display: none;
  }
}
