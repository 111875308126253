.component {
  height: 100%;
  overflow-y: auto;
}

.component table {
}

.header {
  position: sticky;
  top: 0;
  z-index: 100;
}

.body {
}

.name a {
  font-weight: 500;
}

.name a:hover {
  text-decoration: underline !important;
}

.failed {
}

.reply {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 450px;
}
