.list {
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
}
.trigger {
  display: flex;
  align-items: center;
}
.list img {
  max-width: 24px;
  max-height: 24px;
  width: 24px;
  height: 24px;
  margin: 0 0.25rem 0 0;
}
.label {
  margin-left: 7px;
}

.popup:global(.ui.top.left.popup) {
  top: 0 !important;
  left: -10px !important;
}
