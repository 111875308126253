.tooltip {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.title {
  white-space: nowrap;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 7px;
}

.content {
  font-size: 14px;
}

.commonButtonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 13px;
  padding-top: 24px;
}

.buttonsContainer {
  justify-content: space-between;
}

.buttonContainer {
  justify-content: flex-end;
}

.skip {
  text-decoration: underline;
  cursor: pointer;
}

.lastButton {
  min-height: 28px !important;
  padding: 6px 14px !important;
}
.lastButton:global(.ui.button:not(.icon)):hover {
  box-shadow: none !important;
}
