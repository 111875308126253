.warningIcon {
  margin-left: 7px;
  margin-top: 4px;
}
.channel {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  flex: 1 0 auto;
  margin-right: 21px;
}
.channel .icon {
  margin-top: 4px;
  height: 17px;
  width: 17px;
}
.channel .name {
  margin-left: 7px;
  margin-bottom: 7px;
  padding-bottom: 7px;
  cursor: pointer;
}
.selected {
  border-bottom: 2px solid var(--PRIMARY-BLUE);
  color: var(--PRIMARY-BLUE);
}
