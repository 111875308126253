.conditionContainer {
  display: flex;
  flex-direction: column;
}

.conditionContainer div:not(:last-child) {
  margin-bottom: 20px;
}

.conditionContainer :global(.ui.checkbox) {
  margin-right: 7px;
}
