.root {
}

.payment {
  border-radius: 8px;
  padding: 13px 22px 14px;
  background-color: #fff;
  margin-bottom: 15px;
  overflow: hidden;
}

.head {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.summary {
  color: #626779;
  font-size: 12px;
  margin: 0 0 6px;
}

.link {
  font-size: 12px;
}

.link a,
.link a:visited,
.link a:link {
  color: var(--GRAY-MID);
  text-decoration: none;
}

.link a:hover {
  border-bottom: 1px solid #eaeaea;
}

.text {
}

.status {
}
