.connectFacebookCatalog .description,
.connectFacebookCatalog .guide {
  margin-bottom: 24px;
}

.connectFacebookCatalog .tipWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  border-left: 4px solid var(--PRIMARY-BLUE);
  background-color: var(--BUTTON-BLUE);
  padding: 20px 24px;
}
.connectFacebookCatalog .tipTitleWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.connectFacebookCatalog .tipIcon {
  --width: 20px;
  --height: 24px;
  --image: url(../../../assets/images/tips.svg);
  margin-right: 8px;
}
.connectFacebookCatalog .tipTitle {
  font-weight: 500;
}
