.root {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.pic {
  margin: 0 0 16px;
}

.pic img {
  user-select: none;
}

.head {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 8px;
}

.details div {
  margin: 0 0 4px;
}

.actions {
  margin: 27px 0 0;
}
