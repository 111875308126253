.container {
  padding: 30px;
  margin: 80px 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.imageWrapper {
  width: 94px;
  height: 94px;
  border-radius: 50%;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  margin-bottom: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.imageWrapper::after {
  content: url(../../assets/async.svg);
  width: 60px;
  height: 60px;
  position: absolute;
  right: -15px;
  bottom: -15px;
}

.salesforceImg {
  padding: 4px;
}

.hubspotImg {
  border-radius: 100%;
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 21px;
}

.desc {
  margin-bottom: 24px;
}
