.noTemplates {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.noTemplates .head {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 21px;
}

.help {
  color: var(--GRAY-MID);
  font-size: 14px;
  text-align: center;
  line-height: 17px;
}

.popup {
  --image: url("../assets/popup.svg");
  --width: 17px;
  --height: 19px;
  margin-left: 8px;
}

.starWrap {
  margin: 21px 0;
}
