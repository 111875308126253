.backLink {
  position: absolute;
  top: 0;
  left: 0;
}

.content {
  overflow: hidden;
  margin-top: 53px;
}

.footer {
  position: sticky;
  background: #fff;
  bottom: 0;
  padding: 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
