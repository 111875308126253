.chatHeader :global(.right.menu) {
  font-size: 13px;
  align-items: center;
}

.section {
  flex: 0 0 auto;
  margin-right: 7px;
}

.separator {
  display: inline-block;
  border-right: 1px solid #eaeaea;
  margin: 0 7px;
  /*height: ;*/
}

.divider {
  display: inline-block;
  height: 34px;
  width: 1px;
  margin: 0 7px;
  background-color: var(--DISABLED-BORDER-COLOR);
}

.searchButton {
  width: 28px;
  height: 28px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-color: transparent !important;
  background-color: transparent;
  color: var(--PRIMARY-BLUE);
  padding: 0 !important;
  min-height: 0 !important;
}

.searchButton .iconWrap {
  width: 18px;
  height: 18px;
}

.searchButton svg {
  width: 100%;
  height: 100%;
  transform: translateX(-1px);
}

.searchButton:hover {
  background-color: var(--GRAY-LIGHT) !important;
}

.searchButton:hover:global(.ui.ui) {
  box-shadow: none !important;
}

.filterIconWrap {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 14px;
}

.filterIconWrap svg {
  width: 21px;
  height: 16px;
}

.demoCover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
}
