.upgradeButton {
  justify-content: center;
}

.overlay {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(240, 242, 245, 0.7);
}

.wrapper {
  width: 700px;
}

.card {
  box-shadow: 0px 0px 10px 3px #ececec;
}

.popup {
  padding: 45px 37px;
}

.title {
  font-size: 28px;
  font-weight: 500;

  text-align: center;
  margin: 0 103px;
}

.title span {
  color: var(--PRIMARY-BLUE);
}

.body {
  margin: 28px 24px 33px;
  display: flex;
}

.leftColumn {
  flex: none;
  width: 210px;
}

.leftColumn img {
  width: 100%;
}

.rightColumn {
  flex: 1;
  padding-left: 28px;
}

.description {
  font-size: 14px;
  font-weight: 400;
}

.list {
  margin-top: 24px;
  font-size: 12px;
}

.list ul {
  list-style: none;
  color: var(--GRAY-MID);
  margin-top: 15px;
  padding-left: 0;
}

.list ul li {
  margin-bottom: 6px;
  font-size: 12px;
}

.checkIcon {
  color: var(--PRIMARY-BLUE);
  margin-right: 8px;
}

.footer > p {
  margin-top: 21px;
  text-align: center;
  color: var(--GRAY-MID);
  font-size: 12px;
}
