.staffImage {
  min-width: 100px;
  min-height: 20px;
  margin: 20px 0px 20px 0;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 0px 6px 0.25rem !important;
}

.staffImage:hover {
  background-color: var(--GRAY-LIGHT);
  border-radius: 5px;
}

.staffImage > label {
  cursor: pointer;
  color: var(--GRAY-DARK);
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
}

.staffImage .staffImgContainer {
  margin-right: 7px !important;
}

.staffImage .staffImgContainer > img {
  width: 20px !important;
  height: 20px !important;
}

.staffImage .staffImgContainer :global(.sb-avatar.sb-avatar--text),
.staffImage
  .staffImgContainer
  :global(.sb-avatar.sb-avatar--text > .sb-avatar__text) {
  width: 20px !important;
  height: 20px !important;
}

.staffImage :global(.sb-avatar.sb-avatar--text .sb-avatar__text > div) {
  font-size: 14px !important;
}
.staffImage i.icon {
  margin-bottom: 3px;
}

.accountContainer > img {
  width: 32px !important;
  height: 32px !important;
}

.accountContainer :global(.sb-avatar.sb-avatar--text),
.accountContainer :global(.sb-avatar.sb-avatar--text > .sb-avatar__text) {
  width: 32px !important;
  height: 32px !important;
}

.staffImgContainer .avatarContainer {
  width: 20px;
  height: 20px;
}

.accountContainer .avatarContainer {
  width: 32px;
  height: 0;
}

.avatar {
  width: 32px !important;
  height: 32px !important;
}
.mainMenu.rounded {
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  padding-bottom: 0px !important;
}
.mainMenu.rounded .userProfile {
  margin: 0px;
}
.mainMenu {
  padding: 24px 0px !important;
  top: 44px !important;
  width: 282px;
}
.mainMenu .userProfile {
  max-width: 100%;
  margin: 0 10px;
}
.mainMenu.rounded > .userProfile > :global(.item) {
  padding: 11.5px 26px 11.5px 26.5px !important;
}
.userProfile > :global(.item) {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 11.5px 16px 11.5px 16.5px !important;
  max-height: 40px !important;
}

.userProfile > :global(.item:hover) {
  color: var(--BLACK) !important;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.05) !important;
}

.userProfile :global(.ui.toggle.checkbox) label {
  min-height: 1rem;
}

.userProfile :global(.ui.toggle.checkbox) label:before {
  width: 35px !important;
  height: 19px !important;
}

.userProfile :global(.ui.toggle.checkbox) label::after {
  width: 15px !important;
  height: 15px !important;
  top: 2px !important;
  left: 18px !important;
}

.userProfile :global(.ui.toggle.checkbox:not(.checked)) label::after {
  left: 2px !important;
}

.userProfile > :global(.item) > a:link,
a:visited,
a:visited:hover {
  margin: 0px !important;
  padding: 0px !important;
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  color: var(--BLACK);
  text-decoration: none;
  white-space: normal;
  background: transparent;
}

.userProfile > :global(.item) label:global(.with-tooltip) {
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.userProfile > :global(.item) label:global(.with-tooltip) > img {
  margin-left: 8px;
  width: 8px;
  height: 8px;
  top: 1px;
}

.title {
  margin-bottom: 17px;
  padding-left: 24px;
}

.accountContainer {
  display: flex;
  margin-bottom: 14px;
  padding-left: 24px;
}

.info {
  display: flex;
  flex-direction: column;
  margin-left: 16px !important;
}

.username {
  padding-bottom: 7px;
}

.role {
  font-size: 12px;
  color: var(--BLACK);
  opacity: 0.8;
}

.hr {
  border-bottom: 1px solid var(--DISABLED-BORDER-COLOR);
  margin: 12px 16px;
}

.questionIcon {
  --image: url("../../assets/images/icons/question-mark-circle.svg");
  --width: 20px;
  --height: 20px;
  margin-right: 14px;
  background-position: center;
}

.logoutIcon {
  --image: url("../../assets/images/icons/logout.svg");
  --width: 20px;
  --height: 20px;
  margin-right: 14px;
  background-position: center;
}

.videoTutorialsIcon {
  --image: url("../../assets/images/icons/video-recorder.svg");
  --width: 20px;
  --height: 20px;
  margin-right: 14px;
  background-position: center;
}
