.grid {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.icon {
  display: inline-block;
  background: url("./assets/cart.svg") 0 0 no-repeat;
  background-size: contain;
  width: 32px;
  height: 32px;
  margin: 0 0 8px;
}

.head {
  font-weight: 500;
  font-size: 16px;
  margin: 0 0 8px;
}

.subhead {
  margin: 0 0 8px;
}
