.container {
  margin-left: -35px;
  margin-right: -35px;
  padding: 0 35px 40px;
}

.header {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 9px;
  line-height: 1.1;
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.6;
  color: var(--GRAY-MID);
  margin-bottom: 22px;
}

.headerRow {
  background-color: var(--GRAY-LIGHT);
  font-weight: 500;
  font-size: 12px;
  color: var(--GRAY-MID);
}

.container table {
  border: none !important;
  border-radius: 0 !important;
  margin-left: -35px !important;
  margin-right: -35px !important;
}

.container table > thead th {
  padding: 14px 11px 12px !important;
  color: var(--GRAY-MID) !important;
  font-weight: 500;
  line-height: 14.32px;
  background-color: var(--GRAY-LIGHT) !important;
  border-bottom: unset !important;
  border-top: unset !important;
}

.container table > thead th:first-child {
  padding-left: 35px !important;
}

.container table > tr td:first-child {
  padding-left: 35px !important;
}

.container table > tr:nth-child(2) td {
  border-top: unset !important;
}

.container th {
  text-transform: uppercase !important;
}

.typeCell {
  display: flex;
  align-items: center;
}

.typeCell .label {
  margin: 0 0 0 8px;
}

.typeCell .name {
  font-size: 14px;
  line-height: 1;
}

.typeCell .url {
  color: var(--GRAY-MID);
  font-size: 12px;
}

td.first {
  padding-left: 35px !important;
  min-width: 340px;
}

.actions {
  margin: 21px 0;
  display: flex;
  flex-flow: row nowrap;
  gap: 14px;
  --color: var(--BLACK);
}

.links a,
.links a:visited {
  color: var(--PRIMARY-BLUE);
}

.actions .button svg {
  width: 14px;
  height: 14px;
  margin-right: 7px;
}
