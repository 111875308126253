.container {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start !important;
  width: 40vw;
  max-width: 630px !important;
  overflow-y: auto;
  margin: 0 auto;
}
.title {
  font-weight: 500;
  font-size: 28px;
}
.wrapper {
  width: 100%;
  overflow: auto;
}
.bookConsultation {
  margin-top: 20px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  background: #fff;
  padding: 22px 40px 40px;
  width: 100%;
}
.bookConsultation :global(.header) {
  font-size: 20px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bookConsultation :global(.header span) {
  padding-left: 9px;
}
.bookConsultation :global(.content) {
  padding-top: 21px;
}
.bookConsultation :global(.content ul) {
  margin: 18px 0px 28px;
  list-style-type: none;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.bookConsultation :global(.content ul li) {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  color: var(--GRAY-MID);
  padding-top: 10px;
}
.bookConsultation :global(.content ul li svg) {
  margin-right: 9px;
}

.bookConsultation :global(.ui.button) {
  padding: 8px !important;
  width: 100%;
  justify-content: center;
}
.connectOnMyOwn {
  margin-top: 20px;
  border-left: 8px solid #6078ff;
  background: rgba(96, 120, 255, 0.1);
  padding: 22px 49px 21px 30px;
  width: 100%;
}
.connectOnMyOwn :global(.header) {
  font-weight: 500;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.connectOnMyOwn :global(.header span) {
  padding-left: 9px;
}
.connectOnMyOwn :global(.content) {
  padding: 14px 0px 21px;
}
.haveTwilioAccount {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  width: 100%;
}
.haveTwilioAccount :global(.text) {
  font-weight: 500;
  font-size: 14px;
}
.link {
  font-weight: 500;
  color: var(--PRIMARY-BLUE);
  font-size: 14px;
}
.link:hover {
  color: var(--LINK-HOVER);
  cursor: pointer;
  text-decoration: underline;
}
