.root:global(.dropdown.ui.ui.ui) {
  border: 1px solid #eaeaea !important;
}

.root:global(.dropdown.ui.ui.ui .text) {
  line-height: 17px;
  font-weight: 400;
}

.long {
  min-width: 10em !important;
}

.short {
  min-width: 7em !important;
}
