.modal:global(.ui.ui) {
  --pad: 62px;
  display: grid !important;
  grid-template-rows: min-content minmax(0, auto) min-content;
  height: 80vh;
}

@media only screen and (min-height: 1000px) {
  .modal:global(.ui.ui.ui) {
    height: 60vh !important;
  }
}

.header {
  position: relative;
  background-color: var(--DISABLED-BORDER-COLOR);
  padding: 35px var(--pad) 17px;
}

.close {
  position: absolute;
  right: 70px;
  top: 30px;
  display: inline-block;
  --iconColor: var(--DISABLED-FONT-COLOR);
  cursor: pointer;
}

.close svg {
  width: 18px;
  height: 18px;
}

.title {
  font-weight: 500;
  font-size: 16px;
  margin: 0 0 7px;
}

.subtitle {
  color: var(--DISABLED-FONT-COLOR);
}

.body {
  padding: 14px var(--pad) 0;
  overflow: hidden auto;
}

.footer {
  padding: 24px var(--pad) 44px;
}
