.dropdownList {
  padding-top: 35px;
  width: 500px;
}

.dropdownRow {
  padding-top: 16px;
  padding-bottom: 16px;
}
.rowContainer .value {
  padding-left: 11px;
  font-weight: 500;
}

.dropdownRow:last-child {
  padding-bottom: 32px;
}

.rowContainer {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.tick {
  width: 20px;
  height: 20px;
  margin-right: 32px;
}

.label {
  font-size: 16px;
  font-weight: 500;
}

.line {
  border-left: 1px solid var(--DISABLED-BORDER-COLOR);
  height: 65px;
  margin-right: 42px;
}

.dropdownRow :global(.ui.selection.dropdown) {
  border: 1px solid var(--DISABLED-BORDER-COLOR) !important;
  border-radius: 5px !important;
  width: 320px !important;
  height: 40px !important;
}

.rowContainer :global(.phone-number > .phone-number) {
  border: 1px solid #eaeaea !important;
  border-radius: 5px !important;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  width: 320px;
}
.rowContainer :global(.phone-number > .phone-number > .ui.input.fluid) {
  flex: 1 0;
  margin-left: 0;
}
.rowContainer :global(.phone-number > .phone-number > .ui.floating.dropdown) {
  border-right: 1px solid #d8d8d8 !important;
  margin-right: 0;
  flex: 0 0 7em;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  box-shadow: none !important;
}
.rowContainer :global(.phone-number > .phone-number > .ui.input.fluid input) {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  box-shadow: none !important;
}
