.modal {
  width: 712px !important;
  height: 427px !important;
  padding: 30px 40px 32px !important;
}

.modal :global(.close) {
  top: 0 !important;
}

.title {
  padding-bottom: 24px;
  font-weight: 500;
}

.content {
  display: flex;
  justify-content: space-between;
  height: 256px;
}

.selections {
  margin-right: 7px;
  position: relative;
  overflow: auto scroll;
}

.selections :global(.loader) {
  position: relative;
  top: 15px;
}
.detailContainer {
  width: 389px;
  height: 256px;
  margin-left: 7px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  border-radius: 8px;
  overflow: auto scroll;
}

.detailContainer:global(.default) {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--GRAY-MID);
}

.detailContainer:global(.hover) {
  padding: 24px 25px 24px 24px;
}

.detailContainer ul {
  margin-top: 0px;
  padding-top: 10px;
  padding-left: 0px;
  list-style: none !important;
  line-height: 22.4px;
}

.detailContainer li {
  display: flex;
  padding: 4px 0;
}

.detailContainer li :global(img) {
  margin-right: 8px;
}

.validUntil {
  padding: 16px 0 24px;
}

.validUntil :global(.title) {
  padding-bottom: 9px;
  color: var(--GRAY-MID);
}

.tagContainer :global(.title) {
  padding-bottom: 8px;
  color: var(--GRAY-MID);
}

.tagContainer .tags {
  padding-top: 8px;
  display: flex;
  flex-wrap: wrap;
}

.tags :global(.label) {
  flex: 0 0 30%;
  margin: 4px;
}

.subTitle {
  color: var(--GRAY-MID);
  font-size: 12px;
  padding-bottom: 4px;
  border-bottom: 1px solid var(--DISABLED-BORDER-COLOR);
  margin-bottom: 6px;
}

.selections ul {
  margin-top: 0px;
  padding-top: 0;
  padding-left: 0px;
  list-style: none !important;
}
.selections li {
  list-style: none;
  padding: 12px 54px 11px 12px;
  cursor: pointer;
}

.selections li:hover {
  background: var(--GRAY-LIGHT);
  border-radius: 4px;
}

.selections li:global(.hasNumber) {
  padding: 12px 8px 11px 12px;
  display: flex;
  justify-content: space-between;
}

.selections li:global(.active) {
  background: var(--GRAY-LIGHT);
  border-radius: 4px;
}

.tokenNumber {
  color: #5ebada;
  padding: 5px 10px;
  background: #e9f1f8;
  border-radius: 5px;
}

.hintContainer {
  color: var(--FIELD-ERROR-RED);
  padding: 10px 20px;
  display: flex;
  background-color: var(--WARNING-BACKGROUND);
  border-radius: 8px;
  line-height: 19.2px;
  align-items: center;
  margin-top: 16px;
  font-size: 12px;
}

.infoIcon {
  --size: 14px;
  margin-right: 12px;
  flex: 0 0 auto;
  --color: var(--FIELD-ERROR-RED);
}
