.controls {
  margin-top: 4px;
  margin-bottom: -3px;
  margin-left: auto;
}

.controls .hidden {
  visibility: hidden;
}

.paneContainer:global(.ui) {
  display: grid !important;
  grid-template-rows: minmax(0, auto);
}

.paddedContent {
  padding: 0 30px !important;
}
