.root {
  width: 1220px;
}

@media all and (max-width: 1575px) {
  .root {
    width: 920px;
  }
}
@media all and (max-width: 1200px) {
  .root {
    width: auto;
  }
}

.header {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-flow: row nowrap;
  margin: 0 0 5px;
}

.header .text {
  font-size: 16px;
  font-weight: 500;
  margin-right: 20px;
}

.actions {
  display: flex;
}

.actions .icon {
  margin-right: 7px;
  display: block;
  line-height: 1;
  align-self: center;
  --color: var(--PRIMARY-BLUE);
}

.actions .refresh {
  display: inline-flex;
  color: var(--PRIMARY-BLUE);
}

.actions .refresh.disabled {
  pointer-events: none;
  color: var(--DISABLED-FONT-COLOR);
}
