.dummy .image {
  height: 145px;
  border-radius: 5px;
  background: var(--GRAY-LIGHT);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dummy .link {
  color: var(--PRIMARY-BLUE);
  cursor: pointer;
}

.dummy .content {
  padding-top: 16px;
  font-weight: 500;
}
