.field.checkbox label.checkbox {
  margin-bottom: 0;
}

.field.checkbox > label {
  margin-bottom: 10px !important;
}

.field.checkbox .hint {
  margin-top: -7px;
  margin-bottom: 11px;
}

.field {
  margin: 0 var(--aside) 28px 0 !important;
}

.field.fullWidth {
  margin-right: 0 !important;
}

.field > label {
  margin-bottom: 4px !important;
}

.hint {
  color: var(--DISABLED-FONT-COLOR);
  font-size: 12px;
  margin-top: 4px;
}
