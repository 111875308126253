.controls {
  position: absolute;
  left: -32px;
  top: 6px;
  flex-direction: column;
  height: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.sortControls.raised {
  top: 2px;
}
.sortControls.waitEnabled {
  top: 6px;
}

.sort {
  cursor: pointer;
  font-size: 0;
  line-height: 0;
  width: 16px;
  height: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.sort.disabled {
  opacity: 0.4;
  pointer-events: none;
  cursor: default;
}

.sort:before {
  display: inline-block;
  width: 10px;
  height: 6px;
  position: relative;
}

.up:before {
  top: -1px;
  content: url("../../../../assets/images/ui/sort-up.svg");
}

.up:hover:before {
  content: url("../../../../assets/images/ui/sort-up-hover.svg");
}

.down:before {
  top: 1px;
  content: url("../../../../assets/images/ui/sort-down.svg");
}

.down:hover:before {
  content: url("../../../../assets/images/ui/sort-down-hover.svg");
}
