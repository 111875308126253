.targetTotalWrapper {
  border-top: 1px solid var(--DISABLED-BORDER-COLOR);
  padding-top: 21px;
}

.totalTag {
  background-color: #d3f2cd;
  color: #2c6047;
  margin-left: 0;
  margin-right: 24px;
}

.viewTargetListLink {
  color: var(--PRIMARY-BLUE);
  display: inline-block;
  cursor: pointer;
}

.viewTargetListLink:hover {
  text-decoration: underline;
}
