.body {
}
.container {
  white-space: pre-wrap;
  padding: 30px 20px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  background: rgba(236, 236, 236, 0.3);
  word-break: break-word;
  overflow-y: auto;
  position: relative;
}
.emoji {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 10px;
}
.container :global(.link) {
  color: #6078ff;
}
.buttons {
  padding: 14px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.buttons .button {
  margin-right: 14px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  padding: 9px 14px;
  text-align: center;
  border-radius: 5px;
  flex: 1;
  max-width: max-content;
}
.button a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.header {
  font-weight: 600;
  color: var(--ORIGINAL-BLACK);
  padding-bottom: 14px;
}
.headerFile :global(.field-error) {
  margin-top: 7px !important;
}
.footer {
  padding-top: 14px;
  font-size: 12px;
  color: var(--GRAY-MID);
}
.templateContainer {
  display: flex;
  flex-direction: column;
}
.note {
  padding-top: 14px;
  color: var(--GRAY-MID);
}
.label {
  padding-top: 28px;
  font-weight: 500;
  padding-bottom: 14px;
}
.dropdown {
  text-transform: capitalize;
}
