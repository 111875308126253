.inputContainer {
  position: relative;
}

.inputContainer input {
  padding-left: 56px !important;
}

.dollarSign {
  position: absolute;
  border-right: 1px solid var(--GRAY-UI);
  height: 100%;
  top: 0;
  padding: 8px 16px;
}
