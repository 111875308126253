.assign {
  --image: url("./assets/flow/assign.svg");
  --width: 41px;
  --height: 40px;
}

.cart {
  --image: url("./assets/flow/cart.svg");
  --width: 40px;
  --height: 40px;
}

.contacts {
  --image: url("./assets/flow/contacts.svg");
  --width: 40px;
  --height: 40px;
}

.contactUpdate {
  --image: url("./assets/flow/contactUpdate.svg");
  --width: 40px;
  --height: 40px;
}

.message {
  --image: url("./assets/flow/message.svg");
  --width: 40px;
  --height: 40px;
  transform: translateY(-1px);
}

.orderConfirmed {
  --image: url("./assets/flow/orderConfirmed.svg");
  --width: 40px;
  --height: 40px;
}

.orderUpdated {
  --image: url("./assets/flow/orderUpdated.svg");
  --width: 40px;
  --height: 40px;
}

.store {
  --image: url("./assets/flow/store.svg");
  --width: 40px;
  --height: 40px;
}
