.content {
  flex-direction: column;
}

.plans {
  margin: 12px 0;
  display: flex;
  margin-left: -40px;
}

.plan {
  flex: 1;
  margin-right: 24px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  padding: 40px 40px 16px;
}

.plan.lastOne {
  margin-right: 0;
}

.head {
  margin-bottom: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.head .title {
  font-size: 16px;
  font-weight: 500;
}

.head .free {
  background-color: #d3f2cd;
  color: #2c6047;
}

.head .recommend {
  background-color: #dceafd;
  color: #6b73bb;
}

.body {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.channel {
  width: 120px;
  height: 140px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 24px;
  padding: 28px 12px 12px;
}

.channel .icon {
  width: 40px;
  margin-bottom: 8px;
}

.name {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.easyTag {
  color: var(--GRAY-MID);
  min-height: 22px;
  font-size: 12px;
}

.connected {
  color: var(--SUCCESS-GREEN);
  font-size: 12px;
}

.connected .checkIcon {
  --image: url("../../../assets/images/tick_path.svg");
  --width: 10px;
  --height: 10px;
  margin-right: 8px;
}

.connectBtn {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #e7eaffd0;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;
}

.channel:hover .connectBtn {
  display: flex;
}

.tabs {
  width: 100%;
}

.tabs :global(.ui.menu.pointing.secondary) {
  display: flex;
  border-bottom: 1px solid var(--DISABLED-BORDER-COLOR);
  width: 100%;
}

.tabs .tab:global(.item) {
  flex: 1;
  display: flex;
  flex-direction: column;
  color: var(--BLACK);
  font-weight: 500;
}

.tabs .tab .icon {
  margin-bottom: 9px;
  width: 40px;
}

:global(.ui.segment > div).tabContent {
  font-size: 14px;
  padding: 8px 0 4px;
}

:global(.ui.segment > div).tabContent .list {
  list-style-image: url("../../../assets/images/check.svg");
}

:global(.ui.segment > div).tabContent .list li {
  padding-left: 10px;
  line-height: 120%;
  margin-bottom: 16px;
}

.buttons {
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 8px;
}

.buttons .pricingBtn:global(.ui.button) {
  --bg-color: #e7eaff;
  --text-color: var(--PRIMARY-BLUE);
  border: none;
  padding: 12px 32px;
}
