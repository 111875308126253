.container {
  display: flex;
  flex-direction: column;
  background: var(--WHITE);
  padding: 30px;
  border-radius: 8px;
  justify-content: center;
  max-width: 612px;
  width: 100%;
  text-align: left;
}

.container :global(.dimmer) {
  z-index: 1;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 12px;
}

.header :global(.image) {
  width: 54px;
  height: 54px;
}

.header :global(.text) {
  padding-left: 21px;
}

.subHeader {
  font-weight: 500;
  font-size: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.steps {
  padding-top: 12px;
  counter-reset: list-number;
}

.step:before {
  counter-increment: list-number;
  content: counter(list-number);
  margin-right: 8px;
  margin-bottom: 8px;
  width: 22px;
  height: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background-color: var(--FOCUS-INPUT-BORDER-BLUE);
  color: var(--WHITE);
  border-radius: 4px;
}

.loginButton {
  color: var(--PRIMARY-BLUE);
  cursor: pointer;
  font-weight: bold;
}

.screenshot {
  margin-bottom: 24px;
  margin-left: 54px;
  width: 209px;
  height: 276px;
  background-image: url("./migrate_number_close_popup.png");
}

.alert :global(.tipHeader) {
  display: flex;
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 8px;
}

.alert :global(.tipHeader) svg {
  margin-right: 7px;
}

.alert ul {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  padding-inline-start: 28px !important;
}
.alert ul li:first-child {
  padding-bottom: 8px;
}

.newButton:global(.ui.button) {
  margin-top: 24px !important;
  margin-bottom: 8px !important;
  padding-top: 11.5px !important;
  padding-bottom: 11.5px !important;
}

.existingButton {
  color: var(--PRIMARY-BLUE);
  padding: 11.5px 0;
  text-align: center;
  cursor: pointer;
}

.fbLoginLoader {
  display: block !important;
  position: relative !important;
  margin-top: 50px !important;
}

.checkboxWrapper {
  margin-top: 24px;
  padding: 22px 45px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
}
