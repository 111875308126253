.sidebar {
  --paddingX: 20px;
  --paddingY: 20px;
  grid-area: sidebar-right;
  overflow: hidden auto;
  box-shadow: none;
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px 0;
}

.widget {
  margin: 0 20px 6px;
}

.separator {
  border-bottom: 1px solid var(--DISABLED-BORDER-COLOR);
  margin: 0 var(--paddingX) 12px;
}

.widget:empty,
.widget:empty + .separator {
  display: none;
}

.demoCover {
  pointer-events: none;
}

.searchActive.widget {
  display: none;
}
