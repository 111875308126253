.addOnCardWrapper {
  width: 100% !important;
  box-shadow: none !important;
  border: 1px solid var(--DISABLED-BORDER-COLOR) !important;
}

.addOnCardTitleLabel {
  font-weight: 500;
  font-size: 20px;
  padding-right: 12px;
}

.addOnCardPriceTitle {
  display: grid;
  grid-template-columns: 1fr fit-content(400px);
  grid-gap: 30px;
}

.addOnCardRightSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.consultUsRightSection {
  align-self: center;
}

.addOnCardPrice {
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 20px;
}

.pricePeriodLabel {
  font-size: 14px;
  color: var(--DISABLED-FONT-COLOR);
  font-weight: 400;
}

.addOnCardDescriptionLabel {
  color: var(--DISABLED-FONT-COLOR);
  font-size: 14px;
  margin-bottom: 24.5px;
}
.addedTick {
  --color: var(--SUCCESS-GREEN);
  margin-top: 2px;
  margin-right: 3px;
}

.addedLabel {
  color: var(--SUCCESS-GREEN);
  margin-right: 10px;
}

.periodEnd {
  padding-left: 5px;
}

.periodEndNoPadding {
  color: var(--SUCCESS-GREEN);
}

.periodEndNoPadding span {
  font-weight: 500;
}

.periodEnd span {
  font-weight: 700;
}

.addedLabelWrapper {
  display: flex;
  align-items: flex-start;
}

.addOnButtonDisabled {
  --bg-color-disabled: #d8d8d8;
  --text-color-disabled: var(--DISABLED-FONT-COLOR);
}

.cardHeader {
  display: flex;
  align-items: center;
  padding-bottom: 12px;
}

.freeTrialTag {
  background-color: #d3f2cd !important;
  color: #2c6047 !important;
  font-size: 12px !important;
  padding: 5px 10px;
  font-weight: 500;
}
