.supportPlanContainer {
  display: grid;
  grid-template-columns: 300px 1fr;
  padding: 32px 30px;
}

.supportSectionWrapper {
  width: 100%;
}

.supportCardWrapper {
  width: 100% !important;
  box-shadow: none !important;
  border: 1px solid var(--DISABLED-BORDER-COLOR) !important;
}

.supportCardPriceTitle {
  display: grid;
  grid-template-columns: 1fr fit-content(400px);
  grid-gap: 30px;
}

.addedTick {
  --color: var(--SUCCESS-GREEN);
  margin-top: 2px;
  margin-right: 3px;
}

.supportTitleLabel {
  font-weight: 500;
  font-size: 20px;
}

.addedLabel {
  color: var(--SUCCESS-GREEN);
  font-weight: 500;
}

.supportCardDescriptionLabel {
  color: var(--DISABLED-FONT-COLOR);
  font-size: 14px;
  margin-bottom: 24.5px;
  margin-top: 16px;
}

.addedTick {
  --color: var(--SUCCESS-GREEN);
  margin-top: 2px;
  margin-right: 3px;
}

.supportLabelWrapper {
  display: flex;
  align-items: flex-start;
}

.supportCardRightSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.supportCardPrice {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 20px;
}

.pricePeriodLabel {
  font-size: 14px;
  color: var(--DISABLED-FONT-COLOR);
  font-weight: 400;
}

.listItemWrapper {
  display: grid;
  margin-bottom: 8px;
  grid-template-columns: 27px 1fr;
}

.tickIcon {
  margin-top: 3px;
}

.listItemTitle {
  font-weight: 500;
  margin-bottom: 5px;
}

.addedLabelWrapper {
  display: flex;
  align-items: flex-start;
}

.addedTick {
  --color: var(--SUCCESS-GREEN);
  margin-top: 2px;
  margin-right: 10px;
}

.billedYearly {
  margin-top: 12px;
  color: var(--DISABLED-FONT-COLOR);
}
