.container {
  display: flex;
  flex-direction: column;
  padding-top: 38px;
  width: 500px;
}

.titleContainer {
  padding-top: 18px;
  padding-bottom: 15px;
  display: flex;
  text-align: left;
}

.titleContainer img {
  width: 20px;
  height: 20px;
  margin-right: 24px;
}

.titleContainer label {
  font-weight: 500;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-left: 1px solid var(--DISABLED-BORDER-COLOR);
  padding-left: 34px;
  margin-left: 10px;
}

.radioGroup :global(.ui.button .active) {
  border-color: var(--PRIMARY-BLUE);
}
.radioGroup :global(.ui.button:hover) {
  border-color: var(--PRIMARY-BLUE) !important;
}

.radioGroup :global(.radio) {
  display: none;
}

.languageContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 30px;
  padding-bottom: 24px;
}

.languageContainer :global(.ui.selection.dropdown) {
  margin-top: 16px;
  border: 1px solid var(--DISABLED-BORDER-COLOR) !important;
  border-radius: 5px !important;
  width: 320px;
  height: 40px;
}

.getCode {
  padding: 8px 30px !important;
}

.invalidTime {
  font-size: 12px;
  margin-top: 8px;
  color: var(--GRAY-MID);
}

.verify {
  padding: 12px 210px 13px 238px !important;
}

.digitInputContainer {
  display: flex;
  align-items: center;
  border-left: 1px solid var(--DISABLED-BORDER-COLOR);
  height: 107px;
  padding-left: 34px;
  margin-bottom: 32px;
}

.errorMsgContainer {
  display: flex;
  align-items: center;
  margin-bottom: 34px;
}

.errorIcon {
  width: 20px;
  height: 20px;
  margin-right: 24px;
}
