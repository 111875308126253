.weeklyBalance {
  padding: 24px;
  border: 1px solid var(--GRAY-UI);
  border-radius: 8px;
  max-width: fit-content;
  margin: 16px 0;
}

.label {
  font-size: 12px;
  color: var(--GRAY-MID);
  margin-bottom: 8px !important;
}

.balance {
  font-size: 16px;
  font-weight: 500;
  margin: 0 !important;
}
