.container {
  width: 1000px;
  margin: 0 auto;
  padding: 0 0 0 20px;
  overflow-y: auto;
}

.backButton {
  float: left;
}

.contentWrapper {
  width: 720px;
  flex: none;
  margin: 0 auto;
  margin-bottom: 24px;
}

.content {
  padding: 40px;
}

.content a,
.content a:hover {
  color: var(--PRIMARY-BLUE);
}

.grantPermission .description,
.grantPermission .list {
  margin-bottom: 24px;
}

.connectWhatsappNumber .noCatalog {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--GRAY-MID);
}

.connectWhatsappNumber .description {
  margin-bottom: 24px;
}

.connectWhatsappNumber .catalogsWrapper {
  margin-bottom: 24px;
}
