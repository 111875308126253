.password :global(.input) {
  position: relative;
}

.password .iconWrap {
  cursor: pointer;
  position: absolute;
  top: 1px;
  right: 8px;
  display: flex;
  align-items: center;
  height: calc(100% - 2px);
  background-color: var(--WHITE);
  padding: 0 6px;
}

.password .icon {
  font-size: 16px !important;
}

.password .icon:hover {
  color: var(--PRIMARY-BLUE);
}

.password .iconWrap.active {
  color: var(--BLACK);
}
