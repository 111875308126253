.container {
  margin-bottom: 32px;
}

.title {
  color: var(--GRAY-MID);
  margin-bottom: 16px;
}

.optionContainer {
  border-radius: 10px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 23px 28px;
  width: 544px;
  height: 94px;
}

.salesforceOption {
  margin-bottom: 16px;
}

.optionContainer .radio {
  margin-right: 16px;
}

.optionContainer img {
  width: 48px;
  height: 48px;
  margin-right: 16px;
}

.optionContainer span {
  font-weight: 500;
}

.hubspotLogo {
  transform: scaleX(-1);
}
