.dropdown:global(.ui.ui.ui) {
  display: flex;
  height: 34px;
  flex-flow: row nowrap;
  align-items: center;
  border: 1px solid #eaeaea !important;
  padding: 0 8px;
  background-color: #fff;
}

.dropdown:hover,
.dropdown:global(.active.ui.ui.ui) {
  background-color: #f6f5f5;
  border: 1px solid #f6f5f5 !important;
}

.dropdown :global(.dropdown.icon) {
  inset: 0 !important;
  position: relative !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px !important;
  margin-right: 0 !important;
}

.pic {
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.pic img,
.pic :global(.icon.ui.ui.ui.ui.ui.ui) {
  margin: 0 !important;
}

.text {
  margin-left: 7px;
  padding-left: 0 !important;
}

.shrinkOnTablet {
}

.shrinkOnNarrowTablet {
}

.header {
  color: #9aa8bd;
  font-size: 13px;
  font-weight: 500;
  height: 28px;
  padding: 0 11px;
  display: flex;
  align-items: center;
}

.noText {
  min-width: max-content !important;
}

.noText .text {
  display: none;
}

.noText + :global(.dropdown.icon) {
  /*margin-left: 0!important;*/
}

.noText :global(.icon),
.noText :global(.icon-item img) {
  margin-right: 0 !important;
}
