.modal {
  text-align: center !important;
  max-width: 517px;
  padding: 20px 30px;
}

.header {
  display: flex;
  justify-content: center;
  font-size: 20px;
  color: var(--FIELD-ERROR-RED);
  margin-bottom: 21px;
}

.header img {
  margin-right: 7px;
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 21px;
}

.actions button {
  padding: 8px 30px !important;
}
.actions button:first-child {
  margin-right: 14px;
}
