.shareProducts {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.arrow {
  color: var(--color);
  font-size: 48px;
  cursor: pointer;
}

.prev::before {
  content: "\2039";
  --color: var(--PRIMARY-BLUE);
}

.next::after {
  content: "\203A";
  --color: var(--PRIMARY-BLUE);
}

.disabled {
  --color: var(--DISABLED-BORDER-COLOR);
}

.product {
  border-radius: 14px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  padding: 10px;
  margin: 0 33px;
  height: calc(600px - 230px);
  overflow: auto;
  flex: 1 1 auto;
}

.product .image {
  height: 211px;
  object-fit: cover;
  margin: 0 auto;
}

.product .title {
  padding-top: 11px;
}

.product .amount {
  padding: 8px 0px;
  color: var(--GRAY-MID);
}

.text {
  max-width: 250px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.link {
  color: var(--PRIMARY-BLUE);
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
  color: var(--LINK-HOVER);
}
