.table th,
.table td {
  --padSide: 32px;
}

.actions.cell {
  --iconWidth: 4px;
  --iconHeight: 12px;
}

.image {
  display: inline-flex;
  width: 56px;
  height: 56px;
  --imgIconWidth: 56px;
  --imgIconHeight: 56px;
}

.image img {
  border-radius: 8px;
}

.image.empty {
  background: var(--GRAY-LIGHT) url(./assets/image-stub.svg) 50% 50% no-repeat;
}

.name {
  display: flex;
  align-items: center;
  gap: 18px;
}
