.tools {
  grid-row: 3;
  grid-column: 1 / 1;
  flex: 1 0 auto;
  height: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 8px;
}

.tools :global(.icon) {
  opacity: 1;
}

.tools.note .send {
  --text-color: #fff;
  --text-color-disabled: #fff9ed;
  --bg-color: #fcab6f;
  --border-color: #fcab6f;
  --border-hover-color: #e59961;
  --bg-hover-color: #e59961;
  --bg-color-disabled: #fdd7b3;
  --shadow-hover-color: transparent;
}

.tools.sendOnly {
  justify-content: flex-end;
}
.tools :global(.attachment-trigger.icon) {
  font-size: 14px;
}

.channelSelector {
  display: inline-flex;
  margin-left: auto;
  align-items: center;
  margin-right: 14px;
}

.channelSelector .label {
  display: inline-flex;
  align-items: center;
  margin-right: 14px;
  color: var(--GRAY-MID);
}
