.root {
  display: grid;
  grid-template-columns: 1fr 480px 1fr;
  grid-template-rows: min-content auto;
  grid-template-areas: "nav nav nav" ". body .";
  height: 100%;
  padding: 28px 0 0;
}

.nav {
  grid-area: nav;
  margin-left: 38px;
}

.back {
  display: flex;
  align-items: center;
  color: var(--PRIMARY-BLUE);
  font-weight: 500;
}

.back .icon {
  margin-right: 11px;
}

.body {
  grid-area: body;
  justify-self: center;
  text-align: center;
}

.pic {
  margin: 52px 0 12px;
}

.head {
  margin: 0 0 10px;
  font-size: 20px;
  font-weight: 500;
}

.total {
  margin: 0 0 24px;
}

.link {
  margin: 0 0 12px;
}

.link .field {
  margin-bottom: 10px;
}

.link .field :global(.text-minor) {
  margin: -12px 0 5px;
  font-size: 12px;
  height: 17px;
  display: inline-block;
  color: var(--GRAY-MID);
}

.link .field :global(.ui.input) {
  margin-bottom: 0;
}

.link .hint {
  margin: 0 0 24px;
}

.actions :global(.button) {
  margin: 0 0 27px;
}

.preview {
  text-align: center;
  display: flex;
  justify-content: center;
}

.previewLink {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--PRIMARY-BLUE);
  cursor: pointer;
}

.preview .icon {
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
}

.preview .text {
}
