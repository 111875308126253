.item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 7px 25px;
  cursor: pointer;
}

.item:hover,
.item.selected {
  background-color: var(--GRAY-LIGHT);
}

.avatar {
  flex: 0 0 40px;
  margin-right: 14px;
}

.avatarWrap {
  width: 40px;
  height: 40px;
  position: relative;
}

.avatarWrap img {
  object-fit: cover;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
}

.avatarWrap :global(.icon-image) {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.details {
  flex: 1 1 auto;
  overflow-x: hidden;
}

.details .header {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 4px;
}

.details .name {
  flex: 1 0 auto;
  font-weight: 500;
}

.details .date {
  font-size: 12px;
  color: var(--GRAY-MID);
  margin-left: auto;
  margin-top: 3px;
}

.details .text {
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.text .highlight {
  color: #4d67f9;
  font-weight: 500;
}
