.messageContentTransform {
  text-decoration: none;
}
.header {
  font-weight: 500;
}
.header:global(.image) {
  flex-direction: column;
  justify-content: center;
  margin-bottom: 12px;
  min-height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.header :global(.dimmable > .image) {
  max-height: 336px;
  min-height: 336px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 12px;
}
.header :global(.dimmable > .image > img) {
  width: 100%;
  display: block;
  min-height: 336px;
  object-fit: cover;
}
.header:global(.image) img {
  width: 100%;
  min-height: 336px;
  object-fit: cover;
}
.footer {
  margin-top: 10px;
  font-size: 12px;
  color: var(--GRAY-MID);
}
.messageContentTransform a {
  color: var(--PRIMARY-BLUE) !important;
}
