.button {
  padding: 0px !important;
  min-height: auto !important;
}

.store {
  --color: var(--GRAY-MID);
}

.store:hover {
  --color: var(--PRIMARY-BLUE);
}

.dropdown:global(.ui.dropdown) {
  border: 1px solid var(--DISABLED-BORDER-COLOR) !important;
  border-radius: 3px;
  z-index: 999;
  background-color: var(--WHITE);
}

.dropdown :global(.menu) {
  margin-top: 0px !important;
  border-radius: 3px !important;
}

.dropdown :global(.header) {
  color: var(--GRAY-MID) !important;
  font-size: 13px !important;
  margin: 0px !important;
  padding: 15px 15px 5px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  position: sticky;
  top: 0px;
  z-index: 1000;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 88%,
    rgba(255, 255, 255, 0)
  );
}

.popup {
  border: 0px solid transparent !important;
  padding: 0px !important;
}

.shopifyItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.shopifyItem .name {
  color: var(--BLACK);
  padding-left: 14px;
}
