.border {
  box-shadow: inset 0 0 0 5px var(--PRIMARY-BLUE);
  margin-top: -20px;
  position: relative;
}

.tagWrapper {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 1px;
}

.tag {
  font-weight: 500;
  background-color: var(--PRIMARY-BLUE);
}
