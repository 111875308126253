.container {
  border: 1px solid var(--DISABLED-BORDER-COLOR);
}

.header {
  border-bottom: 1px solid var(--DISABLED-BORDER-COLOR);
  padding: 20px 30px;
  font-size: 16px;
  font-weight: 500;
}

.body {
  padding: 16px 30px;
}

.timeRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.timeLabel {
  color: var(--GRAY-MID);
  font-size: 12px;
}

.timeValue {
  font-size: 12px;
}

.totalRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 600;
}

.itemRow {
  margin-bottom: 8px;
  display: flex;
}

.imageWrapper {
  flex: none;
  height: 50px;
  position: relative;
}

.image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}

.quantity {
  position: absolute;
  right: -5px;
  bottom: -5px;
  min-width: 20px;
  min-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 50%;
  background-color: #636879;
  color: var(--WHITE);
}

.detail {
  margin-left: 12px;
  font-size: 12px;
}

.itemName {
  margin-bottom: 4px;
}

.spec {
  color: var(--GRAY-MID);
  margin-bottom: 4px;
}

.price {
}
