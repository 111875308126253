.status {
  position: relative;
  margin-left: 17px;
  display: block;
  color: var(--status-color);
}

.status:before {
  content: "";
  display: block;
  width: 11px;
  height: 11px;
  border-radius: 11px;
  position: absolute;
  left: -18px;
  top: 6px;
  background-color: var(--status-color);
}

.scheduled {
  --status-color: #494cad;
}

.queued,
.draft {
  --status-color: #9e9e9e;
}

.sent {
  --status-color: #18c963;
}

.processing,
.sending {
  --status-color: #f6ca00;
}

.paused {
  --status-color: #ae325f;
}

.error {
  --status-color: var(--FIELD-ERROR-RED);
}

.error:before {
  background-image: url("../../assets/images/icons/warning_circle.svg");
}
