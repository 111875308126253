.boxWrapper {
  border-radius: 10px;
  padding: 21px;
  margin-top: 21px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
}

.boxShadow {
  box-shadow: 0px 2px 4px var(--GRAY-SHADOW),
    inset 0px 3px 0px var(--PRIMARY-BLUE);
}

.children {
  display: flex;
}

.logo {
  width: 80px;
  height: 80px;
  margin-right: 21px;
}

.content {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 28px;
  font-weight: 500;
}

.title:after {
  content: "👋🏻";
  margin-left: 7px;
}

.subTitle {
  font-size: 16px;
}

.animationContainer {
  flex: 0 0 100%;
  padding: 0 32px 32px;
  transition: transform 500ms cubic-bezier(0.2, 0.68, 0.35, 0.99);
}

.animationContainerMain {
  transform: translateX(0px);
  opacity: 1;
}

.animationContainerNew {
  transform: translateX(-477px);
  opacity: 0;
}
