.main {
  flex-direction: column !important;
  justify-content: flex-start !important;
  overflow-y: auto;
  width: 100vw;
}

.container {
  display: flex;
  flex-direction: row !important;
  align-items: flex-start !important;
  justify-content: center;
  border-radius: 5px;
}

.tick {
  --image: url(../../assets/images/icons/tick-circle.svg);
  --width: 17px;
  --height: 17px;
  margin-right: 7px;
}

.listItem {
  display: list-item;
  list-style-type: disc;
  margin-bottom: 7px;
  margin-left: 21px;
}

.priceTableDiff {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 21px;
  margin-left: 21px;
}

.priceTableDiff .column {
}

.priceTableDiff .row {
  margin: 0 0 11px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  white-space: nowrap;
}

.priceTableDiff .head {
  margin-bottom: 7px;
}

.priceTableDiff .row img {
  margin-right: 7px;
}

.priceTableDiff .region {
}

.priceTableDiff .amount {
  color: #9aa8bd;
}

.linkContainer {
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
  font-weight: 500;
  margin: 11px 0 21px 0;
  text-align: center;
}
.linkContainer.noMargin {
  margin-bottom: 0px !important;
}
.linkContainer:last-child {
  margin-top: 14px;
}

.border {
  border-right: 1px solid var(--DISABLED-BORDER-COLOR);
  height: 16px;
  margin: 0 24px;
}

.approval :global(span) {
  font-weight: bold;
}

.infoIcon {
  --image: url("../../assets/images/info_gray.svg");
  --width: 16px;
  --height: 14px;
  margin-left: 7px;
  cursor: pointer;
  background-position: center;
}

.tooltip {
  min-width: 311px;
}

.tooltip :global(.description) {
  padding-bottom: 10px;
}

.tooltip :global(.description span) {
  font-weight: bold;
}

.tooltip :global(ul) {
  padding-inline-start: 20px !important;
  margin-top: 5px;
}

.messageIcon {
  --image: url("../../assets/images/icons/chat.svg");
  --width: 17px;
  --height: 17px;
  margin-right: 9px;
}

.previewContainer {
  text-align: center;
  margin-top: 20px;
}

.gifContainer :global(img) {
  text-align: center;
  width: 260px;
  height: 553px;
}

.previewTitle {
  font-size: 14px;
  padding-bottom: 20px;
  font-weight: 500;
}

.navContainer {
  margin-bottom: 36px;
}

.messageLimitGrid {
  text-align: left;
  align-items: center;
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  grid-auto-rows: auto;
  margin: 11px 0 12px;
  grid-gap: 4px;
  align-items: flex-start;
}

.userTitle {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  font-weight: 600;
  margin-bottom: 3.5px;
}

.businessTitle {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  font-weight: 600;
  margin-bottom: 3.5px;
}

.userDescription {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  margin-top: 3.5px;
  margin-bottom: 8px;
  padding-right: 32px;
}

.businessDescription {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  margin-top: 3.5px;
  margin-bottom: 8px;
}

.messageLimitHint {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
}

.feeTitle {
  font-size: 14px;
  padding-bottom: 8px;
  padding-top: 16px;
}

.feeWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 16px;
}

.fee {
  font-weight: 500;
  font-size: 20px;
  padding-right: 8px;
}

.perMonth {
  font-weight: 500;
  font-size: 14px;
  color: var(--GRAY-MID);
}

.perMonth span {
  padding-right: 3px;
}

.subHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subHeader img {
  width: 79px;
  height: 42px;
}
