.container {
  display: grid;
  min-width: 1320px;
  overflow-y: auto;
  margin: 0 30px 32px;
  align-content: start;
  padding-top: 30px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
    "header header header header"
    "filters filters filters actions"
    "total total . . "
    "team team team team"
    "staff staff staff staff";
}

.header {
  grid-area: header;
  padding-bottom: 29px;
}

.filters {
  grid-area: filters;
  padding-bottom: 25px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.filters > * {
  margin-right: 14px;
}

.actions {
  grid-area: actions;
  justify-self: end;
}

.totalSales {
  grid-area: total;
  padding-bottom: 32px;
}

.teamSales {
  grid-area: team;
  padding-bottom: 32px;
}

.staffSales {
  grid-area: staff;
}
