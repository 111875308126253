.root {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
}

.minus,
.plus {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  cursor: pointer;
  border-radius: 5px;
  --color: var(--BLACK);
}

.minus:hover,
.plus:hover {
  background-color: #ececec;
}

.minus svg {
  width: 10px;
  height: 2px;
}

.plus svg {
  width: 10px;
  height: 10px;
}

.input input {
  text-align: center !important;
}

.minus.disabled svg,
.plus.disabled svg {
  opacity: 0.4;
}
