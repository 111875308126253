.root {
}

.controls {
  padding-top: 5px;
  display: grid;
  grid-template-areas:
    "controls varsHead"
    "body vars";
  grid-template-columns: 1fr 260px;
  grid-gap: 14px 21px;
}

.controls + :global(.field-error.visible) {
  margin-top: 7px !important;
}

.controlText {
  grid-area: body;
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  flex: 1 0 auto;
}

.controlText label {
  flex: 0 0 auto;
}

.textControls {
  flex: 0 0 auto;
  align-self: flex-start;
  padding: 0 10px;
  height: 40px;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: -40px;
  position: relative;
}

.textContent {
  flex: 1 1 auto;
  padding: 25px 35px 45px;
  height: 100%;
  overflow: auto;
  line-height: 2em;
  word-break: break-word;
  white-space: pre-wrap;
  margin-bottom: 0;
  border-width: 2px;
  resize: none;
}

.textContent:focus {
  box-shadow: none !important;
  border-width: 2px;
}

.variablesHead {
  grid-area: varsHead;
  align-self: center;
}

.variablesHead label {
  padding-bottom: 0 !important;
}

.controlVariables {
  grid-area: vars;
}

.labelSection {
  display: flex;
  align-items: center;
}

.labelSection .item {
  flex: 0 1 auto;
  margin: 0 0 0 30px;
  display: flex;
  align-items: center;
}

.labelSection .item:first-child {
  margin-left: 0;
}

.labelSection .item.long {
  flex: 0 0 390px;
}

.labelSection .item.fluid {
  flex: 1 0 auto;
}

.labelSection .item.floating {
  margin-left: auto;
  flex: 0 1 auto;
}

.labelSection label {
  margin: 0;
  padding-bottom: 0 !important;
}

.labelSection :global(.ui.input.fluid) {
  width: 100%;
}

.root.readonly .controls {
  grid-template-columns: 1fr;
  grid-template-areas:
    "controls"
    "body";
}

.root.readonly .channelsList {
  flex: 1 0 auto;
}
