.subscriptionTableHeaderWrapper {
  table-layout: fixed;
  width: 100%;
}

.subscriptionTableHeaderWrapper thead {
  vertical-align: top;
  text-align: left;
}

.subscriptionTableHeaderPlans {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 23px;
}

.subscriptionTableHeaderPlansWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  min-height: 180px;
  height: 100%;
}

.freePriceLabel {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 23px;
}

.pricePeriodLabel {
  font-size: 14px;
  color: var(--DISABLED-FONT-COLOR);
  font-weight: 400;
}

.switchYearlyButton {
  font: inherit;
  font-weight: 500;
  margin-top: 16px;
  background: none;
  color: var(--PRIMARY-BLUE);
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
}

.savingsInfoLabel {
  text-transform: uppercase;
  margin-top: 16px;
  font-size: 12px;
  color: var(--SUCCESS-GREEN);
}
