.link {
  display: inline-flex;
  align-items: center;
  padding: 0 12px;
  min-height: 31px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  border-radius: 5px;
}

.text {
  flex: 1 1 auto;
  max-width: 12em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icon {
  line-height: 0;
}

.icon a {
  height: auto;
  line-height: 0;
}

.icon.icon :global(._iconGlobalOverride) {
  margin-left: 7px;
  --width: 15px;
  --height: 15px;
}

.list {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  gap: 7px;
}
