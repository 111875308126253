.main {
  top: 33px;
  overflow: auto;
}

.component {
  display: flex;
  flex-flow: column nowrap;
  place-content: stretch flex-start;
  overflow-y: auto;
}

.main.edit .component {
  align-items: center;
}
