.splash {
  margin: 28px auto 21px;
  text-align: center;
  font-size: 62px;
  line-height: 1;
}

.header {
  text-align: center;
  font-size: 28px;
  font-weight: 500;
}

.subheader {
  text-align: center;
  color: #9aa8bd;
}

.actions {
  margin: 35px 0 0;
  display: flex;
  justify-content: center;
}

.actions :global(.ui.button) {
  min-width: 300px;
}
