.formWrapper {
  display: flex;
  margin: 16px 0;
}

.formWrapper .form {
  flex: 1;
}

.formWrapper .preview :global(.preview) {
  padding-right: 0;
}

.wrapper {
  border: 1px solid #eaeaea;
  border-radius: 5px;
  padding: 25px 30px;
  margin: 20px 0;
}

.wrapper :global(.field) {
  justify-content: space-between;
}

.titleDescription {
  margin-top: 12px;
  color: var(--GRAY-MID);
}

.dropdownField {
  max-width: 20% !important;
}

.optionSectionWrapper {
  margin-top: 24px;
}

.optionSectionWrapper > p {
  font-weight: 500;
}

.addButton {
  border: 1px solid var(--PRIMARY-BLUE) !important;
  color: var(--PRIMARY-BLUE) !important;
  padding: 7px 14px !important;
  justify-content: center;
}

.closeButton {
  border-radius: 10px;
  height: 34px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  place-self: center;
  border: unset;
}

.closeButton:hover {
  background-color: var(--DISABLED-BORDER-COLOR);
}

.sortControl {
  place-self: center;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.optionWrapper {
  margin: 16px 0;
}

.optionGrid {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 20px 1fr 1fr 30px;
}

.optionTitle {
  grid-column: 2 / 3;
}

.optionField {
  margin: 0 !important;
}

.optionField input {
  width: 100% !important;
}

.optional {
  color: var(--GRAY-MID);
  margin-left: 8px;
}

.deleteSectionContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.deleteSectionButton {
  color: var(--WARNING-TEXT) !important;
  border: none !important;
}

.deleteSectionButton :first-child {
  margin-right: 8px;
}
