.modal ul {
  list-style-type: none;
  padding: 0;
  margin: 0 0 42px;
}

.listRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.listRow > svg {
  margin-right: 7px;
}

.modal :global(.ui.button) {
  width: 100% !important;
  padding: 10px 0 !important;
  display: flex;
  justify-content: center;
  margin-bottom: 42px;
}

.tipContainer {
  border-left: 8px solid var(--PRIMARY-BLUE);
  background: rgba(96, 120, 255, 0.1);
  margin-bottom: 42px;
  padding: 20px;
}

.tipTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10.5px;
}

.tipTitle img {
  margin: 0 0 0 7px !important;
}
