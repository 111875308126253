.popup {
  background-color: #fff;
  padding: 10px 17px;
  width: 260px;
  max-height: 440px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
}

.header {
  text-align: right;
  margin: 0 0 14px;
  font-weight: 500;
}

.outLink {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.outLink:after {
  content: url("../../../assets/images/icons/external-link.svg");
  display: inline-flex;
  transform: translateX(4px) translateY(3px);
  align-items: center;
}

.search {
  margin: 0 0 10px;
  grid-template-areas: "main";
  display: grid !important;
  align-items: center;
  justify-content: stretch;
}

.search .input {
  grid-area: main;
  padding-right: 30px !important;
  width: 100%;
}

.search .close {
  grid-area: main;
  cursor: pointer;
  justify-self: end;
  transform: translateX(-10px);
}

.status {
  color: var(--GRAY-MID);
}

.labels {
  height: 257px;
  overflow: hidden auto;
}

.labelsInner {
  position: relative;
  width: 100%;
}

.labels .item {
  cursor: pointer;
  padding: 0 0 7px;
}

.labels .item.virtualized {
  position: absolute;
  top: 0;
  left: 0;
}

.labels .item .label {
  cursor: pointer;
  overflow-y: hidden;
  position: relative;
}

.action {
  color: var(--PRIMARY-BLUE);
  margin: 14px 0 18px;
  cursor: pointer;
  font-weight: 500;
}
