.root {
}

.head {
  font-weight: 500;
}

.subhead {
  color: var(--GRAY-MID);
}

.form {
  margin: 21px 0 0;
}

.items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px 24px;
}
.actions {
  margin: 21px 0 0;
  display: flex;
  justify-content: flex-end;
}
