.body {
  min-width: 400px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 8px;
  margin: 0 0 8px;
  background-color: #fff;
  border-radius: 8px;
}

.image {
  width: 72px;
  height: 72px;
  flex: 0 0 72px;
  margin-right: 15px;
  background-color: #ceebff;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.image.empty {
  background-color: var(--GRAY-LIGHT);
}

.image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.body .content {
  flex: 1 0 auto;
}
.body .content .count {
  margin: 0 0 5px;
  font-weight: 500;
}

.body .content .icon {
  width: 16px;
  height: 15px;
  background: url(./assets/cart.svg) 0 0 no-repeat;
  margin-right: 5px;
}

.actions {
  border-radius: 8px;
}
.actions :global(.ui.button) {
  display: block !important;
  width: 100%;
}
