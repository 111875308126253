.container {
  margin-bottom: 17px;
}
.headerWrap {
  display: flex;
  align-items: center;
  justify-content: stretch;
}
.headerWrap :global(.ui.placeholder) {
  width: 100%;
}
.header {
  font-size: 16px;
  font-weight: 500;
}
.status {
  margin-left: 21px;
}
