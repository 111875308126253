.wrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: space-between;
}

.control {
  opacity: 1;
  cursor: pointer;
  padding: 4px;
}

.control:not(.disabled):hover {
  background-color: var(--DISABLED-BORDER-COLOR);
  border-radius: 4px;
}

.disabled {
  opacity: 0.2;
  cursor: default;
}
