.noScrollX,
.noScrollY {
  -ms-overflow-style: none;
}

.noScrollX::-webkit-scrollbar,
.noScrollY::-webkit-scrollbar {
  display: none;
  width: 0 !important;
}

.noScrollX {
  overflow-x: scroll;
}

.noScrollY {
  overflow-y: scroll;
}
