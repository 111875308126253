.container {
  position: relative;
}
.container .image {
  width: 100%;
}
.container .action {
  position: absolute;
  top: 40%;
  width: 100%;
  display: flex;
  z-index: 9999;
  align-items: center;
  justify-content: center;
}
