.backButton {
  cursor: pointer;
  line-height: 17px;
  position: absolute;
  left: 0;
  top: 0;
}

.backIcon {
  --image: url("../../../assets/images/icons/arrow-left-back.svg");
  --width: 6px;
  --height: 11.33px;
  margin-right: 6px;
}

.cancelIcon {
  --image: url("../../../assets/images/cross.svg");
  --width: 24px;
  --height: 24px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}
