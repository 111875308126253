.container {
  width: 1000px;
  margin: 0 auto;
  padding: 0 0 0 20px;
  overflow-y: auto;
}

.description {
  display: flex;
  justify-content: space-between;
}

.backButton {
  float: left;
}

.content {
  width: 800px;
  margin: 0 auto;
  margin-bottom: 24px;
  padding: 40px;
  border-radius: 8px !important;
}

.enableStripeWrapper {
  display: flex;
}

.enableStripeWrapper .icon {
  cursor: pointer;
}

.statusAlert {
  margin-top: 48px;
}

.statusAlert :global(.tipHeader) {
  display: flex;
}

.statusAlert :global(.tipHeader svg) {
  margin-right: 7px;
}

.statusAlertChildContainer {
  display: flex;
  flex-direction: column;
}

.statusAlertChildContainer button {
  align-self: flex-start;
}

.statusAlertContent {
  padding: 11.5px 0 8px;
}
