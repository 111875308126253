.card {
  border-radius: 5px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  align-self: stretch;
  justify-self: stretch;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: stretch;
  margin-bottom: 15px;
  max-width: 280px;
  width: 280px;
  min-width: 280px;
  margin-right: 20px;
  height: 354px;
  max-height: 354px;
  transition-duration: 0;
}

.card:hover {
  background-color: var(--TEMPLATE-SELECTION-HOVER-COLOR);
}

.card.dummy:hover {
  background-color: revert;
}

.header {
  flex: 0 0 45px;
  padding: 12px 12px 8px;
  text-align: center;
  border-bottom: 1px solid var(--DISABLED-BORDER-COLOR);
  display: flex;
  align-items: center;
}

.header .titleWrapper {
  flex-direction: column;
  flex: 1;
  display: flex;
  overflow: hidden;
}

.header .star.solid {
  width: 17px; /*for you, Chrome*/
  --size: 17px;
  --color: var(--PRIMARY-BLUE);
  flex: none;
  margin-left: 4px;
}

.header .line {
  font-weight: 700;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 8px;
}

.header .category {
  color: var(--GRAY-MID);
  flex: 1;
  padding-bottom: 4px;
}

.body {
  flex: 1 1 auto;
  overflow: hidden auto;
  padding: 14px;
  white-space: pre-wrap;
  line-height: 1.6;
}

.buttons {
  flex: 0 1 auto;
  margin: 0 -1px 0;
  padding: 14px 14px 5px;
  border-top: 1px solid var(--DISABLED-BORDER-COLOR);
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.button {
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  border-radius: 5px;
  margin-bottom: 10px;
  min-width: 120px;
  text-align: center;
}

.button :global(span) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0px 8px;
}

.button:nth-child(2n) {
  flex: auto;
  max-width: 120px;
}

.button:not(:last-child) {
  max-width: 120px;
  text-overflow: ellipsis;
}

.button:last-child {
  flex: 1;
}

.actions {
  margin-top: -5px;
}

.eye.icon {
  --width: 19px;
  --height: 10px;
  --image: url("../../../../component/Chat/Messenger/SelectWhatsappTemplate/assets/eye.svg");
  margin: 0 7px 0 0;
}

.arrow.icon {
  --width: 17px;
  --height: 10px;
  --image: url("../../../../component/Chat/Messenger/SelectWhatsappTemplate/assets/arrow.svg");
  margin: 0 0 0 7px;
}

.footer {
  font-size: 12px;
  color: var(--GRAY-MID);
}

.body .headerText {
  font-weight: 700;
}

.overlayActions {
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  align-items: center;
  height: 100%;
}

.overlayActions :global(.ui.button.ui.ui):hover {
  opacity: 1 !important;
  background-color: #fff !important;
}

.overlay:global(.ui) {
  background-color: transparent;
  transition-duration: 0;
}
