.modal {
  width: 1120px !important;
  border-radius: 16px !important;
}

.modal .modalHeader.modalHeader {
  font-size: 20px !important;
  padding-top: 40px;
  padding-bottom: 0;
  background-color: transparent;
}

.modal .modalContent {
  background-color: transparent;
  max-height: 70vh;
}

.modal .modalHeader,
.modal .modalContent {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.modal :global(> i.close) {
  top: -10px !important;
  right: -45px !important;
}

.modal :global(> i.close:before) {
  content: url("../../assets/images/ui/cross-rounded-white.svg");
}

.body {
  display: grid;
  grid-template-areas: "preview settings" "buttons buttons";
  grid-template-rows: minmax(0, 1fr) min-content;
  grid-template-columns: 268px auto;
  grid-gap: 21px 30px;
  max-height: 60vh;
  height: 100%;
}

.preview {
  grid-area: preview;
  height: 100%;
}

.settings {
  grid-area: settings;
  overflow-y: auto;
}

.settings .section {
  margin-bottom: 42px;
}

.actionsTitle {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 16px;
}

.buttons {
  grid-area: buttons;
  border-top: 1px solid #eaeaea;
  padding-top: 21px;
}

.buttons :global(.ui.button) {
  margin-right: 14px;
}
