.grid {
  display: grid;
  grid-template-columns: 4fr 5fr;
  grid-gap: 0 28px;
  flex-flow: row nowrap;
}

.grid .column {
  justify-self: stretch;
}

.grid .column :global(.ui.input) {
  display: block;
}

.root :global(.ui.ui.dropdown .visible.menu) {
  min-width: min-content;
}

.root :global(.ui.ui.dropdown i.search) {
  top: 0;
  right: 0;
}

.root :global(.ui.ui.dropdown input.search) {
  visibility: hidden;
}

.root :global(.ui.ui.dropdown .text) {
  visibility: visible;
}

.root :global(.ui.ui.dropdown.active input.search) {
  visibility: visible;
}

.root :global(.ui.ui.dropdown.active .text) {
  visibility: visible;
}

.root :global(.ui.ui.dropdown .item) {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}

.root :global(.ui.ui.dropdown .item.tooltipped) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.root :global(.ui.ui.dropdown .item.tooltipped .text-full) {
  flex: 1 0 auto;
  display: flex;
  justify-content: stretch;
  align-items: center;
}

.root :global(.ui.ui.dropdown .item.tooltipped .text-full .text) {
  flex: 1 0 auto;
  margin-left: 10px;
}

.hide:global(.ui.ui.dropdown) {
  display: none !important;
}

.display:global(.ui.ui.dropdown) {
  display: block;
}

.rule :global(.ui.ui.dropdown .menu) {
  width: auto;
}

.rule :global(.ui.ui.dropdown .menu .text) {
  white-space: nowrap !important;
}

.rule :global(.menu .item) {
  width: auto;
  max-width: 50vw;
}

.rule :global(.menu .item .image) {
  width: 16px;
  height: 16px;
}

.rule :global(.menu .item .text) {
  white-space: nowrap;
}

.staff :global(.menu) {
  width: auto;
}

.staff :global(.menu > .item) {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 50vw;
  width: auto;
}

.staff :global(.menu > .item .text) {
  white-space: nowrap;
}

.staff :global(.menu > .item > .sb-avatar.sb-avatar--text) {
  margin-right: 0.78571429rem;
}

.staff :global(.menu > .item > img) {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.staff :global(.menu > .item i) {
  max-width: 16px;
  min-width: 16px;
}
