.root {
  display: grid;
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 26px 30px;
  grid-template-columns: 1fr;
  grid-template-areas: "head head" "chart summary";
  grid-gap: 30px 0;
}

.head {
  grid-area: head;
  font-weight: 500;
}

.summary {
  grid-area: summary;
}

.summary .item {
  margin: 0 0 34px;
}

.summary .item:last-child {
  margin-bottom: 0;
}

.chart {
  grid-area: chart;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto;
  align-content: center;
  justify-items: center;
  grid-template-areas: "center";
  transform: translateY(-30px);
}

.chartWrap {
  grid-area: center;
  align-self: center;
}

.chartWrap > :global(div[style]) {
  height: auto !important;
}

.chartWrap > div[type],
.chartWrap :global(.apexcharts-canvas) {
  height: 250px !important;
  min-height: 0 !important;
}

.chartWrap :global(.apexcharts-canvas > svg) {
  overflow: visible;
  transform: translateY(35px);
  height: 250px !important;
}

.chartWrap :global(.apexcharts-canvas > .apexcharts-inner) {
  transform: translate(35px, 0);
}

.chartWrap :global(.apexcharts-canvas > svg path) {
  stroke-width: 0;
}

.pending .chartWrap {
  color: #ccc;
}

.conversion {
  grid-area: center;
  align-self: center;
  text-align: center;
  transform: translateY(7px);
}

.conversion .label {
  line-height: 1.2;
}

.conversion .amount {
  font-size: 25px;
  font-weight: 700;
  margin-right: -15px;
}

.conversion .percent {
}
