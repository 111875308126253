.fieldSet {
  margin: 0 0 32px;
}

.fieldSet .head {
  font-weight: 500;
  margin: 0 0 8px;
}

.fieldSet .subhead {
  color: var(--DISABLED-FONT-COLOR);
}
