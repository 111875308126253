.header {
  padding: 15px 30px;
}

.textWrap {
  max-width: 80%;
}

.title {
  font-weight: 500;
  color: var(--BLACK);
  font-size: 16px;
}

.subTitle {
  display: flex;
  font-weight: 500;
  color: var(--GRAY-MID);
  font-size: 14px;
  line-height: 17px;
  margin-top: 14px;
}

.type {
  color: var(--GRAY-MID);
  margin-right: 14px;
  padding-right: 14px;
  position: relative;
}

.type:after {
  position: absolute;
  top: 2.5px;
  right: 0;
  display: block;
  content: "";
  width: 1px;
  height: 12px;
  background-color: var(--GRAY-MID);
}

.status {
  position: relative;
  padding-left: 18px;
}

.status:before {
  display: block;
  position: absolute;
  top: 3px;
  left: 0;
  content: "";
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: var(--GRAY-MID);
}

.live {
  color: var(--SUCCESS-GREEN);
}

.live:before {
  background-color: var(--SUCCESS-GREEN);
}

.filterWrap {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.dateRangePicker {
  width: 250px;
  position: relative;
}

.dateRangePicker :global(.react-datepicker__input-container:before) {
  left: 14px;
  top: calc(50% - 8.5px);
  width: 17px;
  height: 17px;
  background-image: url("./assets/calendar-gray.svg");
}

.dateRangePicker :global(.react-datepicker__input-container:after) {
  position: absolute;
  line-height: 20px;
  content: "";
  right: 15px;
  top: calc(50% - 2.5px);
  width: 10px;
  height: 5px;
  background: url("../../../assets/images/ui/dropdown-chevron-default.svg")
    no-repeat;
}

.dateRangePicker :global(.react-datepicker__input-container > input) {
  padding-left: calc(1em + 24px);
  min-height: unset;
  height: 35px;
  cursor: pointer;
}

.selectedDateRangePicker :global(.react-datepicker__input-container:after) {
  background: none;
}

.selectedDateRangePicker :global(.react-datepicker__input-container > input) {
  background-color: var(--LIGHT-BLUE) !important;
}

.clearIconWrap {
  height: 35px;
  width: 30px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.clearIconWrap :global(.ui.image) {
  position: absolute;
  top: calc(50% - 4.5px);
  right: 15px;
}

.triggerStatus {
  width: 200px;
  height: 35px;
  margin-left: 14px;
  position: relative;
}

.triggerStatus :global(.ui.search.dropdown) {
  min-height: unset !important;
  padding: 8px 14px !important;
  height: 35px;
}

.triggerStatus :global(.ui.search.dropdown > input) {
  min-height: unset !important;
  height: 35px !important;
  top: 0px !important;
}

.triggerStatus :global(.ui.search.dropdown > .text) {
  line-height: 35px !important;
}

.triggerStatus :global(.ui.search.dropdown > .dropdown.icon) {
  width: 10px !important;
  right: 14px !important;
  top: calc(50% - 8px) !important;
}

.selectedTriggerStatus :global(.ui.search.dropdown) {
  background-color: var(--LIGHT-BLUE) !important;
}

.selectedTriggerStatus :global(.ui.search.dropdown > .dropdown.icon:before) {
  display: none;
}

.selectedTriggerStatus .clearIconWrap :global(.ui.image) {
  right: 20px;
}

.tab {
  margin: 28px 0 13px;
}

.tab :global(.ui.pointing.secondary.menu .item:not(:last-child)) {
  margin-right: 42px;
}

.tab :global(.item) {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.tab :global(.item .title) {
  margin-right: 7px;
  color: var(--BLACK) !important;
}

.tab :global(.active.item .title) {
  color: var(--PRIMARY-BLUE) !important;
}

.tab :global(.item .number) {
  color: var(--GRAY-MID) !important;
  margin-right: 5px;
}

.tab :global(.item .percentage) {
  color: var(--GRAY-MID) !important;
}
