.preview {
  --preview-width: 268px;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 35px;
  height: 100%;
  max-height: 520px;
  display: grid;
  grid-template-rows: 1fr minmax(0, 520px);
}

.preview.compact {
  max-width: max-content;
  padding: 0;
}

.preview.fluid {
  max-height: 520px;
}

.preview.fluid .content,
.preview.fluid .footer {
  background-size: cover !important;
}

.preview.fluid .content {
  min-height: 41px;
}

.preview.fluid .footer {
  min-height: 49px;
}

.header {
  font-size: 16px;
  margin-bottom: 7px;
  font-weight: 500;
}

.content {
  display: grid;
  grid-template-rows: 42px minmax(0, 1fr) 0;
  width: 301px;
  height: calc(100% - 33px);
}

.preview.sms .content {
  grid-template-rows: 48px minmax(0, 1fr) 0;
}

.messengerHead {
  display: flex;
  padding: 0 8px;
  align-items: center;
}

.preview.wechat .messengerHead,
.preview.sms .messengerHead {
  padding-left: 14px;
  padding-right: 14px;
}

.preview.whatsapp .content {
  background: url("./assets/whatsapp-sample-lite.png") 0 0 no-repeat transparent;
  max-height: 1110px;
  grid-template-rows: 37px minmax(0, 1fr) 43px !important;
  background-size: cover;
}

.preview.whatsapp .footer {
  background: url("./assets/whatsapp-sample-lite.png") 0 100% no-repeat
    transparent;
  background-size: cover;
  background-position: center calc(100% + 15px);
}

.messengerHead .arrow {
  margin-right: 7px;
}

.messengerHead .logo {
  width: 27px;
  height: 27px;
  margin-right: 7px;
}

.messengerHead .logoName {
  width: 68px;
  height: 12px;
  margin-right: 7px;
}

.preview.wechat .messengerHead .logoName {
  margin-left: auto;
  margin-right: auto;
  flex: 1 0 auto;
  width: 66px;
  height: 12px;
}

.messengerHead .tick {
  width: 18px;
  height: 16px;
}

.messengerHead .dots {
  margin-left: auto;
  flex: 0 0 auto;
}

.langContent {
  margin: 0 0 14px;
  display: flex;
  flex-flow: row nowrap;
}

.langContent pre {
  font-family: var(--DEFAULT-FONT-FAMILY);
  margin: 0;
  font-size: 12px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.balloon {
  position: relative;
  background: #fff;
  font-size: 12px;
  min-height: 36px;
  padding: 10px;
  padding-bottom: 16px;
  border-radius: 8px;
  position: relative;
  min-width: 200px;
}

.balloon::after {
  content: "now";
  position: absolute;
  bottom: 4px;
  right: 8px;
  color: var(--GRAY-MID);
}

.messengerHead .rightIcons {
  margin-left: auto;
}

.preview.generic .content {
}

.preview.sms .content {
  background-color: #fff;
  border: 1px solid #eaeaea;
}

.preview.sms .balloon {
  background-color: #eaeaea;
}

.preview.line .content {
  background: #9aafd7;
}

.preview.wechat .attachment {
  border-radius: 0;
}

.preview.wechat .attachment img {
  border-radius: 2px;
}

.preview.wechat .attachContent .balloon {
  padding: 0;
}

.preview.wechat .attachContent .balloon:before {
  display: none;
}

.preview.wechat .balloon:before {
  position: absolute;
  top: 10px;
  left: -8px;
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  border: 4px solid transparent;
  border-right: 4px solid #fff;
}

.preview.wechat .content {
  background: #f2f2f2;
}

.preview.wechat .avatar {
  margin-right: 14px;
  flex: 0 0 min-content;
}

.body {
  overflow-y: auto;
  padding: 14px;
  max-height: 400px;
}

.footer {
}

.time {
  right: 6px;
  bottom: 0;
  position: absolute;
  color: #d8d8d8;
  font-size: 8px;
}

.buttons {
  margin-top: 7px;
}

.button {
  margin-top: 2px;
  padding: 6px 6px 4px;
  background: #fff;
  border-radius: 5px;
  flex: 1;
  text-align: center;
  cursor: pointer;
  min-height: 30px;
  width: 100%;
  color: #6078ff;
}

.attachContent {
  display: flex;
  align-items: flex-start;
  margin: 0 0 14px;
}

.attachment {
  flex: 1 0 calc(100% - 50px);
  position: relative;
}

.attachment.image img {
  max-width: 100%;
}

.attachment .name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  background-color: #f5f5f5;
  color: #3c4258;
  height: 40px;
  padding: 0 14px;
}

.attachment .name .filename {
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 0 200px;
  white-space: nowrap;
}

.attachment .name img {
  margin-right: 11px;
}

.preview .attachment.image {
  font-size: 0;
}

.preview.wechat .attachment.image {
  max-width: 100px;
}

.preview.wechat .balloon {
  border-radius: 2px;
}

.preview.compact .content {
  width: var(--preview-width);
  background-size: var(--preview-width);
  grid-template-rows: 37px minmax(0, 1fr) 0;
}

.preview.compact .langContent {
  margin-bottom: 22px;
}

.preview.compact .balloon {
  padding: 12px;
}

.preview.compact .balloon pre {
  font-size: 10px;
  color: #636879;
}

.messageHeader {
  font-weight: bold;
  margin-bottom: 4px;
}

.messageFooter {
  color: var(--GRAY-MID);
  margin-top: 4px;
}

.samplePlaceholder {
  width: 250px;
  height: 100px;
  background-color: var(--GRAY-LIGHT);
  margin-bottom: 4px;
}

.sampleDocumentWrapper {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.sampleDocumentWrapper div {
  overflow: hidden;
}

.sampleImageWrapper {
  width: 100%;
  overflow: hidden;
}
.sampleImage {
  width: 100%;
}
