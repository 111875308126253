.nav {
  margin-bottom: 14px;
}

.container {
  display: grid;
  grid-template-rows: min-content min-content minmax(0, auto);
  grid-template-columns: auto;
  width: 100%;
}

.brief {
  background-color: #fff;
  border-radius: 5px 5px 0 0;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  border-bottom: none;
  padding: 21px;
  display: grid;
  grid-template-areas: "main preview" "tabs actions";
  grid-template-rows: auto min-content;
  grid-template-columns: auto auto;
}

.brief .briefData {
  grid-area: main;
}
.brief .tabs {
  grid-area: tabs;
  display: flex;
  align-items: flex-end;
}

.brief .actions {
  grid-area: actions;
  justify-self: end;
}

.grid {
  background-color: #fff;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  border-top: none;
  border-radius: 0 0 5px 5px;
  display: grid;
  grid-template-rows: auto min-content;
}

.gridContents {
  height: 100%;
  overflow: hidden;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 4px 0;
}
