.callActionWrapper {
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  margin-top: 8px;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.deleteButton {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;
  cursor: pointer;
}

.fieldWrapper {
  margin-bottom: 8px;
}

:global(span.length-counter) {
  position: absolute;
  right: 24px;
  top: 41px;
  font-size: 12px;
  color: var(--GRAY-MID);
}

.dynamicUrlSwitchWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
}

.dynamicUrlSwitchWrapper.disabled {
  cursor: default;
}

.dynamicUrlSwitch {
  padding: 4px 12px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
}

.dynamicUrlSwitch.enabled.enabled {
  color: var(--PRIMARY-BLUE);
  border: 1px solid var(--PRIMARY-BLUE);
}

.dynamicUrlSwitch:first-of-type {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: none;
}

.dynamicUrlSwitch:last-of-type {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: none;
}

.inputWrapper {
  display: flex;
  align-items: center;
}

.input.input.input {
  flex: 1;
  margin-bottom: 0;
}

.variable {
  margin: 0 0 0 8px;
  flex: none;
}

.dynamicUrlReminder {
  color: var(--GRAY-MID);
  font-size: 12px;
  margin-top: 8px;
}
