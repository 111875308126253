.accordion {
  padding: 26px 40px;
  position: relative;
}

.accordion.background {
  background: linear-gradient(
    -30deg,
    #f8f9ff 0%,
    #f8f9ff 25%,
    #fff 25%,
    #fff 100%
  );
}

.accordionTitle {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 500;
  align-items: center;
  cursor: pointer;
}

.accordionTitle > div {
  flex: none;
}

.title {
  display: flex;
  align-items: center;
}

.img {
  display: inline-block;
  width: 64px;
  height: 64px;
  padding: 16px;
  background-color: #e7eaff;
  border-radius: 64px;
  margin-right: 28px;
}

.extraText {
  display: inline-block;
  margin-right: 16px;
}

.expandBtn {
  width: 18px;
  height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.expandBtn .arrowIcon {
  --image: url("../../../../assets/images/ui/dropdown-chevron-default.svg");
  --width: 14px;
  --height: 7px;
}

.expandBtn .arrowIcon.active {
  transform: rotate(180deg);
}

.accordionBody {
  padding-top: 16px;
}
