.container {
  display: flex;
  justify-content: center;
}

.box {
  border: 2px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 40px 62px 45px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.box:first-child {
  margin-right: 21px;
}

.box img {
  flex: 0 0 auto;
  width: 63px;
}

.fileName {
  padding-top: 20.25px;
  padding-bottom: 15px;
}

.buttonArea {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-content: center;
}

.buttonArea span {
  color: var(--PRIMARY-BLUE);
  font-weight: 500;
}

.buttonArea span:hover {
  text-decoration: underline;
}

.buttonArea svg {
  width: 15.6px;
  height: 17.87px;
}

.downloadIcon {
  --color: var(--PRIMARY-BLUE);
  margin-left: 7px;
}

.actions {
  padding-top: 34px;
}

.actions div {
  padding-bottom: 14px;
}

.actions a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.redirectIcon {
  --color: var(--PRIMARY-BLUE);
  margin-left: 7px;
}
