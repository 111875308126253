.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px 0;
}

.shippingOption {
  padding: 24px;
  border-radius: 8px;
  border: 1px solid var(--GRAY-UI);
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
}

.shippingOption :global(.field:nth-child(odd)) {
  width: 300px;
}

.shippingOption > div {
  display: flex;
  gap: 24px;
}

.addOptionButton {
  border: 1px solid var(--PRIMARY-BLUE) !important;
  color: var(--PRIMARY-BLUE) !important;
  padding: 8px 14px !important;
}

.removeButton {
  position: absolute;
  right: 8px;
  top: 8px;
  border: none !important;
  color: var(--WARNING-TEXT) !important;
  display: inline-flex;
  gap: 8px;
}
