.component {
  overflow-x: hidden;
  height: 100%;
  border-radius: 5px;
}

.component.bordered {
  border: solid 1px #eaeaea;
  background: var(--WHITE);
}

.component.fluid {
  height: 100%;
  max-height: 100%;
  border-radius: 0;
  border: 0;
}

.items {
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #eaeaea;
  display: grid;
  grid-template-areas: "fixed";
  grid-template-rows: 250px;
  grid-template-columns: 250px;
  height: 100%;
  overflow-y: auto;
}

.component.fluid .items {
  border-top: none;
  border-radius: 0;
}

.items .list {
  grid-area: scrollable;
  overflow-y: auto;
  min-height: 0;
}
.prefix {
  grid-area: fixed;
}
.itemHeader {
  background-color: #fff;
  border-radius: 5px 5px 0 0;
  padding: 20px 30px;
  font-weight: 500;
  border-bottom: 1px solid #eaeaea;
}

.headerWrap {
  grid-area: fixed;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.headerWrap :global(.loader) {
  margin-left: auto;
}

.item {
  border-bottom: 1px solid #eaeaea;
  padding: 20px 30px;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.item.compact {
  padding: 12px 20px 13px;
}

.component.fluid .item {
  padding-left: 14px;
}

.item:last-child:not(.editPaymentLinkBtn) {
  border-bottom: none;
}

.item:hover {
  background-color: var(--GRAY-LIGHT);
  color: var(--BLACK);
}

.search {
  grid-area: fixed;
  padding: 18px 30px;
  align-self: end;
}

.component.fluid .search :global(.ui.input.search) {
  padding-left: 14px;
}

.component.fluid .search :global(.ui.input.search .search.icon) {
  left: 22px;
  height: 19px;
}

.groupName {
  padding: 24px 30px 0;
  color: var(--GRAY-MID);
  font-size: 12px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.infoIcon {
  --image: url("../../assets/images/info_gray.svg");
  --width: 13px;
  --height: 13px;
  margin-left: 8px;
}

.plusIcon {
  --image: url("../../assets/images/automation/primary-cross.svg");
  --width: 17px;
  --height: 17px;
  margin-right: 12px;
}
.addPaymentItem {
  border-bottom: 1px solid #eaeaea;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-bottom: 7px;
}

.addPaymentLink {
  color: var(--PRIMARY-BLUE);
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 7px 30px;
}

.addPaymentLink:hover {
  text-decoration: underline;
  background-color: var(--GRAY-LIGHT);
}
.addPaymentLink:hover,
.editPaymentLinkBtn:hover {
  color: var(--PRIMARY-BLUE);
}

.editPaymentLink {
  display: flex;
  align-items: center;
}

.currentPaymentLink {
  flex: 1;
}

.editPaymentLinkBtn {
  flex: none;
  color: var(--PRIMARY-BLUE);
  cursor: pointer;
}
