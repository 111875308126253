.header {
}

.text {
}

.videoModal {
  padding: 50px;
  width: 600px !important;
  box-sizing: content-box;
}

.actionsInline {
  margin: 0 0 21px;
  display: flex;
  justify-content: flex-end;
}

.actionsInline :global(.ui.button) {
  margin-left: 7px;
}

.externalLink.icon {
  --image: url("../../assets/images/icons/external-link-primary.svg");
  --width: 14px;
  --height: 15px;
  margin-left: 14px;
}
.checkboxContainer {
  border-radius: 8px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  padding: 14px;
  margin-bottom: 21px;
}
.description {
  margin-bottom: 21px;
}
.description .link {
  color: var(--PRIMARY-BLUE);
}
.description .link:hover {
  color: var(--LINK-HOVER);
}
