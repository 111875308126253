.filtersWrap {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
}

.filtersApplied {
  margin-left: 10px;
  flex: 1 0 auto;
}
