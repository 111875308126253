.logo {
  width: 54px;
  height: 54px;
  margin-right: 21px;
}

.title {
  font-weight: 500;
  font-size: 28px;
}

.container {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  text-align: left;
}
