.statusLine {
  margin: 0 0 10px;
}
.statusLine a:link,
.statusLine a:visited,
.statusLine a {
  color: var(--PRIMARY-BLUE);
  text-decoration: none;
  font-weight: 500;
}
