.root {
  padding-left: 32px;
  position: relative;
}

.root:before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 5px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.root.pending:before {
  background-color: #e9e9e9;
}

.root.total:before {
  background-color: #eaeaea;
}
.root.influence:before {
  background-color: #94c2fb;
}

.root.conversions:before {
  background-color: var(--PRIMARY-BLUE);
}

.head {
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  margin: 0 0 8px;
}

.head .text {
  font-weight: 500;
  flex: 0 1 auto;
  display: inline-block;
  margin-right: 8px;
}

.head .text :global(.ui.placeholder) {
  transform: translateY(-7px);
}

.head .hint {
}

.main {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0 0 8px;
  line-height: 1;
}

.main .value {
  font-size: 28px;
  font-weight: 700;
  color: var(--PRIMARY-BLUE);
  flex: 1 0 auto;
}

.main .percent {
  margin-left: 14px;
  flex: 1 1 auto;
}

.main .percent > div {
  margin-left: 0;
}

.previous {
}

.previous .label {
  font-size: 12px;
  line-height: 1.3;
  color: var(--DISABLED-FONT-COLOR);
}

.previous .value {
  font-size: 16px;
  font-weight: 500;
  opacity: 0.8;
}
