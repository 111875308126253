.component {
  --max-content-width: 820px;
  display: grid;
  grid-template-areas: ". nav ." ". main .";
  grid-template-columns: 1fr var(--max-content-width) 1fr;
  grid-template-rows: min-content auto;
  background-color: transparent;
  width: 100%;
  height: 100%;
}

.nav {
  height: 33px;
  grid-area: nav;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav .back {
}

.nav .alternative {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--PRIMARY-BLUE);
}

.nav .alternative .text {
  font-weight: 500;
}

.nav .alternative .icon {
  margin-left: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  grid-area: main;
  overflow-y: auto;
  display: grid;
  grid-template-rows: min-content minmax(0, auto);
  grid-template-areas: "fixed" "scrollable";
}

.fixed {
  grid-area: fixed;
  padding: 0 5px;
}

.scrollable {
  grid-area: scrollable;
  overflow-x: visible;
  overflow-y: auto;
  padding: 5px 0;
}

.header {
  font-size: 28px;
  font-weight: 500;
  margin: 30px 0 28px;
  text-align: center;
}

.page.header {
  margin-bottom: 42px;
}

.subheader {
  margin-bottom: 42px;
  text-align: center;
}

a.external {
}

a.external:after {
  content: url("../../../../assets/images/icons/external-link.svg");
  display: inline-block;
  margin-left: 3px;
  vertical-align: middle;
}

@media screen and (max-device-width: 900px) {
  .component {
    --max-content-width: auto;
  }
}

@media screen and (max-height: 800px) {
  .page.header {
    margin-top: 0;
    margin-bottom: 21px;
  }
  .subheader {
    margin-bottom: 7px;
  }
}
