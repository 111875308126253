.root {
  position: absolute;
  width: 100%;
  top: 30%;
}

.root :global(.container) {
  display: flex;
  flex-flow: column wrap;
  width: 520px;
  margin: 0 auto;
}

.root :global(.container .header) {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 14px;
}

.root :global(.container .sub-header) {
  padding: 0 0 21px;
  font-size: 16px;
}

.root :global(.container .header + .sub-header) {
  margin-top: -14px;
  color: var(--DISABLED-FONT-COLOR);
}
.root :global(.content a),
.root :global(.content a:link) {
  color: var(--DISABLED-FONT-COLOR);
}

.root :global(.container .content) {
  padding-bottom: 15px;
}

.root :global(.container .action .button) {
  padding: 8px 15px;
  min-height: 33px;
}

.root :global(.container .action .button:last-child) {
  margin-left: 10px;
}

.root :global(.container .action .button:first-child) {
  margin-left: 0;
}

.root :global(.container .action.individual-list .ui.button) {
  width: 268px;
  display: flex;
  justify-content: center;
}

.root :global(.container .action.individual-list:last-child) {
  padding-top: 21px;
}

.root.centered :global(.header),
.root.centered :global(.content),
.root.centered :global(.sub-header) {
  text-align: center;
}

.root.centered :global(.action) {
  display: flex;
  justify-content: center;
}
