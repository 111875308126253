.promoAddOn {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(96, 120, 255, 0.1) !important;
  border-radius: 0 !important;
  height: 107px;
  border-left: 8px solid var(--PRIMARY-BLUE);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.promoAddOn :global(.ui.button) {
  min-height: 32px !important;
  padding: 7.5px 30px !important;
  margin-top: 14px;
}

.promoAddOn :global(.ui.image) {
  display: inline-block;
  margin-right: 8px;
}

.promoAddOnSizeBox {
  height: 107px;
}
