.whatsappChannelNotIncluded {
  color: var(--DISABLED-FONT-COLOR);
  align-self: self-start;
  margin-top: 60px;
}

.subscriptionPlanContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 32px 30px;
}
