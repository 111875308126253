.grid:global(.table.ui) {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  --iconWidth: 24px;
  --iconHeight: 24px;
  --imgIconWidth: 24px;
  --imgIconHeight: 24px;
  --padSide: 20px;
}

.grid:global(.table.ui) > thead tr:first-child td,
.grid:global(.table.ui) > thead tr:first-child th {
  padding-top: 11px !important;
}

.grid:global(.table.ui.ui) thead th:first-child,
.grid:global(.table.ui.ui) tr td:first-child {
  padding-left: var(--padSide) !important;
}

.grid:global(.table.ui.ui) thead th:last-child,
.grid:global(.table.ui.ui) tr td:last-child {
  padding-right: var(--padSide) !important;
}

.grid:global(.table.ui.ui) > thead tr th {
  position: sticky !important;
  border-left: none;
  border-right: none;
  border-top: 1px solid var(--GRAY-UI);
  border-bottom: 1px solid var(--GRAY-UI);
  font-weight: 500;
  top: 0;
  z-index: 5;
  padding: 11px 15px 11px var(--padSide);
  background-color: var(--GRAY-LIGHT);
  color: #9aa8bd;
  white-space: nowrap;
  text-transform: uppercase;
}

.grid:global(.table.ui.ui) > thead tr th:hover {
  color: #9aa8bd;
}

.grid:global(.ui.ui.ui thead tr th.sortable) {
  padding-right: 30px !important;
}

.grid:global(.table.ui.ui) th:global(.sortable:after) {
  transform: translateX(-12px) !important;
}

.grid:global(.table.ui.ui > thead tr th.sortable:hover:after) {
  color: #9aa8bd;
}

.grid:global(.table.ui) > thead tr th .hint {
  margin-left: 7px;
}

.grid:global(.table.ui > thead tr th.hide) {
  display: none;
}

.grid:global(.table.ui > thead tr th.hide) + th {
  padding-left: 30px;
}

.grid:global(.table.ui > thead tr th .field-header) {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
}

.grid:global(.table.ui) > thead tr:hover th {
  background-color: var(--GRAY-LIGHT);
}

.grid:global(.table.ui) > tbody tr {
  border-bottom: 0.5px solid #f1f1f1;
}

.grid:global(.table.ui) > tbody tr:hover,
.grid:global(.table.ui > tbody tr.hovered) {
  background: var(--GRAY-LIGHT);
}

.grid:global(.table.ui) > tbody tr:hover + tr td,
.grid:global(.table.ui > tbody tr.hovered) + tr td {
  border-top: 0.5px solid var(--GRAY-LIGHT);
}

.grid:global(.table.ui) > tbody tr:first-child td {
  border-top: none;
}

.grid:global(.table.ui) > tbody tr:last-child td {
  border-bottom: none;
}

.grid:global(.table.ui) > tbody tr:hover td,
.grid:global(.table.ui > tbody tr.hovered) td {
  border-top: 0.5px solid var(--GRAY-LIGHT);
}

.grid:global(.table.ui) > tbody tr:first-child:hover td {
  border-top: none;
}

.grid:global(.table.ui > tbody tr.empty.row td:hover) {
  background-color: #fff;
  cursor: default;
}

.grid:global(.table.ui > tbody tr.empty.row:hover td) {
  background-color: #fff;
}

.grid:global(.table.ui) > tbody td {
  padding: 11px 5px;
  border-left: none;
  border-top: 0.5px solid var(--GRAY-UI);
  border-bottom: 0.5px solid var(--GRAY-LIGHT);
  white-space: nowrap;
  padding-left: var(--padSide);
  color: var(--GRAY-DARK);
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}

.grid:global(.table.ui > tbody td.hide) {
  display: none;
}

.grid:global(.table.ui > tbody td.hide) + td {
  padding-left: 30px;
}

.grid:global(.table.ui) > tbody td a:hover {
  text-decoration: none;
}

.grid:global(.table.ui > tbody td.origin) {
  text-transform: none;
}

.grid:global(.table.ui > tbody td.origin) > a,
.grid:global(.table.ui > tbody td > .message-content-transformed > a.origin) {
  color: var(--PRIMARY-BLUE);
}

.grid:global(.table.ui)
  > tbody
  td
  :global(> .message-content-transformed > a.origin):hover {
  color: var(--PRIMARY-BLUE);
  cursor: pointer;
  text-decoration: underline;
}

.grid:global(.table.ui) > tbody td a {
  cursor: pointer;
}

.grid:global(.table.ui > tbody td a.text-color),
.grid:global(.table.ui > tbody td a.text-color:hover) {
  color: var(--GRAY-DARK);
  text-decoration: none;
  border: none;
}

.grid:global(.table.ui) > tbody td:last-child {
  padding-right: 1.6em;
}

.grid:global(.table.ui > tbody td.cell-displayName a),
.grid:global(.table.ui > tbody td.cell-displayName .field-major) {
  color: var(--GRAY-DARK);
  font-weight: 500;
}

.grid:global(.table.ui > tbody tr:hover td.cell-displayName a),
.grid:global(.table.ui > tbody tr.hovered td.cell-displayName a),
.grid:global(.table.ui > tbody tr:hover td.cell-displayName .field-major),
.grid:global(.table.ui > tbody tr.hovered td.cell-displayName .field-major),
.grid:global(.table.ui > tbody tr:hover td a.text-color),
.grid:global(.table.ui > tbody tr.hovered td a.text-color) {
  color: var(--BLACK);
}

.grid:global(.table.ui > tbody td .channels) {
  display: flex;
  flex-direction: row;
  text-transform: capitalize;
}

.grid:global(.table.ui > tbody td .displayName) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.grid:global(.table.ui > tbody td .field-major) {
  flex: 1;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
}

.grid:global(.table.ui) > tbody td i {
  max-width: var(--iconWidth);
  max-height: var(--iconHeight);
  width: var(--iconWidth);
  height: var(--iconHeight);
  line-height: 1;
}

.grid:global(.table.ui > tbody td i.icon) {
  font-size: var(--iconHeight);
}

.grid:global(.table.ui) > tbody td img {
  max-width: var(--imgIconWidth);
  max-height: var(--imgIconHeight);
  width: var(--imgIconWidth);
  height: var(--imgIconHeight);
  margin: 0 0.25rem 0 0;
}

.grid:global(.table.ui > tbody td > div.sb-avatar.sb-avatar--text) {
  padding-left: 0;
  max-width: 24px;
  margin: 0 0.25rem 0 0;
}

.grid:global(.table.ui > tbody td .ui.button) {
  padding: 8px 15px;
  min-height: 33px;
}

.grid:global(.table.ui td.checkbox),
.grid:global(.table.ui th.checkbox) {
  white-space: nowrap;
  line-height: normal;
  padding-left: 30px;
  padding-right: 10px;
  max-width: 60px;
  width: 60px;
}

.grid:global(.table.ui td.checkbox .ui.checkbox),
.grid:global(.table.ui th.checkbox .ui.checkbox) {
  min-height: 20px;
  flex: 0 0 20px;
}

.grid :global(.checkbox-wrap),
.grid :global(.checkbox-wrap) {
  width: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
}

.grid :global(.checkbox-wrap .drag-handle),
.grid :global(.checkbox-wrap .drag-handle) {
  width: 20px;
  height: 20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-right: 4px;
  margin-left: 4px;
}

.grid :global(.checkbox-wrap .drag-handle.disabled),
.grid :global(.checkbox-wrap .drag-handle.disabled) {
  opacity: 0.3;
}

.grid :global(.checkbox-wrap .drag-handle .icon),
.grid :global(.checkbox-wrap .drag-handle .icon) {
  font-size: 16px;
  width: 16px;
  height: 16px;
}

.grid:global(.table.ui td.checkbox .checkbox-wrap .ui.loader),
.grid:global(.table.ui th.checkbox .checkbox-wrap .ui.loader) {
  position: relative;
  left: 0;
  top: 0;
  width: 20px;
  transform: none;
}

.grid:global(.table.ui td.checkbox .ui.checkbox.invisible),
.grid:global(.table.ui th.checkbox .ui.checkbox.invisible) {
  visibility: hidden;
}

.grid:global(.table.ui.ui.ui.dnd td.checkbox),
.grid:global(.table.ui.ui.ui.dnd th.checkbox) {
  padding-left: 5px;
}

.grid:global(.table.ui.ui.ui.dnd tbody tr) {
  background-color: #fff;
}

.grid:global(.table.ui.ui.ui.dnd tbody tr:hover),
.grid:global(.table.ui.ui.ui.dnd tbody tr.hovered) {
  background: var(--GRAY-LIGHT);
}

.grid:global(.table.ui.ui.ui.dnd tbody:hover + tr td),
.grid:global(.table.ui.ui.ui.dnd tbody.hovered + tr td) {
  border-top: 0.5px solid var(--GRAY-LIGHT);
}

.grid:global(.table.ui.ui.ui.dnd tbody:hover + tr td),
.grid:global(.table.ui.ui.ui.dnd tbody.hovered + tr td) {
  background-color: #fff;
}

.grid:global(.table.ui .no-contacts-status) {
  text-align: center;
  position: absolute;
  top: 50%;
  width: 100%;
}

.grid :global(.thead-popup) {
  width: 100%;
}

.grid :global(.thead-popup td),
.grid :global(.thead-popup th) {
  top: 46px;
  border-top: none;
  padding: 0 !important;
  position: relative;
}

.grid :global(.thead-popup .table-popup) {
  position: sticky;
  left: 21px;
  text-align: left;
  padding: 10px 10px 10px 30px;
  color: #3c4257;
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  text-transform: none;
}

.grid :global(.thead-popup .table-popup a) {
  margin: 0 8px;
}

.grid :global(.thead-popup .status-text) {
  flex: 0 1 auto;
  margin-right: 21px;
  max-width: 50vw;
}

.grid :global(.thead-popup .actions) {
  flex: 1 0 auto;
  display: flex;
  flex-flow: row nowrap;
}

.grid :global(.thead-popup .actions .ui.button) {
  padding: 8px 15px;
  min-height: 33px;
  margin-left: 1em;
}

.grid :global(.thead-popup .actions .buttons-group) {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: stretch;
}

.grid:global(.table.ui .table-wide-row td),
.grid:global(.table.ui .table-wide-row th) {
  z-index: 5;
  padding: 0;
  height: 30px;
  background-color: var(--BODY-BG);
}

.grid :global(.context-menu-trigger) {
  margin-left: 8px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 12px;
}

.grid :global(.context-menu-trigger .icon.button-dots) {
  cursor: pointer;
  width: 12px;
  height: 12px;
}
