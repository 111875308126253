.container {
  width: 620px;
  margin: auto;
  padding-top: 7px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19.09px;
  padding-bottom: 14px;
}

.title:before {
  content: "\26A0";
  margin-right: 10px;
  font-family: "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji,
    "Segoe UI Symbol", "Android Emoji", EmojiSymbols, "EmojiOne Mozilla" !important;
}

.content {
  line-height: 22.4px;
  padding-bottom: 28px;
}

.content span {
  font-weight: 500;
}

.tipContainer > div {
  padding: 21px 22px !important;
}

.tipContentContainer {
  --color: var(--PRIMARY-BLUE);
  padding-left: 26px;
}
.tipContentContainer :global(span) {
  padding-left: 9px;
}
.alert :global(.tipHeader) {
  display: flex;
}

.tip {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 22.4px;
  flex-wrap: nowrap;
}

.tip span {
  word-break: break-all;
}

.tip svg {
  margin-right: 7px;
  flex: 0 0 auto;
}

.tip img {
  display: inline-block !important;
  margin-left: 5px;
  cursor: pointer;
}

.tip:first-of-type {
  padding-bottom: 7px;
}

.bold {
  font-weight: 500;
}
