.wrapper {
  position: relative;
  width: 100%;
}

.input {
  width: 100%;
}

.counter {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  color: #9aa8bd;
  font-weight: normal;
}
