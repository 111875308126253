.header {
  padding: 21px 0px !important;
  width: 100%;
  font-size: 20px !important;
  justify-content: center !important;
}
.content {
  padding: 0px 30px !important;
  text-align: center;
}
.actions {
  padding-top: 21px !important;
  justify-content: center !important;
}
.actions :global(.ui.button) {
  padding: 8px 15px !important;
  margin-right: 14px;
}
