.editIcon {
  --width: 15px;
  --height: 16px;
  --image: url("./assets/pencil.svg");
}

.deleteIcon {
  --width: 13px;
  --height: 15px;
  --image: url("./assets/trash.svg");
}
.label {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
}
.actions {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.action {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.action:hover {
  background: var(--DISABLED-BORDER-COLOR);
  border-radius: 8px;
  cursor: pointer;
}

.labelCell {
}
.actionsCell {
  width: 1%;
}
