.header {
  margin-top: 21px;
  font-size: 28px;
  font-weight: 500;
}

.helperText {
  font-size: 14px;
  color: #9aa8bd;
}

.inviteUserContainer {
  background-color: #fff;
  border-radius: 8px;
  margin: 21px 0px;
  padding: 30px;
}

.subHeader {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
}

.subHeader > div:first-child {
  display: flex;
  margin-right: 14px;
}

.textContainer {
  display: flex;
  align-items: flex-start;
  margin-bottom: 21px;
}

.textContainer svg {
  margin-right: 8px;
  width: 19px;
}

.textContainer:last-child {
  margin-bottom: 0px;
}

.premiumTag {
  color: #9a4f21;
  background-color: #f3e6be;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  margin-left: 8px;
}

.inviteUserButton {
  margin: 0px !important;
}

.boldText {
  font-weight: 500;
}
