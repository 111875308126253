.title {
  font-size: 16px;
  font-weight: 500;
  padding-top: 16px;
}

.description {
  color: var(--GRAY-MID);
}

.checkboxWrapper {
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.checkboxLabel {
  margin-left: 8px;
  font-weight: 500;
}

.checkboxOn {
  color: var(--SUCCESS-GREEN);
}

.checkboxOff {
  color: var(--GRAY-MID);
}

.radio {
  margin: 16px 32px 0 0;
}
.radioWrapper .radio :global(input:checked ~ label::before) {
  border-color: var(--PRIMARY-BLUE) !important;
  top: 1px;
  border-radius: 100%;
}

.radioWrapper.radioWrapper .radio :global(input ~ label::before) {
  border-radius: 100%;
}

.radioWrapper.radioWrapper .radio :global(input:checked ~ label::after) {
  color: #fff;
  content: "" !important;
  font-size: 14px;
  height: 8px;
  width: 8px;
  top: 7px;
  left: 6px;
  background-color: var(--WHITE) !important;
}
