.frame {
  width: 160px;
  max-height: 400px;
  min-height: 56px;
  border-radius: 16px;
  overflow: hidden;
  margin: 0 0 8px;
  position: relative;
}

.frame.empty {
  height: 160px;
}

.frame.hovered {
  box-shadow: var(--DISABLED-FONT-COLOR) 0 0 8px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.button {
  margin: 0 0 8px !important;
}

.close {
  width: 24px;
  height: 24px;
  right: 8px;
  top: 8px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  --iconColor: #fff;
  --widthOverride: 10px;
  --heightOverride: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
