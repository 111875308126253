.tab {
  display: flex;
  justify-content: flex-start;
}

.messageTagContainer :global(.status-alert) {
  margin-top: 13px;
}

.facebookOTNContainer :global(.status-alert) > div:first-of-type,
.messageTagContainer :global(.status-alert) > div:first-of-type {
  display: flex;
  font-weight: 500;
}

.facebookOTNContainer :global(.status-alert) > div:first-of-type > svg,
.messageTagContainer :global(.status-alert) > div:first-of-type > svg {
  order: 1;
  margin-left: 7px;
}

.facebookOTNContainer :global(.status-alert) > div:first-of-type > div,
.messageTagContainer :global(.status-alert) > div:first-of-type > div {
  order: 0;
}

.warning ul {
  font-weight: 500;
}

.activated {
  padding-top: 10.5px;
  padding-bottom: 10px;
}

.recipientsAccept {
  padding-top: 10px;
  padding-bottom: 21px;
}

.learnMore {
  cursor: pointer;
  font-weight: 500;
  color: var(--PRIMARY-BLUE);
  display: flex;
}

.learnMore img {
  margin-left: 7px;
}

.messageTagContainer {
  margin-top: 24px;
  margin-bottom: 24px;
}

.facebookOTNContainer {
  margin-top: 24px;
  margin-bottom: 7px;
}

.messageTagContainer label,
.facebookOTNContainer label {
  display: block;
  margin-bottom: 7px;
}

.dropdownContainer {
  margin-top: 28px;
}

.messageTagContainer > :global(.dropdown),
.facebookOTNContainer > :global(.dropdown) {
  display: flex;
  width: 341px;
  height: 40px;
  background: var(--WHITE);
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  border-radius: 5px;
  margin-top: 7px;
}

.messageTagContainer > :global(.ui.dropdown > .menu),
.facebookOTNContainer > :global(.ui.dropdown > .menu) {
  margin-top: 7px !important;
}

.messageTagHint {
  padding-top: 8px;
  color: var(--GRAY-MID);
}

.recipientsTokenStatus {
  padding-top: 13px;
  display: flex;
  align-items: center;
}

.recipientsTokenStatus .success {
  color: var(--SUCCESS-GREEN);
  width: unset !important;
  height: unset !important;
  background: unset !important;
}

.recipientsTokenStatus .failed {
  color: #b01717;
}

.recipientsTokenStatus svg,
.recipientsTokenStatus img {
  margin-right: 7px;
}

.successIcon {
  --color: var(--SUCCESS-GREEN);
}
