.main {
  align-items: center !important;
}
.box {
  width: 752px;
  height: 478px;
  background: var(--WHITE);
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  border-radius: 8px;
  padding: 64px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.container header {
  display: flex;
  padding-bottom: 16px;
}

.container header img:nth-child(1),
.container header img:nth-child(3) {
  width: 72px;
  height: 72px;
}

.container header img:nth-child(2) {
  padding: 0 8px;
  width: 40px;
}

.title {
  color: var(--BLACK);
  font-weight: 500;
  font-size: 28px;
  padding-top: 16px;
  padding-bottom: 4px;
}

.subTitle {
  color: var(--BLACK);
  padding-top: 4px;
  padding-bottom: 30px;
}

.description {
  color: var(--BLACK);
  padding-bottom: 32px;
}

.container button {
  padding: 12px 32px !important;
  cursor: pointer;
}
