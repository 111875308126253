.root {
  display: grid;
  grid-template-columns: 3fr 6fr 7fr;
  grid-template-rows: min-content min-content min-content;
  grid-template-areas: "product product description" "quantity amount description" ". footer footer";
  grid-gap: 0 22px;
  padding: 22px 24px 24px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
}

.root.uploadable {
  grid-template-columns: 120px 180px 120px 1fr;
  grid-template-areas:
    "upload product product description" "upload amount quantity description"
    ". footer footer footer";
}

.upload {
  position: relative;
  height: 120px;
  overflow: hidden;
  border-radius: 8px;
}

.upload .image {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.upload .close {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 15px;
  height: 15px;
  background: url(./assets/close-img.svg) 0 0 no-repeat;
  cursor: pointer;
  opacity: 0.6;
}

.upload .close:hover {
  opacity: 1;
}

.upload .uploadInput {
  width: 120px;
  height: 120px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background: url(./assets/upload-border.svg) 0 0 no-repeat;
  font-size: 12px;
  color: var(--GRAY-MID);
  text-align: center;
}

.upload .uploadInput.drag .uploadIcon {
  transform: translateY(-3px);
}

.upload .uploadIcon {
  transition: transform 100ms ease-out;
  display: inline-block;
  width: 14px;
  height: 16px;
  background: url("./assets/upload-icon.svg") 0 0 no-repeat;
  margin-bottom: 8px;
}

.product {
  grid-area: product;
}

.product textarea {
  height: auto !important;
}

.quantity {
  grid-area: quantity;
}

.quantity .inputs {
  display: flex;
  flex-flow: row nowrap;
}

.amount {
  grid-area: amount;
}

.footer {
  grid-area: footer;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.errors {
  flex: 1 0;
}

.description {
  grid-area: description;
  display: flex;
  flex-flow: column nowrap;
}

.description .label {
  flex: 0 0 auto;
}

.description textarea {
  flex: 1 0 auto;
}

.buttons {
  margin-left: auto;
  flex: 0 1;
  padding-top: 7px;
}

.remove {
  color: #ee5e74;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
}

.remove .icon {
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
}

.remove.disabled {
  cursor: default;
  opacity: 0.6;
}

.label {
  font-weight: 500;
  margin: 0 0 8px;
}

.root textarea {
  resize: none !important;
  min-height: 0 !important;
  max-height: none !important;
}

.error {
  margin-top: -2px;
}
