.container {
  background-color: #f6f5f5;
  border-radius: 10px;
}
.title {
  color: rgba(60, 66, 87, 0.8);
  line-height: 1.6;
}
.replyText {
  color: #3c4257;
  line-height: 1.6;
  padding-top: 21px;
  padding-bottom: 14px;
  border-bottom: 1px solid var(--DISABLED-BORDER-COLOR);
}

.link {
  padding-top: 14px;
}
.link a {
  font-weight: 500;
  color: #6078ff;
}
.igStoryLink a:hover {
  color: #4d67f9;
  cursor: pointer;
}
