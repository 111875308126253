.horizontal.actions {
  flex-flow: row nowrap !important;
  align-items: center;
}

.horizontal.actions .button {
  margin: 0 7px 0 0 !important;
  width: 98px !important;
}
.centered .title {
  text-align: center;
}
.centered .horizontal.actions {
  justify-content: center !important;
}
.centered .horizontal.actions :global(.ui.button) {
  padding: 8px 30px;
}
