.label {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
  line-height: 1;
  border-radius: 5px;
  white-space: nowrap;
}

.blue {
  color: #5ebada;
  background-color: #e9f1f8;
}

.red {
  color: #982c50;
  background-color: #f8e3de;
}

.yellow {
  color: #9a4f21;
  background-color: #f3e6be;
}

.green {
  color: #2c6047;
  background-color: #d3f2cd;
}
