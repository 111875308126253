.crossIcon {
  --image: url(../../../../../assets/images/cross.svg);
  --width: 18px;
  --height: 18px;
  cursor: pointer;
}

.arrowIcon {
  --image: url(../../assets/arrow-right-double.svg);
  --width: 28px;
  --height: 20px;
}

.tableHeaderCell {
  border-bottom: none !important;
}

.table:global(.ui.table) {
  border: none;
}

.table:global(.ui.table th) {
  background-color: transparent;
}
