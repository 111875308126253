.item {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 14px;
  margin-bottom: 14px;
}

.item.last {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.line {
  display: flex;
  justify-content: space-between;
  margin: 0 0 3px;
  font-size: 12px;
  flex-flow: row nowrap;
  word-break: break-all;
}

.line .label {
  color: var(--GRAY-MID);
}

.line .value {
  text-align: right;
}
