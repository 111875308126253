.children {
  display: flex;
  flex-direction: column;
  padding: calc(58px - 21px) 10px 21px 0;
}

.children span:first-child {
  font-size: 28px;
  margin-bottom: 14px;
}

.children span:last-child {
  font-size: 14px;
}

.form {
  margin-top: 21px;
  margin-bottom: 8px;
}

.upload :global(.upload-dropzone-input__body) {
  display: flex;
  flex-direction: column;
}

.upload :global(.upload-dropzone-input__description) {
  color: var(--GRAY-MID);
}

.uploadTitle {
  margin-top: 7px;
}

.uploadTitle div:first-child {
  font-size: 14;
  font-weight: 500;
  margin-bottom: 7px;
}

.uploadTitle div:last-child {
  font-size: 13px;
}

.submitButton {
  width: 100%;
  height: 35px !important;
  min-height: 35px !important;
  display: flex;
  justify-content: center;
}

.animationContainer {
  flex: 0 0 100%;
  padding: 0 32px 32px;
  transition: transform 500ms cubic-bezier(0.2, 0.68, 0.35, 0.99);
}

.animationContainerMain {
  transform: translateX(0px);
  opacity: 0;
}

.animationContainerNew {
  transform: translateX(-477px);
  opacity: 1;
}
