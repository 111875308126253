.root {
  --aside: 345px;
}

@media all and (max-width: 1000px) {
  .root {
    --aside: 200px;
  }
}

.languagesGrid {
  display: grid;
  grid-template-columns: 1fr var(--aside);
  column-gap: 29px;
}

.preview :global(.ui.dropdown) {
  margin: 0 0 16px;
}

.preview .label {
  margin-bottom: 7px !important;
  display: block;
}

.messages {
}
