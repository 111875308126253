.container {
  display: flex;
  justify-content: space-between;
  padding: 28px 194px 28px 35px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.qrCodeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrCodeContainer img {
  width: 239px;
  height: 239px;
}

.title {
  font-weight: 500;
  padding-bottom: 7px;
}

.description {
  font-weight: 400;
  font-size: 13px;
  color: var(--GRAY-MID);
  padding-bottom: 21px;
}

.msgContainer {
  background: rgba(234, 234, 234, 0.4);
  border: 1px solid #d8d8d8;
  border-radius: var(--BORDER-RADIUS-S);
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  width: fit-content;
}

.msgContainer span {
  align-self: center;
}

.msgContainer :global(.input) {
  display: flex;
  align-items: center;
  width: 113px;
  margin-left: 7px;
}

.msgContainer :global(.input input) {
  height: 17px;
  padding: 7px;
  line-height: 16.71px;
  min-height: 31px;
}

.channelContainer {
  display: flex;
  margin: 17.5px;
}

.channelContainer img {
  margin-right: 7px;
  width: 20px !important;
  height: 20px !important;
  align-items: center;
}

.download {
  padding: 10px 75px !important;
}

.downloadIcon {
  --color: var(--WHITE);
  --width: 14px;
  --height: 17px;
  margin-right: 7px;
}
