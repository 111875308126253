.container {
  overflow-y: auto;
}

.tabs:global(.ui.ui.ui.ui) {
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 21px;
  border-bottom: 1px solid #dcdbe1;
  padding: 0 30px 21px;
}

.tab:global(.ui.segment > div) {
  flex: 1;
  padding: 0;
  min-width: auto;
}

.sectionWrapper {
  max-width: 800px;
  padding: 32px 0;
  display: flex;
  border-bottom: 1px solid #dcdbe1;
  align-items: center;
}

.sectionWrapper:first-of-type {
  padding-top: 9px;
}

.sectionWrapper:last-of-type {
  border-bottom: none;
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}

.description {
  color: var(--GRAY-MID);
}

.mapUserTable {
  margin: 32px 0 24px;
}

.buttonWrapper {
  margin-left: 40px;
}

.wrapper {
  width: 960px;
  margin: 0 auto;
}

.backWrapper {
  margin: 0 20px;
}
