.reminder {
  padding-top: 8px;
}
.subHeader {
  font-size: 13px;
  color: var(--GRAY-MID);
}
.content {
  padding-top: 0px !important;
}
.link {
  font-weight: 500;
  color: var(--PRIMARY-BLUE);
}
.link:hover {
  cursor: pointer;
  text-decoration: underline;
}
.sampleHeader {
  padding-bottom: 0px;
}
.sampleHeader :global(.close-button) {
  margin-left: auto;
}
.description {
  padding-bottom: 21px;
}
.sampleContent {
  display: flex;
  flex-direction: column;
}
.sampleContent > :global(.dimmable > span) {
  height: 280px;
}
.action {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: auto;
}
.dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 21px;
}
.dropdown :global(.ui.dropdown) {
  border: 1px solid #eaeaea !important;
  margin-left: 8px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
}
.headerContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.copyButton {
  padding: 8px 15px !important;
  margin-bottom: 21px !important;
  width: 140px;
}
.copyButton svg {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}
