.ui.checkbox {
  background: #000;
}

.content:global(.main-content) {
  margin-bottom: 54px;
}

body {
  background-color: #f9fbff;
}

.descWrapper {
  margin-left: 12px;
  margin-top: 52px;
  width: 360px;
  margin-right: 30px;
}

.descWrapper .title {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 8px;
}

.backBtn {
  margin-top: 170px;
}

.card {
  box-shadow: 0px 10px 32px rgba(0, 102, 255, 0.08);
  border-radius: 16px !important;
  width: 469px;
  padding: 40px 35px;
  margin-bottom: 60px;
}

.form label {
  display: inline-block;
}

.form > :global(.field) {
  margin-bottom: 10px;
}

.form :global(.field.error) {
  margin-bottom: 0;
}

.form .fieldError {
  padding: 0;
}

.form .fieldError::before {
  display: none;
}

.nameRow {
  display: flex;
  margin-bottom: 10px;
}

.nameRow > div {
  flex: 1;
  padding: 0 8px;
}

.nameRow > div.first {
  padding-left: 0;
}

.nameRow > div.last {
  padding-right: 0;
}

.btnBlock {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.nextStepBtn {
  padding: 12px 32px;
  margin-top: 32px;
  width: 100%;
}

.extraLabel {
  color: var(--GRAY-MID);
  font-weight: 500;
  margin-left: 8px;
}

.footerError {
  text-align: center;
}

:global(.ui.form .field .ui.input input) {
  width: 100%;
}

@media (max-width: 960px) {
  .card {
    width: 90vw;
    padding: 40px 16px;
  }

  .nameRow {
    display: block;
  }

  .nameRow > div {
    padding: 0;
  }
}
.hidden {
  display: none;
}
