.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: #3c4257;
  color: #fff;
  font-size: 12px;
  margin-left: 13px;
  margin-bottom: 5px;
  padding: 0 10px;
}

.container:global(.large) {
  font-size: 12px;
  font-weight: 500;
  padding: 2px 10px;
}

.container.noMargins {
  margin-left: 0;
  margin-bottom: 0;
}

.container.compact {
  flex: 0 1 auto;
  display: inline-flex;
  font-size: 10px;
  min-height: 22px;
  align-items: center;
}
