.recommendedTag {
  background-color: #d3f2cd;
  color: #2c6047;
  font-weight: 500;
}

.autoTopUpSwitchWrapper {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.autoTopUpSwitch {
  margin-right: 8px;
}
.autoTopUpSwitchLabel {
  margin-bottom: 0 !important;
}

.autoTopUpSwitchOn {
  color: #45c565 !important;
}

.minimumBalanceWrapper {
  margin-top: 16px;
  margin-bottom: 24px;
}

.minimumBalanceTitle {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 8px;
}

.autoTopUpSaveBtn {
  --bg-color: #e7eaff;
  --border-color: #e7eaff;
  --text-color: var(--PRIMARY-BLUE);
  --border-hover-color: #e7eaff;
}

.autoTopUpModal {
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.autoTopUpModal .header {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}

.autoTopUpModal .subHeader {
  color: var(--GRAY-MID);
  margin-bottom: 24px;
}

.autoTopUpModal .cancelBtn {
  --bg-color: #e7eaff;
  --border-color: #e7eaff;
  --text-color: var(--PRIMARY-BLUE);
  --border-hover-color: #e7eaff;
  margin-right: 16px;
}
