.container {
  padding: calc(21px / 2) 0;
}

.title {
  font-weight: bold;
  padding-bottom: 3.5px;
}

.subTitle {
  font-size: 13px;
  color: var(--GRAY-MID);
  padding-top: 3.5px;
  padding-bottom: 7px;
}

.checkboxWrap {
  padding-top: calc(21px / 2);
  padding-bottom: 7px;
}

.checkboxWrap :global(.ui.checkbox) {
  padding-bottom: 7px;
}

.checkboxSubTitle {
  padding-left: calc(69px - 30px);
  font-size: 13px;
  color: var(--GRAY-MID);
  width: 598px;
}
