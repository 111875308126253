.mediaMessage :global(.upload-dropzone-input__description) {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mediaMessage :global(.upload-dropzone-input__body.empty) {
  border: 1px dashed var(--PRIMARY-BLUE) !important;
  background-color: #f7f8fc !important;
}

.fileLimit {
  color: var(--GRAY-MID);
  font-size: 12px;
  line-height: 14.32px;
  font-weight: 400;
}
.uploadErrorMsg {
  display: flex;
  color: var(--WARNING-TEXT);
  font-size: 12px;
  font-weight: 500;
}

.warningIcon {
  --image: url("../../../../../assets/images/icons/warning_circle.svg");
  --width: 13px;
  --height: 13px;
  margin-right: 7px;
}
