.root {
  display: grid;
  grid-template-rows: min-content minmax(0, auto) min-content;
  grid-template-columns: 100%;
  grid-template-areas: "header" "table" "footer";
  width: 100%;
  height: 100%;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  background-color: #fff;
}
.root :global(.ui.table) {
  margin-top: 0;
}

.header {
  grid-area: header;
}

.title {
  display: flex;
  align-items: center;
  padding: 17px 24px 19px;
  border-bottom: 1px solid #d8d8d8;
}

.title .text {
  font-size: 16px;
  font-weight: 500;
}

.title .logo {
  margin-left: 12px;
  display: inline-flex;
  align-items: center;
  align-self: center;
}

.filter {
  padding: 24px 25px 28px;
}

.table {
  grid-area: table;
  overflow-y: auto;
  position: relative;
}

.table::-webkit-scrollbar {
  display: none;
}

.user {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  cursor: pointer;
}
.avatar {
  display: inline-block;
  margin-right: 7px;
}

.footer {
  grid-area: footer;
  justify-self: center;
  padding: 7px 0;
}

.sticky {
  position: sticky;
  top: 0;
}

.disabled {
  opacity: 0.6;
  cursor: wait;
}
