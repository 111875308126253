.starWrap {
  display: inline-block;
}

.star {
  width: 18px; /*for you, Chrome*/
  --size: 18px;
  --color: var(--DISABLED-BORDER-COLOR);
}

.star:hover {
  --color: var(--PRIMARY-BLUE);
}

.star.solid {
  width: 17px; /*for you, Chrome*/
  --size: 17px;
  --color: var(--PRIMARY-BLUE);
}

.cellWrap :global(.loader) {
  position: relative;
  top: 0;
  left: 0;
  transform: none;
}
