.root {
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  padding: 50px;
  width: 700px !important;
}

.modal:global(.ui.modal) {
  width: 700px;
  border-radius: 16px;
}

.mainHeader {
  margin: 0 0 14px;
  font-size: 20px;
  font-weight: 500;
}

p.text {
  margin-bottom: 24px;
}

.code {
  margin: 0 0 21px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.code .preview {
  max-width: min-content;
  padding: 0 50px 35px;
  display: flex;
  flex-flow: column;
  align-items: center;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  border-radius: 5px;
  margin: 21px 0;
}

.code .preview .icon {
  margin: -27px 0 10px;
  width: 54px;
  height: 54px;
}

.code .preview .icon img {
  width: 54px;
  height: 54px;
}

.code .preview .title {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 7px;
}

.code .preview .account {
  margin: 0 0 7px;
  color: var(--GRAY-MID);
}

.code .preview .qrImage {
  width: 170px;
  height: 170px;
  user-select: none;
}
.code .preview .qrImage img {
  max-width: 100%;
}

.checkboxWrap {
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  border-radius: 5px;
  display: inline-block;
  padding: 21px 37px;
  white-space: nowrap;
  margin-bottom: 21px;
}

.actions {
  display: flex;
  justify-content: center;
}
