.container {
  overflow-y: auto;
}

.tabs:global(.ui.ui.ui.ui) {
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 21px;
  border-bottom: 1px solid #dcdbe1;
  padding: 0 30px 21px;
}

.tab:global(.ui.segment > div) {
  flex: 1;
  padding: 0;
  min-width: auto;
}

.tabContainer {
  padding-top: 12px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}

.description {
  color: var(--GRAY-MID);
}

.mapUserTable {
  margin: 32px 0 24px;
}

.buttonWrapper {
  margin-top: 21px;
}

.dividerWrapper {
  margin: 32px 30px;
}

.wrapper {
  width: 960px;
  margin: 0 auto;
}

.backWrapper {
  margin: 0 20px;
}
