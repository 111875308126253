.quote {
  max-width: calc(100% - 30px);
  flex: auto;
}
.quote .text.content {
  --BACKGROUND-COLOR: transparent;
  padding: 0px;
}

.quote.shrinkable {
  flex: 1 1 auto;
  margin-right: 10px;
}

.author {
  color: var(--PRIMARY-BLUE);
  font-weight: 500;
}

.content {
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  overflow: hidden;
  border-top-right-radius: 7.5px;
  border-bottom-right-radius: 7.5px;
}

.video.content {
  background: var(--BACKGROUND-COLOR, #fff);
}

.video.content video {
  height: 47px;
  width: 47px;
}

.image.content img {
  object-fit: contain;
  flex: 0 0 45px;
  height: 30px;
}

.text.content {
  display: block;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  background: var(--BACKGROUND-COLOR, #fff);
  overflow: hidden;
  padding: 13px 8px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  min-width: fit-content;
}

.audio.content:before {
  content: url("../../../assets/images/chat/icon-mic.svg");
  width: 12px;
  height: 14px;
  margin-right: 10px;
  display: inline-block;
}
