.intervalInput {
  display: flex;
}

.intervalInput .column {
  flex: 1 1 50%;
  margin-right: 2rem;
}

.intervalInput .column:last-child {
  margin-right: 0;
}

.periodInput:global(.column.four.four.four) {
  width: 23% !important;
}
.periodInput :global(.column.last) {
  padding-left: 0;
  padding-right: 20px;
}
