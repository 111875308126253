.stripMessage {
  margin: 0 -30px;
  background-color: #eff1ff;
  border-left: 8px solid #6078ff;
  padding: 20px;
}

.stripMessage:global(.marginLeft) {
  margin: 0px;
  height: auto;
}

.stripMessage:global(.template-selected-params) {
  height: 190px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  text-transform: capitalize;
}

.header:after {
  width: 22px;
  height: 24px;
  display: inline-block;
  flex: 0 0 22px;
  margin-left: 2px;
  content: url("./assets/bulb-outline.svg");
  position: relative;
  top: 2px;
}

.content :global(.content .paragraph:first-child) {
  padding-top: 3px;
}

.content :global(.content .paragraph > .link) {
  color: #6078ff;
  cursor: pointer;
  font-weight: 500;
}

.content :global(.content .paragraph > .link:hover) {
  text-decoration: underline;
}

.content :global(.content .paragraph .bold) {
  font-weight: 500;
  padding-right: 5px;
}

.noHorizontalOutset {
  margin-left: 0;
  margin-right: 0;
}
