.container {
  padding: 16px 16px 16px 20px;
  width: 745px;
  margin-left: 30px;
  border-radius: 4px;
  border-left: 4px solid var(--PRIMARY-BLUE);
  background-color: rgba(96, 120, 255, 0.1);
  display: grid;
  grid-template-areas:
    "title button"
    "content button";
  align-items: center;
}
.container .title {
  grid-area: title;
}
.container .content {
  grid-area: content;
  color: #6a6e81;
  font-size: 12px;
}
.container .button {
  grid-area: button;
}
