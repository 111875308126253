.form :global(.field) {
  max-width: 306px !important;
}

.form {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}
.form .field {
  align-self: center;
}

.field :global(.checkbox) label {
  padding-left: 26px !important;
}
