.header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.icon {
  width: 56px;
  margin-right: 20px;
}

.title {
  font-size: 20px !important;
  margin: 0;
}
