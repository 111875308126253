.progressBar {
  width: 100%;
  background-color: var(--bg-color, var(--DISABLED-BORDER-COLOR));
  height: 15px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}

.bar {
  height: 100%;
  display: block;
  width: 0;
  line-height: 15px;
  position: absolute;
  text-align: end;
  border-radius: 15px;
  background-color: var(--progress-color, var(--PRIMARY-BLUE));
}
