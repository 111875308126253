.modal :global(.header) {
  font-size: 20px !important;
  font-weight: 500 !important;
  border-bottom: 1px solid var(--DISABLED-BORDER-COLOR) !important;
}

.description {
  padding-top: 12px;
  padding-bottom: 24px;
}

.dateRange {
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
}

.dateRange :global(.dateRangePicker) {
  margin-left: 0 !important;
}

.modal label {
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 8px;
}

.email {
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
}

.submittedContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.submittedContent img {
  width: 64px;
  height: 64px;
  padding-bottom: 8px;
}

.submittedContent .title {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 8px;
}
