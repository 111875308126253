.planSubscriptionContainer {
  overflow: auto;
  border-radius: 10px;
}

.planSubscriptionContainerWhite {
  background-color: var(--WHITE);
}

.planSubscriptionContent {
  border-radius: 10px;
  background-color: var(--WHITE);
}
