.container {
  background-color: #fafafe;
}
.main {
  flex-direction: column !important;
  justify-content: unset !important;
  margin: 12px 100px 67px 76px !important;
  overflow: auto !important;
}

.header {
  display: flex;
  flex-direction: column;
  margin: 56px auto;
  text-align: center;
}

.header :global(.title) {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 8px;
}

.header :global(.description) {
  margin-top: 8px;
}

.content {
  display: flex;
  justify-content: space-around;
}

.content article {
  flex: 0 1 50%;
  margin-right: 69px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.content section {
  flex: 0 0 50%;
}

.content article :global(.title) {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 12px;
}

.content article :global(.subtitle) {
  margin: 12px 0;
}

.content article ul {
  margin-block-start: unset !important;
  margin-block-end: unset !important;
  padding-inline-start: unset !important;
}

.content article ul li {
  margin: 8px 0;
  display: flex;
  align-items: flex-start;
}

.content article ul li img {
  width: 21px !important;
  height: 20px !important;
  margin-right: 13px;
}

.content article a,
.content a:link,
.content a:visited {
  color: var(--PRIMARY-BLUE);
}

.content article a:hover {
  text-decoration: none;
  color: var(--PRIMARY-BLUE);
}

.content section {
  padding: 48px;
  background-color: var(--WHITE);
  border-radius: 8px;
}

.totalAmount {
  font-weight: 500;
  color: var(--GRAY-MID);
  margin-bottom: 32px;
}

.originalPrice {
  color: var(--BLACK);
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 8px;
  text-decoration: line-through;
}

.trialPrice {
  color: var(--PRIMARY-BLUE);
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 32px;
}

.firstMonth {
  color: #4d4d4f;
  font-weight: 500;
  font-size: 16;
  margin-bottom: 16px;
}

.trialNotice {
  color: #4d4d4f;
  margin: 16px 0;
}

.content section .footer {
  display: flex;
  flex-direction: column;
}

.buttonWrapper {
  margin-top: 16px;
}

.buttonWrapper button {
  padding: 12px 32px !important;
}

.content section .footer:global(.revert) .buttonWrapper {
  order: 1;
  margin-bottom: 16px;
}

.content section .footer:global(.revert) .trialNotice {
  order: 2;
  margin-bottom: 0 !important;
}
