.canvas {
  max-width: 1130px;
  flex: 1 0 auto;
}

.header {
  margin: 0 0 24px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 24px;
}

.header .text {
  flex: 1 0 auto;
}

.header .title {
  margin: 0 0 4px;
  font-size: 20px;
  font-weight: 500;
}

.header .subtitle {
  color: var(--DISABLED-FONT-COLOR);
}

.container {
  border-radius: 10px;
  padding: 32px;
  background-color: #fff;
  margin: 0 0 32px;
}

.container:last-of-type {
  margin-bottom: 0;
}

.actions {
  margin-right: auto;
  flex: 0 0 auto;
}
