.dummy {
  display: flex;
  align-items: center;
  height: 43px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding: 0 15px;
  font-weight: 500;
}

.dummy.compact {
  padding: 0 7px;
}
.dummy.readonly {
  border: 1px solid #eaeaea !important;
  background-color: #eaeaea !important;
  color: #9fa2ac;
}
.dummy.fluid {
  flex-grow: 1;
  flex-basis: 100%;
  width: 100%;
}
