.modal {
  width: 1080px !important;
  height: 80vh;
}

.modal :global(.close.icon) {
  font-size: 16px;
  top: 0px;
}

.modal :global(.close.icon:before) {
  content: "\f00d";
}

.templates {
  width: auto;
  height: 100%;
  border-radius: 16px !important;
  overflow: hidden auto;
}

.templates.empty {
  /*height: 215px;*/
}

.overlayWrap {
  background-color: #ffffff;
  overflow: hidden auto;
  padding: 41px 56px;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: stretch;
  height: 100%;
}
