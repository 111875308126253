.filterWrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  margin-bottom: 14px;
}

.filterWrap .filterSection {
  flex: 1 1 auto;
  margin-right: 40px;
}

.filterWrap .actions {
  flex: 0 1 auto;
  margin-left: auto;
}

.filterWrap .actions :global(.button) {
  margin-left: 7px;
}

.actions {
  flex: 0 1 auto;
  margin-left: auto;
}

.actions :global(.button) {
  margin-left: 7px;
}

.withNavTop:global(.hide-scrollable-table) {
  height: calc(100% - 182px) !important;
}
