.headerFile {
  padding-top: 23px;
}
.header {
  font-weight: 500;
  padding-bottom: 14px;
  text-transform: capitalize;
}
.headerFile :global(.image-item) {
  margin-top: 14px;
}
.headerFile :global(.upload-dropzone-input__body) {
  min-height: 67px !important;
}
