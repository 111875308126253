.contentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: unset !important;
}

.description {
  padding-bottom: 10.5px;
}

.actionContainer {
  display: inline-flex;
  align-items: center;
  color: var(--PRIMARY-BLUE);
  font-weight: bold;
  cursor: pointer;
}

.redirectIcon {
  margin-left: 7px;
}

.redirectIcon g {
  fill: var(--PRIMARY-BLUE);
}

.contentContainer :global(.preview) {
  flex: unset;
  padding-right: 55px;
  padding-top: 38px;
}

.contentContainer :global(.content) {
  width: 239px;
}

.contentContainer :global(.ui.image) {
  width: 239px;
  height: 485px;
}

.contentContainer :global(.lang-content::after) {
  color: var(--GRAY-MID);
}
