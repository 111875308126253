.modal {
  width: 533px;
}
.header:global(.header.header) {
  background-color: #f6f5f5;
  font-size: 20px !important;
  display: flex;
  flex-flow: row nowrap;
  place-content: center center;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #d8d8d8;
  padding: 21px;
}

.content:global(.content.content) {
  padding: 21px 42px;
  display: flex;
  place-content: center center;
  text-align: center;
}

.actions:global(.actions.actions) {
  border-top: 1px solid #d8d8d8;
  padding: 14px 0;
  display: flex;
  place-content: center center;
}

.dangerButton {
  --text-color: #fff;
  --bg-color: #f25e75;
  --bg-hover-color: #de5065;
}

.close {
  right: 0;
  top: 0;
  margin: 0;
}
