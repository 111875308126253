.optionsWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
}

.optionsWrapper :first-child {
  align-items: center;
}

.expirationOption {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.expirationOption :global(.ui.checkbox.radio) {
  min-height: 20px !important;
}

.expirationOption :global(.ui.checkbox.radio input ~ label::before) {
  border-radius: 100% !important;
}

.expirationOption :global(.ui.checkbox.radio input:checked ~ label::before) {
  border-radius: 100% !important;
}

.expirationOption :global(.ui.checkbox.radio input:checked ~ label::after) {
  color: #fff;
  content: "" !important;
  font-size: 14px;
  height: 8px;
  width: 8px;
  top: 7px;
  left: 6px;
  background-color: var(--WHITE) !important;
}

.expireDateWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.expireDateWrapper input {
  max-width: 70px;
}
