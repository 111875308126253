.overlay {
  background: var(--GRAY-SHADOW);
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}
.or {
  color: var(--GRAY-MID);
  margin: 5px 0;
}
.trigger {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 14px;
  color: var(--PRIMARY-BLUE);
  --color: var(--PRIMARY-BLUE);
  font-weight: 500;
  cursor: pointer;
}
.trigger:hover {
  text-decoration: underline;
  color: var(--LINK-HOVER);
}
