.container {
  display: flex;
}

.label {
  margin: 0 7px 7px 0;
  display: inline-block;
}

.counterLabel {
  font-weight: 500;
  border: none !important;
  border-radius: 16px;
  box-shadow: none !important;
  text-decoration: none;
  white-space: nowrap;

  flex: 0 0 auto;
  background-color: var(--GRAY-LIGHT);
  color: var(--BLACK);
  min-height: 31px;
  min-width: 31px;
  font-size: 13px;
  line-height: 1.2;
  overflow: visible;
  text-overflow: unset;

  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.popupWrap {
  border: none !important;
  border-radius: 10px;
}
.popupWrap:before {
  /*border-top: 1px solid rgba(200,200,200,.3);*/
  /*border-left: 1px solid rgba(200,200,200,.3);*/
  box-shadow: -1px -1px 0 rgba(200, 200, 200, 0.1) !important;
  /*box-shadow: none!important;*/
}
.popup {
  padding: 4px 11px 0;
  margin-bottom: -3px;
}

.counterLabel :global(.close.icon),
.counterLabel :global(.delete.icon) {
  align-self: center;
}

.chatCounterLabel {
  width: 20px;
  composes: counterLabel;
  margin: 1px 0 0 auto !important;
}
