.root {
}

.labelSection {
  display: flex;
  align-items: center;
}

.labelSection .item {
  flex: 0 1 auto;
  margin: 0 0 0 30px;
  display: flex;
  align-items: center;
}

.labelSection .item:first-child {
  margin-left: 0;
}

.labelSection .item.long {
  flex: 0 0 390px;
}

.labelSection .item.fluid {
  flex: 1 0 auto;
}

.labelSection .item.floating {
  margin-left: auto;
  flex: 0 1 auto;
}

.labelSection label {
  margin: 0;
  padding-bottom: 0 !important;
}

.labelSection :global(.ui.input.fluid) {
  width: 100%;
}

.upload {
  margin-top: 11px;
}

.upload :global(.upload-dropzone-input__body) {
  display: block;
  min-height: 200px;
}

.upload :global(.upload-dropzone-input__body.empty) {
  display: flex;
}

.upload :global(.upload-dropzone-input__contents) {
  flex-flow: row wrap;
}

.upload :global(.image-item) {
  width: 130px;
  height: 130px;
  flex-basis: 130px;
  margin-bottom: 30px;
}

.upload :global(.image-item img) {
  object-fit: cover;
  width: 130px;
}
.upload :global(.image-item.file) {
}
