.label {
  display: inline-block;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 10px;
  font-weight: 500;
}

.label.yellow {
  background-color: #f3e6be;
  color: #9a4f21;
}

.label.cyan {
  background-color: #e9f1f8;
  color: #5ebada;
}

.label.blue {
  background-color: #dceafd;
  color: #6b73bb;
}

.label.green {
  background-color: #d3f2cd;
  color: #2c6047;
}

.label.red {
  background-color: #f8e3de;
  color: #982c50;
}

.small {
  text-align: center;
  border-radius: 5px;
  font-size: 10px;
  margin-right: 10px;
  cursor: pointer;
  display: inline-flex;
  min-height: 22px;
  line-height: 1.1;
  align-items: center;
  padding: 0 10px;
}

.popup {
}

.head {
  margin: 0 0 7px;
}

.popup ul {
  padding: 0 0 0 21px;
  margin: 0 0 7px;
}

.popup .item {
}
