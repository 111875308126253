.title {
  font-size: 20px;
  font-weight: 500;
  margin-top: 32px;
}

.desc {
  text-align: center;
  margin: 12px 0 0;
}

:global(.ui.button).prevPageBtn {
  margin-top: 46px;
  justify-content: center;
}

.footer {
  margin-top: 120px;
  display: flex;
}

@media screen and (max-width: 500px) {
  :global(.ui.button).prevPageBtn {
    margin-top: 100px;
  }

  .footer {
    margin-top: 18px;
  }
}
