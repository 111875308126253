.container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 2555, 255, 0.9);
  z-index: 9999;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content {
  font-weight: 500;
  color: var(--BLACK);
}
.action {
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 0;
}

.action :global(.button) {
  padding: 8px 15px;
  min-height: 33px;
}
.action .link {
  margin-top: 8px;
  font-weight: 500;
  color: var(--PRIMARY-BLUE);
}
.action .link:hover {
  color: #4d67f9;
  cursor: pointer;
}
