.modal {
  width: 800px !important;
  padding: 40px 57px 49px 56px !important;
}

.title {
  font-weight: 500;
  font-size: 20px;
  padding-bottom: 24px;
  color: var(--GRAY-DARK);
}

.description {
  color: rgba(60, 66, 87, 0.8);
  padding-bottom: 24px;
}

.stepContainer {
  padding-bottom: 24px;
}

.subTitle {
  font-weight: 700;
  padding-bottom: 8px;
}

.request {
  padding-top: 8px;
  padding-bottom: 12px;
}

.preview {
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: center;
}

.ready {
  padding-top: 12px;
  padding-bottom: 16px;
}

.modal .content .stepContainer .readyButton {
  padding: 12px 32px !important;
}
