.fieldError {
  font-size: 14px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 1.6em;
  color: var(--FIELD-ERROR-RED);
  padding: 0 0 0 19px;
  position: relative;
  visibility: hidden;
  text-align: left;
  opacity: 0;
  transition: all 0.5s;
}

.fieldError.below {
  margin-top: 7px;
}

.fieldError.above {
  margin-bottom: 7px;
}

.fieldError:global(.visible) {
  visibility: visible;
  height: 1.6em;
  opacity: 1;
}

.fieldError:global(.visible:before) {
  display: inline-block;
  content: url("../../../assets/images/icons/warning.svg") !important;
  width: 15px;
  height: 15px;
  position: absolute;
  left: 0;
  top: 2px;
}

.fieldError > div {
  flex: 1 0 auto;
  margin-right: 8px;
}

.hasError,
.hasError:global(.ui.ui.ui),
:global(.ui.input.ui.ui) .hasError {
  box-shadow: 0 0 1px 3px #ffd7dd !important;
  border: 1px solid var(--FIELD-ERROR-RED) !important;
}

.standalone.fieldError:global(.visible) {
  margin-top: 0;
  margin-bottom: 1em;
}
