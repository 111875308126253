.searchInputWrap {
  display: grid !important;
  grid-template-areas: "text";
  margin-bottom: 21px;
}

.searchInputWrap:global(.ui.ui) input {
  grid-area: text;
  padding-left: 38px !important;
  padding-right: 33px !important;
  justify-self: stretch;
  overflow: hidden;
}

.searchInputWrap.noMargins {
  margin-bottom: 0 !important;
}
.searchInputWrap.noSearchIcon:global(.ui.ui) input {
  padding-left: 14px !important;
}

.searchIconWrap {
  grid-area: text;
  align-self: center;
  justify-self: start;
  width: 18px;
  height: 18px;
  transform: translateX(10px);
}

.searchIconWrap svg {
  width: 100%;
  height: 100%;
}

.close {
  grid-area: text;
  align-self: center;
  justify-self: end;
  transform: translateX(-10px);
}

.loader {
  grid-area: text;
  align-self: center;
  justify-self: end;
  transform: translateX(-12px) !important;
  position: relative !important;
  top: auto !important;
  left: auto !important;
}
