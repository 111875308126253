.container:global(.sub-container) {
  background: #fff;
  border-radius: 8px;
  padding: 30px;
  margin-top: 24px;
  max-width: 630px;
  width: 630px;
}
.container :global(.full-button) {
  width: 100%;
  padding: 12px !important;
  font-size: 16px !important;
  justify-content: center !important;
}

.container :global(.sub-container) {
  background: white;
  border-radius: 8px;
  padding: 30px;
  margin-top: 24px;
}

.container :global(.row) {
  margin-bottom: 21px;
}

.container :global(.header-with-img) {
  display: flex;
  align-items: center;
}

.container :global(img) {
  height: 54px;
  width: 54px;
  margin-right: 21px;
}

.container :global(.header-text) {
  display: flex;
  flex-direction: column;
}

.container :global(.link) {
  font-weight: 500;
  color: var(--PRIMARY-BLUE);
}
.container :global(.link:hover) {
  color: #4d67f9;
  cursor: pointer;
}

.container :global(.do-later-button) {
  display: flex;
  font-size: 16px;
  color: var(----BLACK);
  margin-top: 14px;
  justify-content: center;
}

.container :global(.do-later-button:hover) {
  text-decoration: underline;
  cursor: pointer;
}

.container :global(.shopify-info-row) {
  display: flex;
}

.container :global(.shopify-info-field) {
  width: 70px;
}

.container :global(.checkbox-row) {
  margin-bottom: 7px;
}

:global(.sync-shopify-container) {
  height: 100%;
  overflow-y: scroll;
  border-radius: 8px;
}
