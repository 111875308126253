.d365Icon {
  --width: 24px;
  --height: 24px;
  --image: url("../../../assets/images/channels/dynamic-365.svg");
}

.orderHistorySummary {
  display: flex;
  background-color: var(--DISABLED_FONT_BACKGROUND);
  margin-bottom: 16px;
}

.summaryBlock {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.summaryBlockBorder {
  position: relative;
}

.summaryBlockBorder::after {
  content: "";
  height: 50%;
  width: 0;
  position: absolute;
  right: 0;
  border-right: 1px solid var(--DISABLED-BORDER-COLOR);
}

.summaryLabel {
  text-align: center;
  margin-bottom: 12px;
  color: var(--GRAY-MID);
}
.summaryValue {
  font-size: 20px;
  font-weight: 600;
}

.orderHistoryItem + .orderHistoryItem {
  border-top: 1px solid var(--DISABLED-BORDER-COLOR);
  padding-top: 12px;
}

.orderHistoryItem > .itemWrapper {
  display: flex;
  justify-content: space-between;
}

.itemLabel {
  margin: 0 6px 12px 0;
  color: var(--GRAY-MID);
}

.itemValue {
  margin-bottom: 12px;
  text-align: right;
}

.hyperlink {
  font-weight: 500;
  color: #6078ff;
}

.hyperlink:hover {
  color: #4d67f9 !important;
  cursor: pointer;
}

.showAllBtn {
  padding-top: 12px;
  text-align: center;
  border-top: 1px solid var(--DISABLED-BORDER-COLOR);
}

.detailWrapper {
  display: flex;
}

.detailWrapper + .detailWrapper {
  margin-top: 12px;
}

.detailImg {
  width: 52px;
}

.detailItemWrapper {
  width: calc(100% - 52px);
}

.detailProductTitle {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.detailProductName {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 6px;
  font-size: 12px;
  padding-right: 6px;
}

.detailProductImg {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}

.detailProductSpec {
  font-size: 10px;
  color: var(--GRAY-MID);
  margin-bottom: 6px;
}

.detailProductItem {
  font-size: 10px;
  margin-bottom: 6px;
}

.pagination {
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
}

.orderDetailBackBtn {
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
