.error:global(.hidden) {
  margin-top: 0 !important;
  padding-left: 0 !important;
}
.error:global(.visible) {
  margin-top: 11px !important;
}

.addTopicDropdown :global(.field) {
  margin-bottom: 0 !important;
}

.addTopicDropdown :global(.selected.item.addition > .text > b) {
  padding-left: 5px !important;
}
