.trigger {
  --chat-attached-msg-height: 300px;
  --chat-attached-msg-width: 336px;
  --trigger-margin-spacer: 12px;
  --trigger-margin-spacer-double: 24px;
}

.trigger {
  overflow: hidden;
  display: flex;
  align-items: center;
  margin: var(--trigger-margin-spacer);
  cursor: pointer;
}

.trigger img {
  max-width: calc(
    var(--chat-attached-msg-width) - var(--trigger-margin-spacer-double)
  );
  max-height: calc(
    var(--chat-attached-msg-height) - var(--trigger-margin-spacer-double)
  );
  object-fit: contain;
  border-radius: 8px;
}

.trigger video {
  max-width: calc(
    var(--chat-attached-msg-width) - var(--trigger-margin-spacer-double)
  );
  max-height: calc(
    var(--chat-attached-msg-height) - var(--trigger-margin-spacer-double)
  );
  border-radius: 8px;
}

:global(.ui.modal).preview {
  width: 0 !important;
  box-shadow: none !important;
}

:global(.ui.modal).preview :global(.content) {
  background-color: transparent;
  display: flex;
  justify-content: center;
  padding: 0;
}

.content img {
  object-fit: scale-down;
  height: 560px;
  max-width: 80vw;
}

.content video {
  max-height: 560px;
}

.sideBar {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 150px;
}

.icon {
  width: 24px;
  height: 24px;
  margin-bottom: 26px;
  cursor: pointer;
}

.loadFailed {
  width: 200px;
  height: 50px;
  display: flex;
  color: var(--WARNING-TEXT);
  justify-content: center;
  align-items: center;
}

.loadFailed .warningIcon {
  width: 17px;
  height: 17px;
  margin-right: 12px;
}
