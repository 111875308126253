.header:global(.header.header) {
  font-size: 20px !important;
  font-weight: 500;
  padding: 32px 30px 12px;
}

.hint {
  margin: 0 0 32px;
}

.close {
  cursor: pointer;
}

.upload {
  margin: 0 0 32px;
}

.upload :global(.upload-dropzone-input) {
  padding-bottom: 0;
}

.upload :global(.splash) {
  height: 240px;
  justify-content: center;
}

.upload .description {
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload .empty {
}

.upload .filled {
  display: flex;
  align-items: center;
  gap: 24px;
}

.upload .filled .icon {
  width: 16px;
  height: 20px;
}

.upload .filled .icon img {
  max-width: 100%;
}

.upload .filled .name {
}

.resetFile {
  cursor: pointer;
  display: inline-flex;
  width: 12px;
  height: 12px;
  align-items: center;
  justify-content: center;
  margin-left: 24px;
}

.resetFile :global(._iconGlobalOverride) {
  --width: 12px;
  --height: 12px;
}

.link,
.link:visited {
  color: var(--PRIMARY-BLUE);
  cursor: pointer;
}

.confirm {
  margin: 0 0 32px;
  display: flex;
  flex-flow: row nowrap;
  gap: 21px;
}

.confirm .input {
}

.confirm .status {
  flex: 0 0 auto;
  margin-left: auto;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 18px;
}
