.triggerCover {
  background-color: rgba(0, 0, 0, 0.2);
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  position: absolute;
  background-image: url("./video-play.svg");
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  border-radius: 8px;
}
