.container:global(.google-sign-container) {
  margin: 21px 0px 14px;
}
.container {
  margin-top: 0px;
  text-align: center;
  padding: 0px 2.8em;
}
.container:global(.full-width) {
  padding: 0px;
}
.container .button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 6px 20px !important;
  background: var(--WHITE);
  cursor: pointer;
  border: 1px solid var(--GRAY-UI);
  font-weight: 500;
  color: var(--GRAY-DARK);
  border-radius: 5px;
}
.container .button:hover {
  box-shadow: 0 0 10px 3px var(--GRAY-SHADOW) !important;
}
.container .button .text.google {
  background: url("./assets/google.svg") no-repeat 7px 0;
}
.container .button .text.shopify {
  background: url("./assets/shopify.svg") no-repeat 7px 0;
}
.text {
  height: 22px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 10px;
}
.subHeader {
  color: var(--GRAY-MID);
  margin-top: 7px;
}
