.mainContent {
  display: flex;
  padding: 5px;
}

.cardWrapper {
  width: 520px;
}

.card {
  padding: 56px 32px;
  width: 100%;
}

.title {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 12px;
}

.desc {
  font-size: 16px;
  font-weight: 500;
}

.mobileWrapper {
  flex: none;
  margin-top: 60px;
  margin-left: 80px;
  width: 380px;
  position: relative;
}

.mobileImg {
  width: 100%;
  z-index: -1;
}

.pointerHand {
  position: absolute;
  bottom: 26px;
  font-size: 40px;
}

.input {
  position: absolute;
  bottom: 48px;
  left: 88px;
  width: 174px;
  font-size: 13px;
  border: none;
}

.input:focus-visible {
  outline: none;
  box-shadow: none;
}

.loadingWrapper {
  position: absolute;
  left: 70px;
  width: 240px;
  bottom: 82px;
  height: 20px;
  display: flex;
  justify-content: center;
}

.loading {
  height: 100%;
}

.chats {
  position: absolute;
  left: 70px;
  bottom: 82px;
  width: 240px;
  height: 430px;
  display: flex;
  flex-direction: column-reverse;
  padding: 0 8px;
  overflow-y: hidden;
}

.record {
  background-color: var(--WHITE);
  max-width: 200px;
  padding: 8px 12px;
  border-radius: 8px;
  margin-bottom: 8px;
  width: fit-content;
  word-break: break-word;
}

.record.notFromUser {
  align-self: flex-end;
  background-color: #e1f8cb;
}

.sidebar {
  position: relative;
}

.demoCover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 2;
}

:global(.ui.image).sendBtn {
  position: absolute;
  bottom: 47px;
  right: 79px;
  cursor: pointer;
}
