.root {
}
.message {
  margin: 0 0 12px;
}
.source {
  font-size: 12px;
  color: var(--DISABLED-FONT-COLOR, #9aa8bd);
  margin: 0 0 12px;
}
