.root {
  --aside: 345px;
}

@media all and (max-width: 1000px) {
  .root {
    --aside: 200px;
  }
}

.languagesGrid {
  display: grid;
  grid-template-columns: 1fr var(--aside);
  column-gap: 29px;
}

.preview :global(.ui.dropdown) {
  margin: 0 0 16px;
}

.preview .label {
  margin-bottom: 7px !important;
  display: block;
}

.messages {
}

.description {
  font-weight: 400;
  color: var(--GRAY-MID);
  padding-bottom: 16px;
}

.radioGroup {
  display: flex;
  align-items: center;
  padding-top: 16px;
}

.radioGroup :global(.ui.card) {
  margin: unset !important;
  width: 352px !important;
  border-radius: 5px !important;
  border-color: var(--DISABLED-BORDER-COLOR) !important;
}

.radioGroup :global(.ui.card .content) {
  padding: 27px 24px !important;
}

.radioGroup :global(.ui.card:first-child) {
  margin-right: 10px !important;
}

.radioGroup :global(.checkbox) {
  margin-bottom: 16px;
}

.radioGroup :global(.header) {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.radioGroup :global(.description) {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: var(--GRAY-DARK);
}

.gatewayCard:global(.stripeUnconnected .content) {
  display: flex;
  flex-direction: column;
  background: var(--GRAY-LIGHT);
}

.unconnected {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 10px;
  background: var(--GRAY-DARK);
  border-radius: 5px;
  color: var(--WHITE);
  align-self: flex-end;
}
