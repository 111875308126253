.helpCenter {
  width: 477px !important;
}

.helpCenter :global(.ui.menu.sidebar.drawer:first-child) {
  background-color: #fcfbfa;
  padding: 0 !important;
}

.helpCenterContainer {
  background: #3c4257;
  color: white;
  display: flex;
  width: 150px;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  position: absolute;
  top: calc(100vh - 70px);
  right: 20px;
  height: 50px;
  border-radius: 25px;
  cursor: pointer;
  z-index: 2;
  font-weight: 600;
  transition: transform 0.2s ease-in-out;
}

.helpCenterContainer > div:first-child {
  height: 24px;
  width: 24px;
  margin-right: 14px;
  margin-bottom: 4px;
}

.helpCenterContainer svg {
  stroke-width: 1px;
}

.helpCenterContainer:hover {
  transform: translate(0px, -5px);
  box-shadow: 0 0 10px 4px rgba(180, 175, 175, 0.5);
}

.animationContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  overflow: hidden;
}
