.container {
  text-align: left;
  padding: 0 24px;
}

.subHeader {
  font-size: 16px;
  padding: 12px 0;
  font-weight: 500;
}

.list {
  list-style-type: none !important;
  margin-block-start: 12px !important;
  margin-block-end: 12px !important;
  padding-inline-start: 0 !important;
}

.list li {
  display: flex;
  padding-bottom: 7px;
}
.list li:last-child {
  padding-bottom: 0;
}

.list li::before {
  content: "";
  width: 17.8px;
  height: 16px;
  background-image: url("../../../assets/images/onboarding/tick.svg");
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 6px;
}

.disableInstruction {
  padding-left: 25px;
  color: var(--GRAY-MID);
}

.disableList {
  display: flex;
  flex-direction: column;
}

.checkboxWrapper {
  margin-top: 24px;
  padding: 22px 45px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
}
