.root {
}

.label {
  font-weight: 500;
  margin: 0 0 7px;
}

.hint {
  margin: 0 0 7px;
  color: var(--DISABLED-FONT-COLOR);
}

.downloadSample {
  margin: 0 0 7px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content .empty {
  text-align: center;
  padding: 0 20px;
  max-width: 90%;
  line-height: 1.15;
}

.fileLimit {
  color: var(--GRAY-MID);
  font-size: 12px;
  line-height: 14.32px;
  font-weight: 400;
  margin: 5px 0 0;
}

.uploaded {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.uploaded img {
  margin: 0 0 14px;
  width: 16px;
}

.close {
  position: absolute;
  right: 13px;
  top: 35px;
}

.status {
}
.downloadContacts {
}
