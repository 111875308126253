.label {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 7px;
  border-radius: 5px;
  font-weight: 500;
  line-height: 1.1;
  font-size: 13px;
  color: var(--color);
  --iconColor: var(--color);
}

.label.blue {
  --color: #5ebada;
  background-color: #e9f1f8;
}

.label.darkBlue {
  --color: #676dbf;
  background-color: #d8ebff;
}

.label.red {
  --color: #982c50;
  background-color: #f8e3de;
}

.label.green {
  --color: #2c6047;
  background-color: #d3f2cd;
}

.label.yellow {
  --color: #9a4f21;
  background-color: #f3e6be;
}
.label.purple {
  --color: #b49cfd;
  background-color: #edeafc;
}

.label.gray {
  --color: #4a4a4a;
  background-color: #d8d8d8;
}

.name {
  display: inline-block;
  flex: 1 1 auto;
}

.close {
  display: inline-flex;
  padding-left: 7px;
  cursor: pointer;
  flex: 0 0 12px;
  height: 12px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  opacity: 0.6;
  transform: translateY(-1px);
}
.close:hover {
  opacity: 1;
}

.icon {
  height: 24px;
  width: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 7px 0 0;
}

.icon svg {
  width: 20px;
}
.iconBg {
  width: 20px;
}
