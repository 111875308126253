.counterLabel {
  font-weight: 500;
  border: none !important;
  border-radius: 5px;
  box-shadow: none !important;
  text-decoration: none;
  white-space: nowrap;

  padding: 3px 6px 4px;
  flex: 0 0 auto;
  background-color: #d8d8d8;
  color: #fff;
  min-height: 18px;
  font-size: 13px;
  line-height: 1.2;
  overflow: visible;
  text-overflow: unset;

  margin-left: 8px;
}

.counterLabel :global(.close.icon),
.counterLabel :global(.delete.icon) {
  align-self: center;
}

.chatCounterLabel {
  width: 20px;
  composes: counterLabel;
  padding: 3px;
  display: flex;
  margin-left: auto !important;
  align-items: center;
  justify-content: center;
}

.chatCounterLabel.hidden,
:global(.ui.label.hashtag).hidden {
  display: none !important;
}
.container {
  display: flex;
}

.listContainer {
  display: flex;
  align-items: center;
}
