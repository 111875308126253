.pic img {
  width: 32px;
  height: 32px;
}

.pic .sb-avatar__text,
.pic .sb-avatar.sb-avatar--text {
  width: 32px;
  height: 32px;
}

.wrap {
  display: flex;
  align-items: center;
}

.info {
  padding-left: 10px;
}

.info > a {
  text-decoration: none;
  cursor: pointer;
}

.info > a .header {
  margin-top: 0px;
  margin-bottom: 0px;
}

.info > a:hover {
  text-decoration: none;
}

.info > a:hover .header {
  color: var(--PRIMARY-BLUE);
  cursor: pointer;
}

.wrap.large {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.wrap.large .pic {
  width: 60px;
  height: 60px;
}

.wrap.large .pic img {
  max-width: 100%;
  display: block;
  width: auto;
  height: auto;
  margin: 0;
}

.wrap :global(.sb-avatar),
.wrap :global(.sb-avatar__text) {
  min-width: 0 !important;
  max-width: initial !important;
  min-height: 0 !important;
  max-height: initial !important;
}

.wrap.large .info {
  padding-top: 10px;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrap.large .name {
  letter-spacing: 0;
  color: var(--DARKEST-BLUE);
  line-height: 1.6;
  margin: 0;
}

.wrap.large .address {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: var(--BLACK);
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
}

.wrap.large .address span {
  padding-left: 5px;
}
.wrap.large :global(.ui.placeholder .line) {
  width: 8rem;
  margin-bottom: 1.2rem;
}
.wrap.large :global(.ui.placeholder).picHolder {
  border-radius: 30px;
  width: 60px;
  height: 60px;
}
.wrap.large :global(.ui.placeholder + .ui.placeholder) {
  margin-top: 0;
}
.wrap.large :global(.ui.placeholder .ui.image) {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.wrap.large :global(.ui.placeholder .ui.image i) {
  font-size: 5em;
  margin: 0px;
}
