.cardWrapper {
  margin-top: 200px;
}

.enter {
  color: var(--PRIMARY-BLUE);
  padding: 7px 14px;
  border: 1px solid var(--PRIMARY-BLUE);
  background-color: var(--WHITE);
  font-size: 16px !important;
  margin: 0 12px !important;
}
