.content {
  width: 580px;
}

.field {
  margin-top: 24px;
}

.field p {
  margin-bottom: 0;
}

.field .uploadDesc {
  font-size: 12px;
  width: 320px;
  color: var(--GRAY-MID);
}

.field .selectedFilesBlock {
  margin: 20px 0;
}

.field :global(.upload-dropzone-input__wrap_empty) {
  padding: 16px 0;
}

.field .selectedFilesBlock :global(.image-item) {
  width: 120px;
  height: 120px;
  display: inline-flex;
  margin-bottom: 15px;
}

.documentLink {
  margin-bottom: 32px;
}
