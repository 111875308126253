.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden auto;
}

.wrapper {
  width: 100%;
  max-width: 1000px;
}

.contentContainer {
  background-color: #fff;
  border-radius: 8px;
  margin: 24px 0;
  padding: 30px;
  position: relative;
}

.logo {
  height: 60px;
  width: 60px;
  border: 1px solid var(--GRAY-UI);
  border-radius: 100%;
  display: flex;
  padding: 6px;
}

.listTitle {
  font-weight: 500;
}

.checkList {
  list-style: none;
  padding: 0;
}

.checkList li {
  margin-top: 8px;
  display: flex;
  align-items: flex-start;
}

.checkList li svg {
  margin-top: 2px;
  flex-shrink: 0;
}

.checkList li span {
  margin-left: 4px;
}
