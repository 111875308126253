.form {
  padding: 0 0 32px;
}

.groupLabel {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 24px;
}

.descriptionGrid {
  display: grid;
  grid-template-columns: 160px 1fr;
  grid-template-rows: min-content minmax(0, 1fr);
  grid-gap: 0 24px;
  grid-template-areas: "image name" "image description";
}

.descriptionGrid .image {
  grid-area: image;
}

.descriptionGrid .name {
  grid-area: name;
}

.descriptionGrid .name :global(.field) {
  margin-bottom: 1em !important;
}

.descriptionGrid .description {
  grid-area: description;
}

.descriptionGrid textarea {
  resize: none !important;
}

.halfGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 24px;
}

.tabs {
}

.tabs.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.tabs .error {
  margin: 0 0 0 8px;
}
