.title {
  text-align: center;
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 500;
}

.feeItem,
.disclaimerTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
}
.helper {
  color: var(--GRAY-MID);
  margin-left: 4px;
}
.feeDescription,
.disclaimerDescription {
  color: var(--GRAY-MID);
  margin-bottom: 24px;
}

.policyMessage {
  margin-top: 24px;
  text-align: center;
}
