.flowWrapper :global(.wizard .wizard-body .status) {
  background: #f0f2f5;
  position: relative !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
}
.flowWrapper :global(.wizard .wizard-body .status .backButton) {
  position: relative !important;
  text-align: left;
}

.flowWrapper :global(.wizard .wizard-body) {
  width: 612px !important;
  padding: unset !important;
  border: unset !important;
  flex: 0;
}
.flowWrapper :global(.wizard .wizard-body .wizard-body-contents) {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 30px 0px !important;
}

.flowWrapper :global(.wizard .buttons-panel) {
  display: block !important;
  margin-bottom: 30px !important;
}

.flowWrapper footer :global(.ui.button.button-next) {
  padding: 13px 271px 12px 252px !important;
}
