:global(button.fluid) {
  justify-content: center;
  padding: 12px !important;
}

.stepContainer {
  max-width: 620px;
  margin: 0 auto;
}

.titleSection {
  display: flex;
  align-items: center;
  gap: 16px;
}

.titleSection h1 {
  font-size: 28px !important;
  margin: 0;
}

.createAccountDescription {
  margin: 24px 0;
}

.selectCountryTitle {
  margin-bottom: 8px;
}

.selectCountryDescription {
  color: var(--GRAY-MID);
}

.selectCountryDropdown {
  margin-bottom: 24px;
}

.actionMessage {
  color: var(--GRAY-MID);
  text-align: center;
  margin-top: 16px;
}

.connectShopifyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 48px auto;
  gap: 16px;
  max-width: 320px;
}

.connectShopifyContainer p {
  font-size: 20px !important;
  text-align: center;
}

.guideText {
  margin-top: 24px;
  text-align: center;
}

.chooseStoreTitle {
  text-align: center;
  font-size: 20px;
}

.storeGroup {
  gap: 16px;
  display: flex;
  flex-direction: column;
  margin: 24px 0;
}

.buttonGroup {
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.accessList {
  margin-bottom: 24px;
}

.accessList > p {
  margin-bottom: 8px !important;
  font-weight: 500;
}

.accessList ul {
  margin: 0;
}

.successSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 40px 0;
}

.successSection h1 {
  font-size: 28px !important;
  margin: 0;
}

.exploreFeatureSection {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.featureWrapper {
  display: flex;
  gap: 24px;
  align-items: center;
}

.featureWrapper img {
  max-width: 200px !important;
  height: 100%;
}

.featureTitle {
  font-size: 20px !important;
  margin-bottom: 8px;
  font-weight: 500;
}

.backToChannels {
  display: flex;
  justify-content: center;
  margin-top: 48px;
}
