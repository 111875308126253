.headerSampleTitle {
  font-weight: 500;
  font-size: 16px;
}

.headerUploadedSample {
  margin: 8px 0 16px;
}

.headerSampleError {
  margin: -24px 0 16px;
}

.headerSampleUploader {
  max-width: 400px;
  margin: 0 auto;
}

.sampleModal .header {
  padding: 32px 40px;
  font-size: 20px !important;
  border-bottom: 1px solid var(--DISABLED-BORDER-COLOR);
  margin-bottom: 0 !important;
}

.sampleModal .body {
  padding: 32px 40px;
}

.sampleModal .description {
  margin-bottom: 16px;
}

.sampleModal .footer {
  padding: 32px 40px;
  border-top: 1px solid var(--DISABLED-BORDER-COLOR);
  display: flex;
  justify-content: flex-end;
}

.messageSampleTitleWrapper {
  margin-bottom: 16px;
}

.messageSampleTitle {
  font-size: 16px;
  font-weight: 500;
  margin-right: 4px;
}

.messageSampleText {
  margin-bottom: 16px;
}

.messageSampleText .variable {
  color: var(--PRIMARY-BLUE);
}

.messageSampleVariable {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.messageSampleVariable label {
  margin-bottom: 8px;
}

.cancelBtn {
  margin-right: 24px !important;
  --bg-color: #e7eaff;
  --border-hover-color: #e7eaff;
  --text-color: var(--PRIMARY-BLUE);
}

.messageSampleError {
  margin-top: 0 !important;
}

.buttonUrlSampleTitle {
  margin: 16px 0;
  font-size: 16px;
  font-weight: 500;
}

.buttonUrlSampleDescription {
  margin-bottom: 16px;
}

.buttonUrlSampleError {
  margin-top: 16px !important;
}
