.record {
  margin-bottom: 7px;
}

.record .header {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  cursor: pointer;
}

.record .collapser {
  display: inline-flex;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 7px;
}

.record .date {
  font-weight: 500;
  white-space: nowrap;
}

.record .curtain {
  transition: height 300ms;
  overflow: hidden;
}

.record.opened .header .icon {
  transform: rotate(0);
  transition: transform 400ms;
}

.record.closed .header .icon {
  transform: rotate(-180deg);
  transition: transform 400ms;
}

.record.closed .curtain {
  height: 0;
}

.detail {
  margin-left: 24px;
}

.timing {
  font-weight: 500;
  white-space: nowrap;
  margin-left: 7px;
}

.pages {
  color: var(--GRAY-MID);
  margin-left: 14px;
}
