.aside {
  overflow-y: auto;
  border-radius: 8px;
  padding: 24px 24px 28px;
  display: grid;
  grid-template-rows: min-content minmax(0, 1fr);
  background-color: #fafafe;
  height: 100%;
}

.summary {
  padding-bottom: 12px;
}

.summary .head {
}

.summary .row {
  display: flex;
  padding: 0 0 14px;
  word-break: break-all;
}

.summary .label {
  color: var(--GRAY-MID);
  font-weight: 500;
  margin-right: 14px;
  word-break: normal;
}

.summary .value {
  font-weight: 500;
  margin-left: auto;
}

.items {
  overflow-y: auto;
}

.item {
  display: flex;
  margin: 0 0 14px;
}

.item .pic {
  flex: 0 0 55px;
  height: 55px;
  width: 55px;
  padding: 0 5px 5px 0;
  margin-right: 6px;
  overflow: hidden;
  position: relative;
}

.item .pic img {
  border-radius: 8px;
  max-width: 100%;
  object-fit: cover;
  width: 50px;
  height: 50px;
}

.item .pic .count {
  display: inline-flex;
  position: absolute;
  border-radius: 50%;
  background-color: #626778;
  color: #fff;
  width: 20px;
  height: 20px;
  font-size: 12px;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.item .pic.empty {
  background: url("assets/catalog-img.svg") 50% 50% no-repeat;
}

.item .details {
  flex: 1 0 auto;
}

.item .details .name {
  margin: 0 0 4px;
}

.item .details .param {
  margin: 0 0 4px;
  color: var(--GRAY-MID);
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 18em;
  white-space: nowrap;
  word-break: normal;
}

.item .details .price {
  color: var(--GRAY-MID);
  margin: 0 0 4px;
}
