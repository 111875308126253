.icon {
  --image: url("./assets/salesforce-logo.svg");
  --width: 20px;
  --height: 20px;
}
.link {
  padding: 12px 0px 12px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--PRIMARY-BLUE);
}
.link:hover {
  text-decoration: underline;
}
.noRecord {
  text-align: center;
}
