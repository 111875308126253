.box {
  outline: none;
  border-radius: 10px !important;
  box-shadow: 4px 2px 20px 0 rgba(233, 233, 233, 0.5) !important;
  display: grid;
  grid-template-rows: min-content 1fr min-content min-content 42px;
  grid-template-columns: 100%;
  grid-template-areas: "tabs" "text" "interactive" "attaches" "tools";
  bottom: 0;
  position: relative;
  z-index: 0;
  order: 4;
  max-width: 100%;
  min-height: 161px;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  margin: 5px 25px 0px 15px;
}

.box :global(.dimmer) {
  background-color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
}

.box.reply {
}

.box.whatsAppTemplate {
  background-color: #f7f7f7;
}

.box.note {
  background-color: var(--NOTE-CONTENT);
}

.box.note > textarea {
  background-color: transparent;
}

.box.note :global(.textarea) {
  background-color: var(--NOTE-CONTENT);
}

.box.note :global(.textarea__control textarea) {
  background-color: var(--NOTE-CONTENT) !important;
  min-height: 40px;
}

.tabs {
  grid-area: tabs;
}

.tools {
  grid-area: tools;
  align-self: end;
  z-index: 10;
  height: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
}

.toolsLite {
  grid-area: tools;
  display: flex;
  justify-self: end;
  height: 0;
  padding-bottom: 8px;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: space-between;
  flex: 1 0 auto;
}

.text {
  grid-area: text;
}

.attaches {
  grid-area: attaches;
}

.context {
  background-color: var(--GRAY-LIGHT);
  margin: 10px 30px 9px 16px;
  padding: 10px 20px 12px;
  order: 4;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: stretch;
  border-radius: 8px;
}

.context:global(.visible.transition) {
  display: flex !important;
}

.context :global(.close.icon) {
  flex: 0 0 15px;
  margin-left: 4px;
  height: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 23px;
}

.selectedCount {
  flex: auto;
}

.textareaWrap {
  grid-row: 2;
  grid-column: 1 / 1;
  z-index: 0;
  padding: 7px 14px;
  overflow: hidden auto;
}

.textareaWrap.hidden {
  display: none;
}

.interactiveMessageBox {
  grid-area: interactive;
  display: flex;
  align-items: flex-end;
  padding: 7px 14px;
}

.interactiveMessageBox > :last-child {
  margin: 7px 0 7px 5px;
}

.box :global(.facebookOTNLoader) {
  display: block !important;
}

.typing {
  order: 5;
  padding: 4px 23px 9px;
  height: 35px;
}
