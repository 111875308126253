.wrapper {
  padding: 32px 35px;
  margin: 0 auto;
}

.header {
  font-weight: 500;
  font-size: 16px;
}

.description {
  color: var(--GRAY-MID);
  padding: 8px 0;
}

.content {
  display: flex;
  padding: 16px 0;
}
.selectionCard {
  width: 480px;
  border-radius: 5px;
  border: 1px solid var(--DISABLED_FONT_BACKGROUND);
  padding: 20px;
}

.titleWrapper {
  display: flex;
  align-items: center;
}

.versionTag {
  padding: 5px 10px 5px 10px;
  margin-left: 8px;
  border-radius: 23px;
  font-size: 12px;
  color: #5ebada;
  background-color: #e9f1f8;
}

.tickIcon {
  --image: url("../../../assets/images/onboarding/tick.svg");
  --width: 24px;
  --height: 24px;
  margin-right: 13px;
}

.selectionCard ul {
  list-style-type: none !important;
}

.selectionCard ul li {
  display: flex;
  align-items: center;
}

.hint {
  color: var(--GRAY-MID);
}

.hint a {
  color: var(--PRIMARY-BLUE) !important;
}
