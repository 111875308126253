.filter {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-flow: row nowrap;
  padding: 0 0 21px;
}

.filter .field {
  max-height: min-content;
  flex: 1 1 min-content;
}

.filter .label {
  font-weight: 500;
  margin: 0 0 7px;
}

.filter .input {
}

.filter .input :global(input),
.filter .input :global(.ui.dropdown.ui.ui) {
  border: 1px solid var(--DISABLED-BORDER-COLOR) !important;
}

.filter .input :global(.ui.dropdown.ui.ui) {
  width: 270px;
}

.filter .input :global(.ui.dropdown.ui.ui .default.text) {
  color: #ccd3de;
  overflow: visible;
}

.filter .input :global(.ui.dropdown.ui.ui.active) {
  box-shadow: var(--FOCUS-INPUT-BORDER-SHADOW);
  border: 1px solid var(--FOCUS-INPUT-BORDER-BLUE) !important;
}

.filter .input :global(.ui.dropdown.ui.ui .menu.visible) {
  transform: translateY(-4px);
  border-radius: 0 0 5px 5px !important;
}

.filter .input :global(.ui.dropdown.ui.ui .menu .item),
.filter .input :global(.ui.dropdown.ui.ui .menu .item:hover) {
  border-color: transparent !important;
}

.pictograms {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.pictograms .from {
  flex: 0 1 auto;
}

.pictograms .to {
  flex: 0 1 auto;
}

.pictograms .link {
  display: block;
  flex: 1 0 auto;
  margin: 0 7px;
  border-bottom: 2px solid var(--DISABLED-BORDER-COLOR);
}
