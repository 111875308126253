.modal .header:global(.header) {
  justify-content: center !important;
  font-size: 20px !important;
  padding-top: 30px !important;
  padding-bottom: 4px !important;
}
.content {
  padding-top: 4px !important;
  padding-bottom: 10px !important;
  text-align: center;
}

.actions {
  justify-content: center !important;
  padding-top: 10px !important;
  padding-bottom: 30px !important;
}

.upgradeButton {
  background-color: var(--PRIMARY-BLUE) !important;
  color: var(--WHITE) !important;
}
