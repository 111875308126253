.root {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 26px 30px;
}

.root.loading .tabs :global(.item .ui.placeholder) {
  width: 100px;
}

.head {
  margin: 0 0 14px;
  font-weight: 500;
}
.tabs :global(.ui.menu.pointing) {
  overflow: auto hidden;
}

.tableWrap {
  overflow: auto;
  position: relative;
}

.tableWrap :global(.ui.dimmer) {
  padding-top: 0;
}

.table:global(.ui) {
  border-collapse: collapse;
  position: relative;
  margin-top: 0;
}

.table:global(.ui) thead tr th {
  text-align: center !important;
  font-size: 12px !important;
  text-transform: uppercase;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.table:global(.ui) thead tr th .hint {
  margin-left: 7px !important;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}

.table:global(.ui) thead tr th .hint i {
  --width: 14px !important;
  --height: 14px !important;
}

.table:global(.ui) thead tr th,
.table:global(.ui) tbody tr td {
  border: 1px solid #eaeaea !important;
}

.table:global(.ui) tbody tr td {
  height: 64px;
}

.table:global(.ui.ui.ui) thead tr th:first-child,
.table:global(.ui.ui.ui) tbody tr td:first-child {
  padding-left: 30px !important;
}

.table:global(.ui.ui) thead tr th:first-child {
  padding-left: 30px !important;
  text-align: left !important;
}

.table:global(.ui) thead tr th:last-child,
.table:global(.ui) tbody tr td:last-child {
  padding-right: 20px !important;
}

td.staffCell {
  padding-top: 4px !important;
  padding-bottom: 0 !important;
  vertical-align: middle;
  line-height: 1;
  --iconHeight: 14px;
  --iconWidth: 14px;
  --widthOverride: 14px;
  --heightOverride: 14px;
}
td.staffCell .info {
  margin-left: 8px;
  transform: translateY(2px);
  height: auto !important;
  display: inline-flex;
  align-items: center;
}

td.valueCell {
  padding: 0 !important;
  width: 125px;
  vertical-align: middle;
  text-align: center !important;
}

.table:global(.ui.ui) td.moneyCell {
  font-weight: 500;
  text-align: right !important;
}

td.progressCell {
  padding: 0 !important;
  overflow: visible !important;
  position: relative;
}

.pager {
  display: flex;
  padding: 32px 0;
  justify-content: center;
}
