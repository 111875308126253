.user {
  display: flex;
  align-items: center;
}
.imageWrapper {
  flex: none;
  margin-right: 8px;
  width: 32px;
  height: 32px;
  flex: none;
  overflow: hidden;
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.name {
  font-weight: 500;
}
.team {
  color: var(--GRAY-MID);
}

.retryWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 200px;
}

.retryDesc {
  color: var(--GRAY-MID);
  margin-bottom: 16px;
}
