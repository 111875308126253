.pages {
  position: relative;
}

.pages :global(.dropdown .item .text) {
  display: flex;
  align-items: center;
}

.pages :global(.dropdown .item .text img) {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}
