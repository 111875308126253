.content:global(.main-content) {
  margin-bottom: 54px;
}

.descWrapper {
  margin: 52px 0 0 12px;
  width: 400px;
}

.descWrapper .title {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 8px;
}

.backBtn {
  margin-top: 140px;
}

.card {
  border-radius: 16px !important;
  box-shadow: 0px 10px 32px rgba(0, 102, 255, 0.08) !important;
  width: 469px;
  padding: 40px 35px;
  margin-bottom: 60px;
}

.channels {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.channel {
  width: 126px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 16px 2px;
  margin-bottom: 12px;
  border: 1px solid var(--WHITE);
}

.channel:hover {
  border-color: var(--GRAY-UI);
}

.channel.active {
  border-color: var(--PRIMARY-BLUE);
}

.channel .icon {
  width: 64px;
  height: 64px;
  margin-bottom: 16px;
}

.channel .channelName {
  font-size: 16px;
  font-weight: 500;
}

.isEmpty {
  display: flex;
  justify-content: center;
}

.isEmpty :global(.ui.checkbox) + label {
  color: var(--GRAY-MID);
  font-weight: 500;
  padding-left: 16px;
}

.btnBlock {
  display: flex;
  justify-content: center;
}

.nextStepBtn:global(.primary.ui) {
  padding: 12px 32px;
  margin-top: 32px;
  width: 100%;
}

.fieldError:global(.field-error) {
  padding: 0;
}

.fieldError:global(.field-error)::before {
  display: none;
}

.footerError {
  text-align: center;
}

@media (max-width: 960px) {
  .card {
    width: 90vw;
    padding: 40px 16px;
  }
}
