.featureTableHeader {
  border-bottom: 0 !important;
  font-weight: 500 !important;
}

.featureLabel {
  color: var(--DISABLED-FONT-COLOR) !important;
  display: flex;
  align-items: center;
}

.featureTableWrapper {
  table-layout: fixed;
}

.featuresCrossIcon {
  --cross-color: var(--DISABLED-BORDER-COLOR);
}

.yearlyOnlyBadge {
  width: auto;
  padding: 3px 10px !important;
}

.infoTooltip {
  margin-left: 5px;
}
