.button {
  padding: 8px 15px !important;
  min-height: 33px !important;
}
.button.sm {
  padding: 5px 7px !important;
  min-height: 0 !important;
  height: 30px;
  font-size: 14px !important;
}

.button.mid {
  font-size: 16px !important;
  min-height: 37px !important;
  padding: 0 21px !important;
}
.button.input {
  min-height: 41px !important;
  padding: 0 21px !important;
}

.button,
.button a,
.button:hover a,
.button span,
.button:hover span,
.button .icon,
.button:hover :global(.icon) {
  color: var(--text-color, #3c4257) !important;
}

.button:global(.ui.ui) {
  background-color: var(--bg-color, #fff) !important;
  border-color: var(--border-color, var(--bg-color, #eaeaea)) !important;
}

.button:global(.disabled.ui.ui) {
  color: var(--text-color-disabled, var(--text-color, #9aa8bd)) !important;
  background-color: var(
    --bg-color-disabled,
    var(--bg-color, #d8d8d8)
  ) !important;
  border-color: var(--bg-color-disabled, var(--bg-color, #d8d8d8)) !important;
  opacity: 1 !important;
}

.button:hover:global(.ui.ui.ui) {
  background-color: var(--bg-hover-color, var(--bg-color)) !important;
  border-color: var(
    --border-hover-color,
    var(--bg-hover-color, #d8d8d8)
  ) !important;
  box-shadow: 0 0 10px 3px var(--shadow-hover-color, #ececec) !important;
}

.inverseBackground:hover:global(.ui.ui) {
  box-shadow: 0 0 10px 3px var(--shadow-hover-color, rgba(0, 0, 0, 0.6)) !important;
}

.centerText {
  justify-content: center;
}
.button:global(.ui.active) {
  border: 1px solid var(--PRIMARY-BLUE) !important;
}
.primary {
  /*composes: colored;*/
  --text-color: #fff;
  --text-color-hover: #fff;
  --text-color-disabled: #fff;
  --bg-color: var(--PRIMARY-BLUE);
  --border-color: var(--PRIMARY-BLUE);
  --bg-color-disabled: #bbc4fa;
  --bg-hover-color: #4d67f9;
}

.sized {
  font-size: var(--text-size, 14px);
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
}

.blue {
  --text-color: var(--PRIMARY-BLUE);
  --text-color-hover: var(--PRIMARY-BLUE);
  --bg-hover-color: var(--BUTTON-BLUE-HOVER-COLOR);
  --bg-color: var(--BUTTON-BLUE);
  --bg-color-disabled: var(--DISABLED-BORDER-COLOR);
  --text-color-disabled: var(--DISABLED-FONT-COLOR);
}
.blue:hover:global(.ui.ui.ui) {
  box-shadow: none !important;
}

.noBorder {
  --text-color: var(--PRIMARY-BLUE) !important;
  border: none !important;
}
.link {
  --text-color: var(--PRIMARY-BLUE);
  --text-color-hover: var(--PRIMARY-BLUE);
  --bg-hover-color: transparent;
  --bg-color: transparent;
  --bg-color-disabled: transparent;
  --text-color-disabled: var(--DISABLED-FONT-COLOR);
}

.link:hover:global(.ui.ui.ui) {
  box-shadow: none !important;
}
