.navTop {
  margin-bottom: 14px;
}

.channelTabs {
  display: flex;
}
.channels {
  padding-bottom: 14px;
}
.numberWrapper {
  flex-flow: column wrap;
}

.numberWrapper .label {
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 14px;
}

.numberWrapper .label :global(label) {
  padding-bottom: 0px !important;
}

.numberWrapper .channel {
  padding: 4px 10px;
  margin: 0px 14px 14px 0px;
  border-radius: 5px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  display: inline-flex;
  flex-flow: row nowrap;
  flex: auto;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  background: var(--GRAY-LIGHT);
}

.numberWrapper .channel .name {
  margin-left: 9px;
  font-weight: 500;
}

.numberWrapper .channel :global(img) {
  width: 21px;
  height: 21px;
}

.whatsapp360Wrap {
  overflow: auto !important;
}
