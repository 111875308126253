.container {
  height: 100%;
}

.container:global(.textarea) {
  flex: 0 0 auto;
}
:global(.textarea__suggestions) {
  z-index: 10;
}

:global(.textarea__control textarea) {
  color: var(--DARKEST-BLUE);
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  min-height: 40px;
}

.container :global(.textarea__input) {
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto !important;
  outline: none;
  resize: none;
  border: none;
  color: var(--DARKEST-BLUE);
  white-space: pre-wrap;
  padding: 0;
  width: 100%;
  box-shadow: none !important;
  border-radius: 0 !important;
  border: 0 !important;
  word-spacing: 0;
  font-family: var(--DEFAULT-FONT-FAMILY);
}

.container :global(.textarea__input:focus) {
  border: 0px !important;
  box-shadow: none !important;
}

.container :global(.textarea__highlighter__caret) {
  background-color: transparent;
}

:global(.textarea__suggestions) {
  background-color: transparent !important;
}

:global(.textarea__highlighter) {
  color: var(--DARKEST-BLUE);
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
}

:global(.textarea__highlighter strong) {
  color: var(--PRIMARY-BLUE);
  z-index: 1;
  position: relative;
  display: inline-block;
  background-color: #e5efff;
  border-radius: 2px;
  text-shadow: 1px 1px 1px #fef6d7, 1px -1px 1px #fef6d7, -1px 1px 1px #fef6d7,
    -1px -1px 1px #fef6d7;
}
