.container {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  justify-content: center;
  max-width: 640px;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 21px;
}

.header :global(.text) {
  display: flex;
  padding-left: 21px;
  flex-direction: column;
}

.header :global(.text > .header) {
  font-size: 28px;
  font-weight: 500;
}

.todoTasks {
  margin: 21px 0;
}

.todoTasks :global(.title) {
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 14px;
}

.todoTasks :global(ul) {
  list-style: none;
  padding: 0;
  margin: 0;
}

.todoTasks :global(ul li) {
  display: flex;
  align-items: center;
}

.todoTasks :global(ul li span) {
  padding-left: 9px;
  color: #3c4257;
}

.todoTasks .action {
  padding-top: 21px;
}

.warning {
  margin-top: 16px;
}

.warning ul {
  padding-inline-start: 21px !important;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}

.action {
  margin-top: 17px;
}

.connectExisting {
  font-size: 12px;
  color: var(--PRIMARY-BLUE) !important;
  text-align: center;
  margin-top: 8px !important;
  cursor: pointer;
}

.connectExisting:global(.disabled) {
  color: var(--GRAY-MID) !important;
  cursor: default;
}

.migrate {
  font-size: 12px;
  text-align: center;
  margin-top: 8px !important;
  padding: 12px 8px;
  color: var(--PRIMARY-BLUE) !important;
  cursor: pointer;
}

.warning :global(.tipHeader) {
  display: flex;
}

.loader {
  display: block !important;
  position: relative !important;
}

.stripeFail {
  display: flex;
  align-items: center;
  position: absolute;
  left: 24px;
  bottom: 24px;
  padding: 9.5px;
  color: var(--FIELD-ERROR-RED);
  background-color: var(--WARNING-BACKGROUND);
  box-shadow: 0px 0px 10px 3px var(--GRAY-SHADOW);
  border-radius: 4px;
}

.stripeFail img {
  margin-right: 8px;
  width: 20px;
  height: 20px;
}
