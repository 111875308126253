.modal {
  display: flex;
  flex-direction: column;
  width: 68vw !important;
  max-width: 1000px;
}

.modalContent {
  height: 600px;
  display: flex !important;
  flex-direction: column;
}

.modalContent .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
}

.modalContent .header .action {
  margin-right: 20px;
  margin-bottom: 21px;
}

.modalContent .header .selectors {
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  margin-left: auto;
}

.modalContent .header .action .button {
  width: 150px;
  justify-content: space-between;
  display: flex;
}

.modalContent .header .action .button .text {
  text-align: center;
  flex: 1;
}

.modal .productList {
  display: flex;
  flex-direction: column;
}

.productListContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 39px;
}

.productGrid {
  display: grid;
  grid-template-areas: "image content";
  padding: 12px;
  grid-template-columns: 84px auto;
}

.productGrid:hover {
  background: #f1f3ff;
  border-radius: 16px;
  cursor: pointer;
}

.search {
  width: 100%;
  padding: 0 15px 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search :global(.ui.input) {
  width: 100%;
}

.search :global(.ui.input input) {
  padding-right: 39px !important;
}

.search .closeIcon {
  position: absolute;
  right: 54px;
}

.productImage {
  grid-area: image;
  width: 82px;
  height: 82px;
  border-radius: 8px;
  object-fit: contain;
}

.productListContainer .content {
  grid-area: content;
  color: var(--GRAY-DARK);
  padding-left: 24px;
}

.productListContainer .content .amount {
  padding-top: 12px;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 26px;
  border-top: 1px solid var(--DISABLED-BORDER-COLOR);
  justify-content: space-between;
}

.footer .count {
  color: var(--ORIGINAL-BLACK);
}

.footer .pagination {
  flex: 1 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.productModal {
  height: auto;
  flex: 1;
  overflow: hidden auto;
}

.stockAvailable .available {
  --color: #55cd6c;
}

.stockAvailable .outOfStock {
  --color: #ee5e74;
}

.stockAvailable .quantity {
  padding-left: 12px;
}

.addToCartCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.addToCartCell .stepper {
  padding-right: 14px;
  width: 104px;
}

.addToCartCell .stepper :global(.ui.input) {
  width: 40px;
}

.link {
  color: var(--PRIMARY-BLUE);
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
  color: var(--LINK-HOVER);
}

.addToCart {
  width: 75px !important;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.cartTab {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cartTab .cartItems {
  margin-left: 7px;
  color: var(--WHITE);
  background: var(--PRIMARY-BLUE);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paging {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.matched {
  color: var(--PRIMARY-BLUE);
}

.searchResult {
  white-space: pre;
}

.refreshButtonWrapper {
  display: flex;
  align-items: center;
  color: var(--PRIMARY-BLUE);
  cursor: pointer;
}

.refreshButtonWrapper:hover {
  text-decoration: underline;
}

.refreshButton {
  padding: 4px 4px 0;
  margin-left: 4px;
}
