.link,
.link:any-link {
  border-bottom: none;
  text-decoration: none;
  background-color: #f0f2f5;
  color: var(--GRAY-DARK);
  padding: 0 10px;
  min-height: 33px;
  border-radius: 5px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
}

.link.transparent {
  background-color: transparent;
}

.link.header {
  font-size: 16px;
}

.link:hover,
.link:any-link:hover {
  border-bottom: none;
  color: BLACK;
  background-color: var(--GRAY-LIGHT);
  text-decoration: none;
}

.hovered,
a:link.hovered:any-link {
  color: BLACK;
  background-color: var(--GRAY-LIGHT);
  border-bottom: none;
  text-decoration: none;
}

.icon {
  position: relative;
  top: 0.07em;
  margin: 0 5px 0 0 !important;
}
