.grid {
  display: grid;
  grid-template-rows: minmax(0, auto) min-content;
  height: 100%;
}

.table {
  overflow: auto;
  margin-left: -32px;
  margin-right: -32px;
}

.pagination {
  margin: 13px 0 -24px;
  display: flex;
  justify-content: center;
}
