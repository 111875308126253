.icon {
  margin-left: 7px;
  cursor: pointer;
}

.buttonWrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 7px;
}

.button {
  min-height: unset !important;
  padding: 6px 14px !important;
  height: 28px !important;
  background-color: rgba(96, 120, 255, 0.3) !important;
  border-color: rgba(96, 120, 255, 0.3) !important;
}
