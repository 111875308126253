.progress {
  display: grid;
  width: 100%;
  height: 64px;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  grid-template-areas: "progress";
}

.value {
  grid-area: progress;
  align-self: center;
  justify-self: center;
}

.bar {
  grid-area: progress;
  background-color: #e7eaff;
  justify-self: start;
}
