.tabs {
  display: flex;
  height: 30px;
  margin-bottom: 14px;
  overflow-x: scroll;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.tabs::-webkit-scrollbar {
  display: none;
}

.tab {
  border-bottom: 2px solid transparent;
  font-weight: 500;
  width: max-content;
  cursor: pointer;
  margin-right: 14px;
  font-size: 14px;
  padding-bottom: 7px;
  user-select: none;
}

.tab.active {
  border-bottom-color: var(--PRIMARY-BLUE);
}

.content {
}
