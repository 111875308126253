.modal {
  max-width: 640px;
}
.header {
  font-size: 20px !important;
}

.actions {
  justify-content: space-between !important;
}

.actions .link {
  display: inline-flex;
  align-items: center;
  gap: 7px;
  color: var(--PRIMARY-BLUE);
  font-weight: 500;
}

.hint {
  font-size: 12px;
  color: var(--DISABLED-FONT-COLOR);
  margin: 5px 0 0;
}

.modal :global(.field.last) {
  margin-bottom: 0;
}

.modal :global(.field-error) {
  margin-top: 4px !important;
}
