.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden auto;
}

.wrapper {
  width: 100%;
  max-width: 1000px;
}

.content {
  padding: 40px 0;
  margin: 20px;
}

.section {
  margin: 0 40px 28px;
}

.tableLabel {
  display: flex;
  align-items: center;
}

.labelText {
  margin-right: 8px;
  font-weight: 500;
}

.logo {
  border-radius: 50%;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.sleekflowLogo {
  --image: url(../../../../../assets/images/logo-solid.svg);
  --height: 20px;
  --width: 20px;
}

.loading {
  min-height: 200px;
}

.footer {
  border-top: 1px solid var(--DISABLED-BORDER-COLOR);
  padding: 28px 40px 0;
  display: flex;
  justify-content: space-between;
}

.addButton {
  --border-color: var(--PRIMARY-BLUE);
  --text-color: var(--PRIMARY-BLUE);
  --border-hover-color: var(--PRIMARY-BLUE);
}

.plusIcon {
  --color: var(--PRIMARY-BLUE);
  width: 14px;
  height: 14px;
  margin-right: 8px;
}

.nextButton {
  margin-left: auto !important;
}

.skipButton {
  margin-right: 16px !important;
}

:global(.ui.form .ui.dropdown:not(.inline):not(.button)).dropdownError,
:global(.ui.form .ui.input).inputError input {
  border-color: var(--FIELD-ERROR-RED) !important;
}

:global(.ui.form .ui.dropdown:not(.inline):not(.button)).dropdownError:hover,
:global(.ui.form .ui.dropdown:not(.inline):not(.button)).dropdownError:focus,
:global(.ui.form .ui.input).inputError input:hover,
:global(.ui.form .ui.input).inputError input:focus {
  border-color: var(--FIELD-ERROR-RED) !important;
  box-shadow: 0 0 1px 3px var(--WARNING-BACKGROUND) !important;
}

.footerErrorMsg {
  background-color: var(--WARNING-BACKGROUND);
  color: var(--FIELD-ERROR-RED);
  padding: 24px 40px;
  margin: 24px 20px;
}

.additionLabel {
  color: var(--PRIMARY-BLUE);
}
