.msgContainer {
  width: 598px;
  height: 217px;
  background: rgba(234, 234, 234, 0.4);
  border: 1px solid var(--GRAY-UI);
  border-radius: var(--BORDER-RADIUS-S);
  padding: 10px 10px 11px 10px;
}

.lastMsgContainer {
  margin: 7px 0 0 39px;
}

.contactName {
  padding: 14px 0 14px 10px;
}
