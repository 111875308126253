.planSubscriptionHeaderContainer {
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-bottom: 1px solid var(--DISABLED-BORDER-COLOR);
}

.subscriptionTabToggle {
  margin-left: 14px !important;
}
.subscriptionTabToggle :global(.item) {
  margin-right: 25px !important;
  padding: 0 7px 7px !important;
}
.subscriptionTabToggle :global(.item span) {
  font-weight: 500;
  font-size: 16px;
}

.subscriptionTabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
}

.planSubscriptionTitle {
}
