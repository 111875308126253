.title {
  font-size: 16px;
  font-weight: 500;
}

.description {
  font-size: 13px;
  color: var(--GRAY-MID);
}

.usageContainer {
  display: grid;
  grid-template-columns: minmax(300px, auto) 1fr;
  grid-gap: 100px;
  width: 100%;
  padding: 21px 30px;
  border: none !important;
}
.progressBar {
  border-radius: 500px !important;
  margin-bottom: 14px !important;
}

.progressBar :global(.bar) {
  border-radius: 500px !important;
  background: var(--PRIMARY-BLUE) !important;
}

.usageLabel {
  font-weight: 500;
  color: var(--DISABLED-FONT-COLOR);
  text-align: center;
}

.currentUsageText {
  color: var(--PRIMARY-BLUE);
}
