.container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 100%;
  padding: 32px;
}

.icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  padding-bottom: 33px;
}

.writtenIcon {
  width: 88px;
  height: 76px;
  padding-right: 9.5px;
}

.tickIcon {
  width: 36px;
  height: 36px;
  align-self: center;
}

.title {
  font-size: 28px;
  font-weight: 500;
  padding-bottom: 14px;
}

.description {
  font-size: 16px;
  padding-bottom: 14px;
}

.content {
  font: 35px;
  padding-bottom: 35px;
}

.button {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 35px !important;
  height: 35px !important;
}

.icon {
  --color: #3c4257;
}
.icon:hover {
  background: var(--DISABLED-BORDER-COLOR);
}
