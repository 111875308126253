.header {
  grid-area: header;
  display: flex;
  font-weight: 500;
  font-size: 20px;
  justify-content: space-between;
  align-items: center;
}

.header :global(a:hover) {
  text-decoration: none;
  color: var(--LINK-HOVER);
}

.edit {
  padding: 12px 24px;
  background: #e7eaff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  color: var(--PRIMARY-BLUE);
}

.topControls {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: #fff;
  flex: 0 0 auto;
}

.bookmarked {
  font-weight: 500;
}

.bookmarked .star {
  width: 17px;
  --size: 17px;
  --color: var(--GRAY-MID);
}

.topControls :global(.active) .star,
.topControls :global(.item):hover .star {
  --color: var(--PRIMARY-BLUE);
}

.star {
  --size: 16px;
  --color: #3c4257;
  margin-right: 8px;
  transform: translateY(2px);
}

.action {
  display: flex;
}
.action .refresh {
  display: flex;
  margin-right: 14px;
  font-size: 14px;
  align-items: center;
  cursor: pointer;
}
.action .disabled {
  color: var(--GRAY-MID);
  display: flex;
  margin-right: 14px;
  font-size: 14px;
  align-items: center;
  cursor: default;
}

.refreshImage {
  margin-right: 7px;
  width: 10px;
  height: 10px;
}
.action .refresh:hover {
  text-decoration: underline;
  color: var(--LINK-HOVER);
}

.tipWrapper {
  padding: 20px 24px 20px;
  border-left: 4px solid var(--PRIMARY-BLUE);
  background-color: var(--BUTTON-BLUE);
  margin: 16px 0 4px;
}
.tipWrapper .tipTitleWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.tipWrapper .title {
  font-weight: 500;
}
.tipWrapper .tipBody {
  line-height: 150%;
}
