.tagsContainer:global(.ui.button) {
  margin: 7px 7px 7px 0px;
  padding: 8px 10px;
}
.tagsContainer :global(.ui.button:first-child) {
  margin-left: 0px;
}
.tagsContainer:global(.ui.button a) {
  color: var(--GRAY-DARK);
}
.tagsContainer:global(.ui.button a:after) {
  content: url("../../../assets/images/icons/external-link.svg");
  display: inline-flex;
  transform: translateX(4px) translateY(3px);
  align-items: center;
}
