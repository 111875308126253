.root {
  flex: 1 1;
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: min-content minmax(0, auto);
  background: #fff;
  border-radius: 5px;
}

.header {
  min-height: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 30px 30px 21px;
  width: 100%;
}

.body {
  overflow: auto;
  padding: 0;
}

.tabs:global(.ui.ui.ui.ui) {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 21px;
  border-bottom: 1px solid #dcdbe1;
  padding: 0 21px 21px;
}

.tab {
  height: 100%;
}

.tab.hasTable {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content minmax(0, auto);
}
