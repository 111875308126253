.pane {
  display: grid;
  grid-template-rows: min-content minmax(0, 1fr);
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: flex-start;
  padding: 0 0 24px;
}

.search {
  flex: 1 1 400px;
  margin-right: 24px;
}

.search :global(.ui.input) {
  margin-bottom: 0;
}

.actions {
  display: flex;
  flex-flow: row nowrap;
  gap: 24px;
  margin-left: auto;
}

.list {
}
