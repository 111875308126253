.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px 35px;
}

.container {
  display: flex;
  flex-direction: column;
}

.switchWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--GRAY-MID);
  font-weight: 500;
}

.switchWrapper :global(label:first-child) {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 9px;
  color: var(--BLACK);
}

.switchWrapper .description {
  font-weight: 400;
  color: var(--GRAY-MID);
  padding-bottom: 16px;
}

.applyToContainer {
  padding-top: 30px;
  color: var(--BLACK);
}

.applyToContainer > .title {
  font-weight: 500;
  padding-bottom: 17px;
  color: var(--BLACK);
}

.applyToSwitchGroup {
  display: flex;
  flex-direction: row;
}

.switchItem > .switch {
  margin-right: 16px !important;
}

.switchItem {
  padding-right: 24px;
}

.hint {
  font-weight: 500;
}

.hint:global(.required) {
  color: var(--SUCCESS-GREEN);
}

.hint:global(.noRequired) {
  color: #b01717;
}

.modal {
  width: 460px !important;
  height: 224px !important;
  border-radius: 16px !important;
  text-align: center !important;
  box-shadow: none !important;
  padding: 40px 32px !important;
}

.modal :global(.header) {
  display: flex !important;
  justify-content: center !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  padding: unset !important;
  padding-bottom: 8px !important;
}

.modal :global(.content) {
  font-weight: 400 !important;
  font-size: 14px !important;
  border-radius: none !important;
  color: var(--GRAY-MID);
  padding: 8px 0 12px !important;
}

.modal :global(.actions) {
  display: flex !important;
  justify-content: center !important;
  padding: 12px 0 0 0 !important;
}

.modal :global(.actions .button) {
  padding: 12px 24px !important;
}
