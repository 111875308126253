.options {
  display: flex;
  justify-content: space-between;
  margin: 21px 0px;
}
.option {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 14px 8px;
  user-select: none;
  font-weight: 500;
  color: var(--BLACK);
  background: white;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  cursor: pointer;
}
.option:first-child {
  border-radius: 5px 0px 0px 5px;
}
.option:last-child {
  border-radius: 0px 5px 5px 0px;
}
.option.active {
  color: var(--PRIMARY-BLUE);
  border: 1px solid var(--PRIMARY-BLUE);
}
