th.headerCell.headerCell.headerCell {
  font-weight: 500;
  padding: 12px 24px !important;
  background-color: var(--GRAY-LIGHT);
  color: var(--GRAY-MID);
}

.bodyCell {
  padding: 12px 24px !important;
}
