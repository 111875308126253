.subHeader {
  width: 560px;
}

:global(.ui.button.primary).paymentBtn {
  display: inline-block;
  padding: 8px 30px;
  margin-top: 21px;
  margin-left: 8px;
}

.scaledIcon {
  margin-left: 10px;
  vertical-align: middle;
  --image: url("../../../assets/images/exit-top-right-white.svg");
  --width: 14px;
  --height: 15px;
}
