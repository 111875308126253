.container {
  padding-top: 27px;
}
.header .title {
  font-weight: 500;
}
.header .subTitle {
  font-size: 12px;
  color: var(--GRAY-MID);
  padding-bottom: 14px;
}
.dropdown {
  border: 1px solid var(--DISABLED-BORDER-COLOR) !important;
  color: var(--GRAY-MID) !important;
  background: var(--DISABLED-BORDER-COLOR) !important;
  opacity: 1 !important;
}
.content {
  margin-top: 14px;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid var(--DISABLED-BORDER-COLOR) !important;
}
.content :global(label) {
  font-weight: 500;
  padding-bottom: 7px !important;
}
.content .buttonType :global(.input.disabled) {
  opacity: 1;
}
.content .buttonType :global(.input > input) {
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  color: var(--GRAY-MID) !important;
  background: var(--DISABLED-BORDER-COLOR) !important;
  margin-bottom: 24px;
}
.content .url {
  padding-top: 12px;
  opacity: 0.8;
}
.content .note {
  padding-top: 12px;
  font-size: 12px;
  color: var(--GRAY-MID);
}
