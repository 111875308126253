.subHeader {
  text-transform: uppercase;
  color: #9aa8bd;
  border-bottom: 1px solid #eaeaea;
  margin: 0px 30px 0px 0px;
  padding-bottom: 8px;
  font-size: 12px;
}

.newSection {
  margin-top: 35px;
}
