.container {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  justify-content: center;
  max-width: 640px;
  width: 100%;
}

.container > :global(.header) {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 21px;
}

.container :global(.header .text) {
  padding-left: 21px;
}

.container :global(.header .text .header) {
  font-size: 28px;
  font-weight: 500;
  padding-bottom: 7px;
  line-height: 1.2;
}

.container .button {
  margin: 28px auto;
}
