.actions {
  display: flex;
  align-items: center;
  gap: 15px;
}

.trigger {
  font-size: 12px;
  font-weight: 500;
}
