.canvas {
  max-width: 1130px;
  flex: 1 0 auto;
  display: grid;
  grid-template-rows: min-content minmax(auto, 1fr) min-content;
}

.container {
  border-radius: 10px;
  padding: 32px;
  background-color: #fff;
  display: grid;
  grid-template-rows: min-content 1fr;
}

.header {
  margin: 0 0 37px;
}

.actions {
  padding: 29px 31px 48px;
}

.tabs {
  margin: 0 -32px;
}

.tabs :global(.ui.menu.ui.ui) {
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid var(--GRAY-UI);
  padding-bottom: 24px;
  margin-bottom: 22px;
}

.tabs :global(.ui.menu.ui.ui.pagination) {
  border-bottom: none;
  padding: 0;
  margin: 0;
}

.tabs :global(.segment.ui) {
  margin-left: 0;
  margin-right: 0;
  padding-left: 32px;
  padding-right: 32px;
}

.tabs :global(.segment.ui > div) {
  flex: 1 1 auto !important;
  padding-left: 0;
}
