.root {
  display: flex;
  align-items: center;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  flex-flow: row nowrap;
  padding: 12px 20px;
}

.labels {
  flex: 1 0 auto;
}

.title {
  font-weight: 500;
}

.subtitle {
  color: var(--GRAY-MID);
}

.controls {
  flex: 0 1 auto;
}
