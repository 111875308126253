.editButton {
  all: unset;
  color: var(--PRIMARY-BLUE) !important;
  cursor: pointer;
  padding: 4px 6px;
  transition: background-color 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  font-weight: 500;
  border-radius: 4px;
}

.editButton:hover {
  background-color: var(--DISABLED-BORDER-COLOR);
}

.editIcon {
  width: 12px;
  height: 12px;
  display: inline-block;
  background-image: url("../../../assets/images/pencil-icon.svg");
  margin-right: 6px;
}

.modal:global(.visible.transition) {
  display: flex !important;
  flex-direction: column;
}

.modal {
  width: 80% !important;
  max-height: 95% !important;
  padding: 40px 56px !important;
  position: relative !important;
}
