.checkFacebookCatalog .description {
  margin-bottom: 24px;
}

.checkFacebookCatalog .checkWrapper {
  border: 1px solid var(--GRAY-SHADOW);
  padding: 20px 40px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}
.checkFacebookCatalog .checkbox {
  margin-right: 8px;
}

.checkFacebookCatalog .ask {
  font-weight: 500;
  margin-bottom: 8px;
}

.checkFacebookCatalog .answer {
  margin-bottom: 24px;
}
