.iconWrapper {
  background: var(--FOCUS-INPUT-BORDER-BLUE);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pageWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10vh;
}

.errorTitle {
  font-size: 28px;
  font-weight: 500;
  text-align: center;
  padding-top: 24px;
}

.errorDescription {
  font-size: 14px;
  text-align: center;
}
