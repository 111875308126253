.contentWrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--WHITE);
}

.content {
  width: 480px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.flashIcon {
  margin: 0 8px;
}

@media screen and (max-width: 500px) {
  .content {
    padding: 30px;
  }
}
