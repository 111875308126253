.modal {
  text-align: center;
}

.text {
  margin: 0 0 21px;
}

.content {
  display: flex !important;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;
}

.content p {
  max-width: 40em;
}

.copyInput {
  max-width: 370px;
}

.divider {
  border-bottom: 1px solid var(--DISABLED-BORDER-COLOR);
  position: relative;
  height: 0;
  overflow: visible;
  text-align: center;
  width: 100%;
  margin: 32px 0;
}

.divider .label {
  position: relative;
  display: inline-block;
  top: -7px;
  background-color: #fff;
  padding: 0 14px;
}

.code {
  width: 200px;
  margin: 0 0 28px;
}

.code img {
  margin: 0 0 28px;
  width: 200px;
  height: 200px;
}

.code :global(.ui.loader) {
  height: 200px !important;
}

.code :global(.ui.loader:before) {
  border-color: var(--GRAY-MID) !important;
}

.code :global(.ui.loader:before),
.code :global(.ui.loader:after) {
  top: 85px;
}

.download {
  --color: var(--PRIMARY-BLUE);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.download .icon {
  margin-left: 7px;
}

.code .download img,
.code .download svg {
  margin: 0 0 0 7px;
  width: auto;
}
