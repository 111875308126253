.allSet .header {
  text-align: center;
  margin-bottom: 24px;
}

.allSet .icon {
  font-size: 62px;
  margin-bottom: 24px;
}

.allSet .title {
  font-size: 28px !important;
  margin: 0;
}

.allSet .subTitle {
  color: var(--GRAY-MID);
}

.allSet .catalogInfo {
  margin-bottom: 24px;
}
.allSet .catalogInfoItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.allSet .infoLabel {
  color: var(--GRAY-MID);
  font-size: 12px;
  font-weight: 500;
  margin-right: 8px;
}

.allSet .feature {
  margin-bottom: 24px;
  padding: 12px 0;
  display: flex;
  align-items: center;
}
.allSet .featureIconWrapper {
  margin-right: 24px;
}
.allSet .featureIcon {
  background-color: var(--BUTTON-BLUE);
  border-radius: 32px;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.allSet .featureTitle {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 8px;
}

.allSet .linkWrapper {
  margin-top: 20px;
  text-align: center;
}
