.CalendarDay__selected_span {
  background: #dadfff;
  color: #6078ff;
  border: 1px double #6078ff;
}

.CalendarDay__selected_span:hover {
  background: #6078ff;
  border: 1px double white;
}

.CalendarDay__selected_span:active {
  background: #6078ff;
  border: 1px double white;
  color: #fff;
}

.CalendarDay__selected {
  background: #6078ff;
  color: white;
  border: 1px double #8194ff;
}

.CalendarDay__selected:hover {
  background: #8194ff;
  color: white;
  border: 1px double #6078ff;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #dadfff;
  color: #6078ff;
  border: 1px double #6078ff;
}

.CalendarDay__highlighted-calendar {
  color: white;
  background: #8194ff;
}

.DateInput_input__focused {
  border-bottom: 2px solid #6078ff;
  border-radius: 5px 5px 0px 0px !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid #6078ff;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right: 33px solid #8194ff;
}

.DateInput_input {
  font-size: 14px;
  color: #3c4257;
  text-align: center;
  font-weight: 400;
  border-radius: 5px;
  padding: 9px 11px 6px;
}

.DateInput {
  border-radius: 5px;
}

.DateRangePickerInput__withBorder {
  border: 1px solid #eaeaea;
  border-radius: 5px;
}

.DateRangePicker {
  margin-left: 10px;
}
