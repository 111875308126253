.root {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.group.group .input.input.input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  flex: 0 1 auto;
}

.group.error {
  border-color: var(--FIELD-ERROR-RED);
}

.group.group .currency:global(.ui.ui.ui.active),
.group.group .currency:global(.ui.ui.ui) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  min-width: 6em !important;
  flex: 0 1 auto;
  padding-right: 0;
  margin-right: -1px;
}

.group .currency :global(.dropdown.icon) {
  width: 10px;
}

.currencyLabel {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  border-radius: 5px 0 0 5px;
  margin-right: -1px;
  padding: 0 12px;
  text-transform: uppercase;
}
