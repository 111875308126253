.showTrigger {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--PRIMARY-BLUE);
  font-weight: 500;
  cursor: pointer;
}
.showTrigger:hover {
  color: var(--LINK-HOVER) !important;
  text-decoration: underline;
}
.allCurtain {
  transition: height 400ms;
}
