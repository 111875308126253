.editor {
  box-sizing: border-box;
  border: none;
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
}
.editorWithFile {
  flex-direction: row;
  display: flex;
}
.wrapper {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}
.wrapper .input {
  flex: 1;
}
.urlBlock {
  background: rgba(60, 66, 87, 0.05);
  border-radius: 8px;
  padding: 12px;
  margin-top: 14px;
}
.urlBlock .title {
  color: #788ba8;
  padding-bottom: 8px;
}
.urlBlock .inputBlock {
  color: var(--BLACK);
  display: flex;
  align-items: center;
}

.urlBlock .inputBlock {
  flex: 1;
}
.input {
  color: var(--BLACK);
  background-color: #fff;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  height: 31px;
  padding: 0 7px;
  white-space: nowrap;
}

.input:focus {
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  box-shadow: none;
  outline: none;
}
.wrapper.error {
  margin-right: 20px;
}
.wrapper.error :global(.ui.image) {
  margin-left: 4px;
}
.input.invalid {
  border-color: var(--FIELD-ERROR-RED);
}
.header {
  flex: 0 0 120px;
  width: 120px;
  height: 120px;
}
.header :global(.upload-dropzone-input__wrap_empty) {
  padding: 0px;
}
.header :global(.upload-dropzone-input__body.empty) {
  padding: 23px 12px;
  height: 120px;
  border: 1px dashed var(--BLACK);
  border-radius: 8px;
  background: #fff;
  flex-direction: column;
}
.header :global(.upload-dropzone-input__icon) {
  margin-right: 0px;
  margin-bottom: 8px;
}
.header :global(.upload-dropzone-input__description) {
  color: var(--GRAY-MID);
  font-size: 12px;
  text-align: center;
}
.header :global(.upload-dropzone-input__contents) {
  margin-left: 0px;
}
.header :global(.upload-dropzone-images .image-item) {
  flex: auto;
  height: 120px;
  width: 120px;
  margin-left: 0px;
}
.header :global(.upload-dropzone-images .image-item img) {
  width: 120px;
}
.content {
  flex: auto;
  padding-right: 14px;
}
.content .header {
  padding-bottom: 7px;
}
.footer {
  font-size: 12px;
  grid-area: footer;
  color: var(--GRAY-MID);
}
.headerText {
  font-weight: 700;
  white-space: pre-wrap;
}
.body {
  white-space: pre-wrap;
}
