.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-row: 1 / span 10;
  grid-column: 1 / 10;
  align-self: stretch;
  justify-self: stretch;
  z-index: 2;
}

.content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.content :global(.button) {
  --color: #fff;
  font-size: 14px !important;
}
