.container {
  margin: 20px 160px;
}

.description {
  margin-bottom: 16px;
}

.listTitle {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}

.list {
  list-style-type: none;
  margin-bottom: 32px;
  padding-left: 0;
}

.listItem {
  position: relative;
  padding-left: 30px;
}

.listItem::before {
  content: url(../../../../../assets/images/check.svg);
  position: absolute;
  left: 0px;
}

.arrowIcon {
  margin-left: 12px;
  --image: url(../../../../../assets/images/icons/arrow-right-white.svg);
  --width: 20px;
  --height: 20px;
}

.btnWrapper {
  margin-bottom: 16px;
}

.helper {
  color: var(--GRAY-MID);
  text-align: center;
}

.footer {
  margin: 24px 40px;
  text-align: center;
}

.highlight {
  text-decoration: underline;
  font-weight: 700;
}
