.container {
  display: flex;
  flex-direction: column;
}

.container :global(.checkbox) label {
  margin-bottom: 0 !important;
}

.container > :global(.checkbox) {
  display: flex;
  margin-bottom: 21px;
}

.container :global(.status-alert) {
  padding: 7px 0 !important;
  width: auto;
}

.container :global(.status-alert) div {
  display: flex;
  justify-content: center;
}
