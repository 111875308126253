.container {
  position: relative;
  border-radius: 5px;
  padding-top: 12px;
  overflow: hidden;
}

.container.hide {
  max-height: 540px;
  min-height: 340px;
  overflow: hidden;
}

.container :global(.dimmable .dimmer) {
  background: rgba(236, 236, 236, 0.1);
}

.widget {
  opacity: 1;
  transition: opacity 500ms ease-out;
}

.widget.muted {
  transition: opacity 300ms ease-in;
  opacity: 0;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 14px 0;
}

.header .icon {
  --width: 17px;
  --height: 18px;
  background-image: url("./assets/contact-icon.svg");
  margin-right: 9px;
}
.header .text {
  font-size: 16px;
  font-weight: 500;
}

.showMore {
  display: block;
  cursor: pointer;
  color: var(--PRIMARY-BLUE);
}

.container :global(.sb-avatar.sb-avatar--text) {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
}

.container :global(.sb-avatar__text) {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
}
