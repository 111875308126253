.portal {
  padding: 13px 16px 19px;
  font-size: 14px;
  line-height: 22.4px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 11px;
}

.title {
  font-weight: 600;
}

.closeIcon {
  --color: var(--GRAY-MID);
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.content {
  font-weight: 400;
  margin-bottom: 13px;
}

.codeContainer {
  font-size: 20px;
  line-height: 23.87px;
  font-weight: 500;
  padding-top: 22px;
  padding-bottom: 26px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-content: center;
}
