.root {
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "pic name" "pic group";
  grid-gap: 0 10px;
}

.pic {
  grid-area: pic;
  align-self: start;
  padding-top: 4px;
}

.pic.pic img {
  max-width: 32px !important;
  max-height: 32px !important;
  width: 32px !important;
  height: 32px !important;
}

.name {
  grid-area: name;
  font-weight: 500;
}

.team {
  grid-area: group;
  color: var(--GRAY-MID);
  max-width: 16em;
  margin-right: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}
