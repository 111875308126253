.content {
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 28px;
  font-weight: 500;
}

.desc {
  margin: 8px 0;
}

.videoWrapper {
  padding: 40px 100px;
  margin: 16px;
}
.videoWrapper iframe {
  width: 80vw;
  max-width: 800px;
  height: 420px;
}
.btn {
  margin: 8px;
}
