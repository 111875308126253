.modal {
  max-width: 385px;
  padding: 30px 75px 35px 75px;
}

.modal :global(.content) {
  padding: 0 !important;
}

.modal :global(.ui.image) {
  margin: 0 auto;
  width: 30px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
}

.description {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin-top: 20px;
}

.modal :global(.actions) {
  margin-top: 14px;
  justify-content: center !important;
  padding: 0 !important;
}

.modal :global(.actions > .ui.button) {
  width: 80px;
  padding: 8px 15px;
  min-height: 33px;
  justify-content: center;
}

.modal :global(.actions > .ui.button:not(:first-child)) {
  margin-left: 5px;
}
