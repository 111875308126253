.analyticsIcon {
  --width: 20px;
  --height: 20px;
  --image: url("../../../assets/images/channels/shopify.svg");
}

.productItemContainer {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  align-items: center;
}

.productItemContainer > div:first-child {
  display: flex;
  position: relative;
}

.productItemContainer > div:last-child {
  width: 75%;
}

.productItem {
  margin-bottom: 7px;
}

.productItemTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 10px;
}

.productItemContainer > div:last-child > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  padding-left: 8px;
}

.productItemImage {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}

.productPriceRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
}

.productQuantity {
  color: #9aa8bd;
  font-weight: 500;
}

.productPrice {
  font-size: 14px;
  font-weight: 700;
}

.quantityContainer {
  white-space: nowrap;
  text-align: center;
  border-radius: 1.75em;
  background-color: rgba(114, 114, 114, 0.9);
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  position: absolute;
  right: -0.75em;
  top: -0.75em;
  z-index: 3;
}

.shopifyFieldWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  line-height: 26px;
}

.shopifyFieldTitle {
  color: #9aa8bd;
  white-space: nowrap;
}

.shopifyFieldValue {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 14px;
  text-transform: capitalize;
}

.orderHeaderWrapper {
  display: flex;
  color: #9aa8bd;
  margin-bottom: 7px;
}

.orderHeaderWrapper > div {
  flex: 1 1 0px;
}

.orderValueWrapper {
  display: flex;
  flex: 1 1 0px;
  margin-bottom: 7px;
}

.orderValueWrapper > div {
  flex: 1 1 0px;
}

.orderDetailHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 7px;
}

.hyperlink {
  font-weight: 500;
  color: #6078ff;
}

.hyperlink:hover {
  color: #4d67f9 !important;
  cursor: pointer;
}
.copyField {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.copyField :global(img) {
  margin-right: 8px;
  cursor: pointer;
}
