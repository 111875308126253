.header {
  display: grid;
  grid-template-areas:
    "message action"
    "error .";
  grid-template-columns: 1fr 120px;
  padding: 21px 0px 14px 0px;
}
.message {
  grid-area: message;
  font-weight: 500;
}
.action {
  font-weight: 500;
  color: var(--PRIMARY-BLUE);
  grid-area: action;
  margin-left: auto;
}
.action:hover {
  color: var(--LINK-HOVER);
  cursor: pointer;
  text-decoration: underline;
}
