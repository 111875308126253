.wrapper {
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  border-radius: 5px;
  padding: 25px 30px;
  margin: 20px 0;
}

.form {
  display: flex;
  justify-content: center;
  overflow-y: auto;
  max-height: 90%;
}

.formWrapper {
  flex: 1;
  max-width: 600px !important;
}

.buttonTypeDescription {
  margin-top: 12px;
  color: var(--GRAY-MID);
}

.closeButton {
  border-radius: 10px;
  height: 34px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  place-self: center;
}

.closeButton i {
  margin: 0;
}

.quickReplyField button {
  margin-left: 8px;
  border: unset;
}

.quickReplyField {
  display: flex;
  align-items: center;
}

.closeButton:hover {
  background-color: var(--DISABLED-BORDER-COLOR);
}

.submitContainer {
  display: flex;
  background-color: white;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--DISABLED-BORDER-COLOR);
  justify-content: flex-end;
}

.addButton {
  border: 1px solid var(--PRIMARY-BLUE) !important;
  color: var(--PRIMARY-BLUE) !important;
  padding: 7px 14px !important;
}

:global(.ui .field) {
  position: relative;
}

.counter {
  position: absolute;
  bottom: 12px;
  right: 12px;
  color: var(--GRAY-MID);
}
