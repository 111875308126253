.menu {
  min-height: auto !important;
  padding-left: 15px;
  border: none;
  margin-top: 15px !important;
  margin-bottom: 0 !important;
  grid-row: 1 / 2;
  grid-column: 1 / 1;
  position: relative;
  z-index: 3;
}

.menu :global(.item) {
  padding: 0 0 10px !important;
  margin: 0 30px 0 0 !important;
  align-self: center;
  color: var(--GRAY-DARK) !important;
  font-weight: 500 !important;
}

.menu.menu :global(.item.active) {
  color: var(--PRIMARY-BLUE) !important;
  border-color: var(--PRIMARY-BLUE) !important;
  font-weight: 500;
}

.menu.menu :global(.item).dimmed {
  color: var(--DISABLED-BORDER-COLOR);
}

.menu :global(.item:hover),
.menu.whatsAppTemplate :global(.item:hover) {
  color: var(--PRIMARY-BLUE);
}

.selectWhatsappTemplate:global(.item) {
  --color: var(--PRIMARY-BLUE);
  margin-left: auto !important;
  color: var(--PRIMARY-BLUE) !important;
}
.menu:global(.ui.pointing.secondary.menu)
  .selectWhatsappTemplate:global(.item:hover) {
  text-decoration: underline;
  color: var(--LINK-HOVER) !important;
}

.selectWhatsappTemplate svg {
  width: 13px;
  height: 13px;
  margin-right: 7px;
}

.menu.reply {
}

.menu.note :global(.item:hover) {
  color: var(--ORANGE-NOTE);
}

.menu.note :global(.item.active) {
  color: var(--ORANGE-NOTE) !important;
  border-color: var(--ORANGE-NOTE) !important;
  font-weight: 500;
}

.selectMessageTag:global(.item) {
  --color: var(--PRIMARY-BLUE);
  margin-left: auto !important;
  color: var(--PRIMARY-BLUE) !important;
}

.selectMessageTag:global(.item img) {
  margin-right: 8px;
}
