.image {
  flex: 0 0 54px;
}
.image img {
  width: 54px;
}
.header {
  align-items: center;
  display: flex;
}
