.modal:global(.ui.ui) {
  max-height: 90vh;
  width: 960px !important;
  border-radius: 16px !important;
}

.modalHeader {
  display: block !important;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  padding-bottom: 7px !important;
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.modal :global(> i.close.icon) {
  top: -10px;
  right: -42px;
}

.modal :global(> i.close.icon:before) {
  content: url("../../../assets/images/ui/cross-rounded-white.svg");
}

.modal .header {
  margin: 0 0 7px;
  font-size: 20px;
}

.modal .close {
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 20px;
}

.modal .subheader {
  font-size: 14px;
  font-weight: 400;
  color: var(--GRAY-MID);
}

.buttons {
  justify-content: flex-start !important;
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.content {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.content.sortable {
  padding-left: 33px !important;
}

.form {
  padding-left: 30px;
}
