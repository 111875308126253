.root {
  background: #fff;
  border-radius: 4px;
  border-left: 4px solid var(--FOCUS-INPUT-BORDER-BLUE, #94c2fb);
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
}

.title {
  font-weight: 500;
}
.body {
}
.id {
  font-size: 12px;
}
.actions {
}
.link {
}
