.modal.modal {
  width: 880px !important;
  height: 600px !important;
  --border-radius: 16px;
}

.modal .content {
  height: 100%;
  overflow: hidden;
  padding: 40px 65px !important;
}
