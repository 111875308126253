.priceBreakdownWrapper {
  background-color: var(--GRAY-LIGHT);
  border-radius: 24px;
  padding: 24px;
  margin-top: 32px;
}

.planPriceWrapper {
  border-bottom: 1px solid var(--DISABLED-BORDER-COLOR);
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  min-width: 500px;
}

.planLabel {
  font-weight: 500;
  font-size: 14px;
}

.pricePerLabel {
  font-weight: 500;
  font-size: 14px;
}

.priceTotalWrapper {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}
