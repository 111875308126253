.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 55px);
  min-width: 368px;
  margin: auto;
}

.iconBg {
  background: #f0f3f8;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  padding: 8px 0;
}

.description {
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 20px;
}
