.container {
  display: flex;
  flex-direction: column;
  background: var(--WHITE);
  margin-top: 92px;
  padding: 35px 30.5px;
  border-radius: 8px;
  justify-content: center;
  max-width: 621px;
  max-height: 400px;
  width: 100%;
  text-align: left;
  margin: 0 auto;
}

.icon {
  font-size: 62px;
  text-align: center;
  padding-bottom: 14px;
}

.title {
  text-align: center;
  font-weight: 500;
  font-size: 28px;
  padding-top: 14px;
  padding-bottom: 7px;
}

.description {
  text-align: center;
  color: var(--DISABLED-FONT-COLOR);
  padding-top: 7px;
  padding-bottom: 21px;
}

.container label {
  padding-bottom: 7px;
}

.container :global(.ui.input) {
  margin-bottom: 21px !important;
}

.container .submit {
  justify-content: center;
  align-items: center;
  min-height: 44px !important;
}
