.cardWrapper {
  margin-top: 140px;
}

.nextStepBtn {
  margin-top: 24px;
}

.tips {
  margin-top: 40px;
  display: flex;
}

.tipsIconWrapper {
  flex: none;
  width: 42px;
}

.tipsIcon {
  --width: 30px;
  --height: 32px;
  --image: url(../../../../assets/images/tips-active.svg);
}

.tipsContent {
  flex: 1;
}

.tipsDesc {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 24px;
}

.channelWrapper {
  width: 72px;
  height: 72px;
  border: 13px solid #ccc;
  border-radius: 72px;
  display: inline-block;
  margin-right: 20px;
}

.channelWrapper.messenger {
  border-color: #018eff26;
}
.channelWrapper.instagram {
  border-color: #d34b6226;
}
.channelWrapper.wechat {
  border-color: #09b83e26;
}
.channelWrapper.line {
  border-color: #00c30026;
}

.channelIcon {
  width: 100%;
}
