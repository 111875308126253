.intervalButton {
  padding: 15px 54px !important;
  margin: 0 !important;
  border-radius: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  --border-color: var(--DISABLED-BORDER-COLOR);
}

.intervalButtonActive {
  color: var(--PRIMARY-BLUE);
}

.intervalButtonMonthly {
  padding: 0 54px;
}

.intervalButtonActiveIndicator {
  position: absolute;
  width: 50%;
  height: calc(100% + 2px);
  transform: translate(0, -50%);
  border: 1px solid var(--PRIMARY-BLUE);
  top: 50%;
  left: -1px;
  border-radius: 5px;
}

.monthlyIntervalActive {
  left: unset;
  right: -1px;
}

.intervalButtonYearly {
  padding: 0 54px;
}

.intervalButtonWrapper {
  margin-bottom: 36px;
  background: none;
  border-radius: 5px;
  padding: 15px 0px;
  display: flex;
  position: relative;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  cursor: pointer;
}
