.grid {
  --head-height: 50px;
  overflow: auto;
  margin: 0 !important;
}

.grid :global(thead) {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f6f5f5;
}

.actionsGroup {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.contextActions {
  display: inline-flex;
  line-height: 1;
  margin-left: 8px;
}
.contextActions :global(.context-menu-trigger) {
  display: inline-flex;
  align-items: center;
}

.contextActions i {
  margin-right: 0;
}

.grid :global(tr th:first-child),
.grid :global(tr td:first-child) {
  padding-left: 31px !important;
}
