.table {
  height: calc(100% - 225px);
}
.table:global(.hasTab) {
  height: calc(100% - 300px) !important;
}

.emptyContent {
  height: calc(100% - 142px);
}
.emptyContent :global(.empty-content) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 500;
}

.table :global(.ui.table.very.basic > tbody td) {
  border-bottom: none;
  padding: 10px 20px 8px 20px !important;
  height: 54px !important;
}

.table :global(.ui.table.very.basic > tbody td:first-child) {
  padding-left: 24px !important;
}

.table :global(.ui.table.very.basic > thead tr th:first-child) {
  padding-left: 24px !important;
}

.status {
  width: 180px !important;
}

.statusWrap {
  display: flex;
  align-items: center;
}

.statusWrap :global(.ui.image) {
  display: inline-block;
  margin-right: 9px;
  width: 12px !important;
}

.success {
  color: var(--SUCCESS-GREEN) !important;
}

.failed {
  color: var(--FIELD-ERROR-RED) !important;
}

.name {
  padding-left: 7px;
}

.link {
  display: flex;
  align-items: center;
  color: var(--GRAY_DARK) !important;
}

.link:hover {
  color: var(--BLACK) !important;
}

.dateTriggered {
  width: 280px !important;
}

.contactWarp {
  display: flex;
  justify-content: space-between;
}

.contactWarp :global(.ui.button) {
  padding: 9px 15px !important;
  min-width: 100px !important;
  height: 35px !important;
  margin-right: 55px;
  display: none;
}

.contactWarp :global(.ui.image) {
  width: 14px !important;
  height: 14px !important;
  margin-right: 10px;
}

.table :global(.ui.table.very.basic > tbody tr:hover .ui.button) {
  display: flex;
}
