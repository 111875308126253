.group {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}

.append {
  margin-left: 8px;
  font-weight: 500;
  margin-bottom: 0 !important;
}

.group :global(.ui.checkbox.ui.ui) {
  flex: 0 0 auto !important;
}
.group:global(.on) .append {
  color: #4bc469;
}

.group:global(.off) .append {
  color: #9e9e9e;
}

.group.normal {
}

.group.large :global(.ui.toggle.checkbox) {
  width: 34px;
}

.group.large :global(.ui.toggle.checkbox label:before) {
  width: 35px !important;
  height: 19px !important;
}

.group.large :global(.ui.toggle.checkbox:not(.checked) label:after) {
  left: 2px !important;
}

.group.large :global(.ui.toggle.checkbox label:after) {
  width: 15px !important;
  height: 15px !important;
  top: 2px !important;
  left: 18px !important;
}

.group.large .append {
}
