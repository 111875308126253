.sectionTitle {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0px;
}

.subTitle {
  font-weight: 500;
  margin: 16px 0 0 0;
}

.description {
  color: var(--GRAY-MID);
  margin-top: 6px;
}

.sectionContainer {
  border-bottom: 1px solid var(--GRAY-UI);
  padding: 24px 0;
}

.loadingSection {
  padding: 48px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorSection {
  padding: 24px 0;
}

.saveButton {
  margin: 24px 0 !important;
}
