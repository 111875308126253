.visit.icon {
  --width: 8px;
  --height: 10px;
  --image: url("../assets/visit.svg");
  margin-right: 8px;
  margin-left: 3px;
  transform: translate(0, 4px);
  align-self: flex-start;
  flex: 1 0;
}

.url {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  margin: 0 0 7px;
}

.urls {
  padding: 3px 0 0;
}

.url .status.visit {
}

.timing {
  align-self: flex-start;
  white-space: nowrap;
  margin-left: 3px;
}
.text {
  text-decoration: underline;
  cursor: pointer;
  flex: 1 1;
  word-wrap: anywhere;
  color: var(--PRIMARY-BLUE);
}
.text a {
  text-decoration: none;
  color: inherit;
}
