.container {
  max-width: 388px;
  margin: 0 auto;
  margin-top: 150px;
}

.title {
  font-size: 20px;
  font-weight: 500;
  color: var(--BLACK);
  text-align: left;
}

.description {
  text-align: left;
  color: var(--GRAY-MID);
  margin-top: 14px;
}

.buttonWrap {
  display: flex;
  justify-content: flex-start;
  margin-top: 21px;
}

.buttonWrap :global(.ui.button) {
  min-height: 37px !important;
  padding: 10px 30px !important;
}
