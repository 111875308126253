.list {
  list-style: none;
  text-indent: 0;
  padding-left: 25px;
}

.list .item {
  padding: 0 0 7px 0px;
  text-indent: 0;
  color: var(--itemColor, var(--BLACK));
  position: relative;
}

.list .item:before {
  content: url("../../../assets/images/onboarding/tick.svg");
  display: inline-block;
  position: absolute;
  left: -25px;
  top: 1px;
}

.list.inset {
  padding-left: 0;
}

.list.inset .item:before {
  position: relative;
  margin-right: 7px;
  left: 0;
}
