.scheduled {
  display: inline-block;
  margin-right: 7px;
  --image: url(./assets/clock.svg);
  --width: 11px;
  --height: 11px;
}

.interactiveMessageWrapper {
  align-self: flex-end;
  margin-top: 14px;
  cursor: pointer;
}

.interactiveMessageWrapper:global(.facebookOTNMessage) {
  cursor: unset !important;
}

.previewModal {
  width: max-content !important;
  padding: 32px 0;
}

.prefix {
  padding-right: 2px;
}

.prepend {
}
