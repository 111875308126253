.header {
  font-size: 16px;
  font-weight: 500;
}

.container ul {
  list-style-type: none !important;
  padding-left: 0 !important;
}

.container ul li {
  padding: 14px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  border-radius: 5px;
  cursor: pointer;
}
.container ul li:hover {
  border: 1px solid var(--FOCUS-INPUT-BORDER-BLUE);
  box-shadow: 0 0 1px 3px var(--FOCUS-INPUT-BORDER-BLUE-SHADOW) !important;
}

.container ul li:not(:last-child) {
  margin-bottom: 14px;
}

.rowTitle {
  font-weight: 500;
  font-size: 16px;
}

.rowDescription {
  font-size: 13px;
  color: var(--GRAY-MID);
}
