.title {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 17px;
}

.channel {
  margin-bottom: 21px;
}

.root {
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  height: 100%;
}

.preview {
}

.head {
}
