.root {
}

.field {
  margin: 0 0 21px;
}

.field :global(.ui.field) {
  margin: 0;
}

.actions {
}
