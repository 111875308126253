.section {
  padding: 24px 0;
}

.shippingForm :global(.ui.checkbox.toggle.ui) {
  width: 38px !important;
  height: 23px !important;
}

.shippingForm :global(.ui.checkbox.toggle.ui label::after) {
  width: 15px !important;
  height: 15px !important;
}

.shippingForm :global(.ui.checkbox.toggle.ui.checked label::after) {
  left: 19px;
}

.shippingForm :global(.ui.checkbox.toggle.ui label::before) {
  width: 38px !important;
  height: 23px !important;
}
