.warnContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 650px;
  margin: 0 auto;
  text-align: left;
}

.title {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 14px;
}

.content span {
  font-weight: 500;
  line-height: 22.4px;
}

.alert {
  margin: 0 auto;
  max-width: 650px;
  text-align: left;
  margin-bottom: 24px;
}

.arrowIcon {
  --image: url("../../../../assets/images/arrow-right-orange.svg");
  --width: 21px;
  --height: 15px;
  margin-left: 8px;
}

.guide {
  color: #ff8d00;
  margin-top: 16px;
}

.guide a {
  color: #ff8d00;
  text-decoration: unset !important;
  display: flex;
  align-items: center;
}

.guide a:hover {
  color: #ff8d00 !important;
}
