.collaboratorFilterSelector {
  background-color: #ccc;
}

:global(.ui.vertical.menu) .collaboratorFilterSelector :global(.menu) {
  margin: 0;
}

:global(.ui.vertical.menu) .collaboratorFilterSelector :global(.menu .item) {
  padding: 0;
  font-size: 14px;
  color: var(--BLACK);
}

:global(.ui.vertical.sidebar.menu)
  .collaboratorFilterSelector
  :global(.menu .staff)
  > * {
  padding-left: 0;
}

.collaboratorInput {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-right: 7px;
  min-height: 34px;
  padding: 0 5px;
  border: 1px solid #ebecf0 !important;
  border-radius: 5px;
  flex-wrap: wrap;
  padding: 8px 8px 0;
  cursor: pointer;
}

.collaboratorInput > div {
  margin-bottom: 8px;
}

.placeholder {
  padding-left: 4px;
  color: var(--GRAY-MID);
}
