.component {
  margin-bottom: 0;
}

.menu:global(.menu.ui.ui) {
  border-bottom: none;
  margin-bottom: 0;
  align-items: flex-start;
  min-height: 0;
}

.menu :global(.item) {
  padding: 0 !important;
  margin: 0 !important;
  margin-right: 42px !important;
  border-bottom: none !important;
}
.menu :global(.item):hover {
  color: var(--PRIMARY-BLUE);
}

.menu :global(.item.active) {
  border-bottom: none !important;
}

.nameWrap {
}

.name {
  font-weight: 500;
  color: var(--BLACK);
  padding-bottom: 5px;
  border-bottom: 4px solid transparent;
  box-sizing: content-box;
  display: inline-block;
}

.menu :global(.item.active) .name {
  color: var(--PRIMARY-BLUE);
  border-bottom: 4px solid var(--PRIMARY-BLUE);
}

.count {
  color: var(--GRAY-MID);
  margin-left: 7px;
}
