.plan {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 26px;
}

.status {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-right: 24px;
}

.row {
  display: flex;
  margin-bottom: 16px;
}

.row.lastOne {
  margin-bottom: 0;
}

.label {
  color: var(--GRAY-MID);
  font-size: 12px;
  width: 85px;
  display: flex;
  align-self: center;
}

.value {
  flex: 1;
}

.value.planName {
  font-size: 16px;
  font-weight: 500;
}

.value.planName .tag {
  background-color: #d3f2cd;
  color: #2c6047;
  font-weight: 500;
}

.value.text {
  font-size: 14px;
  display: flex;
}

.value.text span {
  display: flex;
  align-self: center;
}

.value.text .tag {
  background-color: #dceafd;
  color: #6b73bb;
  font-weight: 500;
}

.progressLabel {
  font-size: 12px;
  color: var(--GRAY-MID);
}

.actions {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 16px;
}

.btns {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.btn:global(.ui.button) {
  padding: 12px 32px;
  margin-bottom: 8px;
}

.btn.pricing {
  --bg-color: #e7eaff;
  --border-color: #e7eaff;
  --text-color: var(--PRIMARY-BLUE);
  --border-hover-color: #e7eaff;
  margin-right: 4px;
}

.btn.book {
  --border-color: var(--PRIMARY-BLUE);
  --text-color: var(--PRIMARY-BLUE);
  --border-hover-color: var(--PRIMARY-BLUE);
  margin-left: 4px;
}
