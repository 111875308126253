.modal {
  max-width: 1000px;
  --border-radius: 16px;
}

.contents {
  display: grid;
  grid-template-rows: min-content minmax(0, auto);
  grid-template-areas: "header" "body";
}

.header {
  grid-area: header;
  padding: 0 0 21px;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--DISABLED-BORDER-COLOR);
}

.body {
  grid-area: body;
  padding: 20px 0 10px;
  max-height: 60vh;
  overflow: hidden auto;
}
