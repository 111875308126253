.ways {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 21px;
  margin-bottom: 28px;
}

.way {
  --itemColor: var(--GRAY-MID);
  outline: 1px solid var(--DISABLED-BORDER-COLOR);
  border-radius: 8px;
  padding: 21px 21px 42px;
  cursor: pointer;
}

.way.selected,
.way:hover {
  outline: 2px solid var(--PRIMARY-BLUE);
}

.actions {
}

.header {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 7px;
}
