.container .hasIcons {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.container a {
  text-decoration: none;
  color: unset !important;
}

.container th:last-child {
  padding-right: 9px;
}

.actionTh {
  text-align: center;
  width: 110px;
  min-width: 110px;
  max-width: 110px;
  padding: 11px !important;
}

.actionTh a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.actionTh a :global(.ui.mini.image) {
  width: 20px;
  height: 20px;
  margin-bottom: 2px;
}
