.title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 7px;
}

.content {
  font-size: 14px;
  margin-bottom: 14px;
  color: var(--GRAY-MID);
}

.buttonContainer {
  color: var(--PRIMARY-BLUE);
}

.buttonClickArea {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
}
.buttonClickArea:hover {
  text-decoration: underline;
}

.redirectIcon {
  margin-left: 8px;
  --color: var(--PRIMARY-BLUE);
}
