.linkWithIcon {
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  margin: 0 1.5px;
}

.linkWithIcon span {
  margin-right: 6px;
}
