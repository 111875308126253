.container:global(.hide-scrollable-table) {
  flex: 0 0 100% !important;
  min-width: 0px !important;
  padding: 0px !important;
  display: grid;
  height: 100%;
  overflow: auto;
  border-radius: 0px !important;
}
.container:global(.hide-scrollable-table .stick-wrap) {
  overflow: auto;
}
.table thead tr {
  display: table-row;
}

.table:global(.ui.table.very.basic) thead:first-child tr th {
  position: sticky !important;
}
.table:global(.ui.table.very.basic) thead tr th {
  border-left: none;
  border-right: none;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  font-weight: 500;
  top: 0;
  z-index: 5;
  padding: 11px 15px 11px 20px;
  background-color: #f6f5f5;
  color: #9aa8bd;
  white-space: nowrap;
  text-transform: uppercase;
}

.table:global(.ui.table.very.basic) thead tr:first-child th {
  padding-top: 11px;
}
.table:global(.ui.table.very.basic) thead tr th:first-child {
  max-width: 97px;
  padding-left: 20px;
}
.table:global(.ui.table.very.basic) td:global(.checkbox) {
  padding-left: 20px;
}

.checkboxWrap {
  width: auto;
}
.checkboxWrap .checkbox {
  min-height: 20px;
  padding-left: 20px;
  flex: 0 0 20px;
}

.table:global(.ui.table.very.basic) td {
  text-align: left;
  max-width: 35px;
  word-break: break-all;
}
.table:global(.ui.table.very.basic) .downloadCell,
.table:global(.ui.table.very.basic) .forwardCell {
  text-align: center;
  width: 110px;
  min-width: 110px;
  max-width: 110px;
  padding: 11px;
}

.table:global(.ui.table.very.basic) .downloadCell img,
.table:global(.ui.table.very.basic) .forwardCell img {
  display: inline-block;
}

.downloadCell :global(.ui.mini.image) {
  width: 26px;
  height: 26px;
}
.forwardCell :global(.ui.mini.image) {
  width: 26px;
  height: 26px;
}

.table:global(.ui.table.very.basic) td:last-child {
  padding-right: 9px;
}

.noDataHint {
  padding: 11px 11px 11px 20px;
}
.file {
  min-height: 100px;
  min-width: 100px;
  max-height: 235px;
  background: #f8f8f8;
  align-items: center;
  overflow: hidden;
  padding: 0 10px;
  justify-content: center;
  display: flex;
  text-overflow: ellipsis;
}
