.stepper {
}

.tier {
  display: grid;
  grid-template-columns: 42px 1fr;
  grid-template-rows: 33px 1fr;
  grid-template-areas: "vertex header" "edge body";
}

.vertex {
  grid-area: vertex;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  width: 14px;
  height: 14px;
  border: 2px solid var(--FOCUS-INPUT-BORDER-BLUE);
  justify-self: left;
  align-self: end;
}

.tier.active .vertex {
  border: 2px solid var(--PRIMARY-BLUE);
  background-color: var(--PRIMARY-BLUE);
}

.stepper.fill {
}

.stepper.check .tier .vertex {
  background-color: #9aa8bd;
  border-color: #9aa8bd;
  width: 20px;
  height: 20px;
  transform: translateY(3px);
}

.stepper.check .tier.active .vertex {
  background-color: var(--PRIMARY-BLUE);
  border-color: var(--PRIMARY-BLUE);
  border: none;
}

.stepper.check .tier.active .vertex:before {
  content: url("../../../../assets/images/check-white.svg");
  transform: scale(50%);
  display: inline-block;
  flex: 0 0 auto;
}

.stepper.check .tier.error.active .vertex,
.stepper.check .tier.error .vertex {
  background-color: #ee5e74;
  border-color: #ee5e74;
  display: inline-flex;
  justify-content: center;
}

.stepper.check .tier.error.active .vertex:before,
.stepper.check .tier.error .vertex:before {
  transform: none;
  content: url("../../../../assets/images/cross-white.svg");
  display: inline-block;
}

.edge {
  grid-area: edge;
  margin: 14px 0 0;
  border-right: 1px solid var(--DISABLED-BORDER-COLOR);
  width: 1px;
  transform: translateX(7px);
}

.header {
  grid-area: header;
  display: flex;
  align-items: center;
  height: 14px;
  align-self: end;
}

.header .text {
  font-size: 16px;
  font-weight: 500;
  flex: 0 0 auto;
}

.header .icon {
  flex: 0 0 24px;
  margin-left: 7px;
  display: inline-block;
  width: 24px;
  height: 24px;
}

.header .icon img {
  max-width: 100%;
}

.body {
  grid-area: body;
  padding: 17px 0 14px;
  color: #636879;
}
