.tableWrap {
  position: relative;
}

.emptyContentWrap {
  position: absolute;
  top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
}

.emptyContentWrap :global(.empty-content) {
  position: static;
  flex: 0 1 auto;
}

.tableHeader .actions {
  margin-left: auto;
  flex: 0 0 auto;
}

.afterMainRow .menu {
  flex: 0 1 auto;
}

.afterMainRow .search {
  flex: 0 1 auto;
  margin: -7px 0 21px;
  max-width: 22em;
}

.triggerGroupFilter:global(.ui.ui.ui) {
  margin-bottom: 0;
}

.triggerGroupFilter:global(.ui.ui.ui .item) {
  min-height: 63px;
  font-size: 16px;
  font-weight: 500;
  padding: 0 42px !important;
}

.triggerTypeFilter:global(.ui.ui.ui) {
  min-height: 0;
}

.triggerTypeFilter.disabled:global(.ui.ui.ui) {
  opacity: 0.6;
  pointer-events: none;
  cursor: not-allowed;
}

.triggerTypeFilter:global(.ui.ui.ui .item) {
  padding: 7px 14px !important;
  margin-right: 12px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: var(--BLACK);
  min-height: 35px;
  border-radius: 8px;
}

.triggerTypeFilter:global(.ui.ui.ui .item):hover {
  background-color: #f6f6f6;
}

.triggerTypeFilter:global(.ui.ui.ui .item .counter) {
  margin-left: 7px;
  color: var(--BLACK);
}

.triggerTypeFilter:global(.ui.ui.ui .item.active),
.triggerTypeFilter:global(.ui.ui.ui .item.active):hover {
  color: var(--BLACK);
  background-color: #f6f5f5;
}
