.content {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #eaeaea;
  padding: 50px;
}

.nav {
  margin: 0 0 28px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.header {
  font-weight: 500;
  font-size: 28px;
  margin: 0 0 28px;
  line-height: 1.2;
}

.subheader {
  font-weight: 500;
  margin: 0 0 28px;
}

.text {
  margin: 0 0 28px;
}

.text b {
  font-weight: 500;
}

.textHeader {
  margin: 0 0 7px;
  font-weight: 500;
}

.pic {
  margin: 0 0 28px;
}

.pic figure {
  position: relative;
  display: inline-block;
}

.pic figcaption {
  position: absolute;
  bottom: -20px;
  right: -20px;
  z-index: 2;
}

.pic img {
  z-index: 0;
}

.pic figcaption img {
}

.channels {
}

.header.centered {
  text-align: center;
}

.headerPic {
  font-size: 62px;
  margin: 0 0 28px;
  text-align: center;
}

.subheaderLight {
  color: #9aa8bd;
  margin: 0 0 28px;
  text-align: center;
}

.footer {
  margin-top: 14px;
}

.footerActions {
}

.inset {
  padding: 21px 42px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  border-radius: 5px;
  margin-bottom: 21px;
}

.insetHeader {
  font-weight: 500;
  margin-bottom: 7px;
}

.insetText {
  margin-bottom: 7px;
}

.insetActions {
  margin-top: 14px;
}

.insetActions :global(.ui.button.ui.ui) {
  padding-left: 26px !important;
  padding-right: 26px !important;
  height: 33px;
}

.hint {
  font-size: 12px;
  margin-bottom: 7px;
}

.form {
  margin-bottom: 28px;
}
