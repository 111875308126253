.wrapper {
  max-width: 120px;
  position: relative;
}

.colorInput {
  width: 100%;
}

.colorIndicator {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  width: 14px;
  height: 14px;
  border-radius: 100%;
}
