.header {
  display: flex;
  flex-flow: row nowrap;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 28px;
  position: relative;
}

.header:after {
  display: block;
  height: 1px;
  content: "";
  border-bottom: 1px solid var(--GRAY-SHADOW);
  margin-left: -32px;
  margin-right: -32px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.header.tabbed {
  border-bottom: none;
  padding-bottom: 0;
}

.header.tabbed:after {
  display: none;
}

.logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #eaeaea;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.logo img {
}

.text {
  font-weight: 500;
  font-size: 20px;
  flex: 1 0 auto;
}

.url {
  color: #9aa8bd;
}

.actions {
  margin-left: auto;
  transform: translateY(10px);
  font-size: 12px;
}

.actions .delete {
  cursor: pointer;
  color: #b01717;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  white-space: nowrap;
}

.actions .delete .icon {
  mask-image: url(../../../../assets/images/delete-bin.svg);
  background-color: #b01717;
  display: inline-block;
  width: 13px;
  height: 15px;
  margin-right: 7px;
}
