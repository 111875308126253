.exploreEnterpriseLabel {
  font-weight: 500;
  font-size: 20px;
}

.exploreEnterpriseDescription {
  font-size: 14px;
  margin-top: 16px;
  margin-bottom: 24px;
}

.consultUsButton {
  text-decoration: none !important;
}

.planSubscriptionExploreEnterprise {
  margin-top: 24px;
  padding: 40px 30px;
}

.exploreEnterpriseLabel {
  font-weight: 500;
  font-size: 20px;
  display: flex;
  align-items: center;
}

.lightBulbIcon {
  margin-left: 8px;
}
