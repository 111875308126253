.header {
  font-weight: 500;
  font-size: 16px;
  color: #3c4257;
}

.subHeader {
  font-size: 14px;
  color: var(--GRAY-MID);
  padding-top: 7px;
}
