.payment {
  border: 1px solid var(--DISABLED-BORDER-COLOR);
  padding: 32px 34px;
  border-radius: 14px;
}

.payment :global(.ui.loader):before {
  border-color: var(--DISABLED-BORDER-COLOR) !important;
}

.payment :global(.ui.loader):after {
  border-color: var(--GRAY-MID) transparent transparent !important;
}

.row .radio :global(input:checked ~ label::before) {
  border-color: var(--PRIMARY-BLUE) !important;
  top: 1px;
  border-radius: 100%;
}

.row.discount {
  padding-top: 14px;
}

.row {
  padding-bottom: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.row:last-child {
  padding-bottom: 0px;
}

.row label {
  font-weight: 500;
  color: var(--GRAY-MID);
}

.row .percentage {
  width: 148px;
}

.row .percentage :global(input) {
  padding-right: 60px;
  text-align: center;
}

.row .percentage::after {
  content: "% off";
  position: absolute;
  right: 16px;
  display: flex;
  align-items: center;
  height: 100%;
}

.row .fixed :global(input) {
  text-align: center;
  padding-left: 41px;
}

.row .fixed::before {
  content: "$";
  position: absolute;
  left: 16px;
  display: flex;
  align-items: center;
  height: 100%;
}

.row .fixed {
  width: 148px;
}

.row .radio {
  min-height: 20px !important;
}

.row .radio :global(label) {
  padding-left: 31px;
  font-weight: 400;
}

.row .amount {
  font-weight: 500;
}

.row.totalAmount {
  padding-top: 8px;
}

.row.totalAmount .amount {
  font-weight: 500;
  font-size: 16px;
}

.row.row .radio :global(input ~ label::before) {
  border-radius: 100%;
}

.row.row .radio :global(input:checked ~ label::after) {
  color: #fff;
  content: "" !important;
  font-size: 14px;
  height: 8px;
  width: 8px;
  top: 7px;
  left: 6px;
  background-color: var(--WHITE) !important;
}

.row.withNumber {
  display: grid;
  grid-template-areas:
    "radio numberInput"
    ". error";
  grid-template-columns: 1fr 148px;
  padding-bottom: 8px;
}

.row.row.withNumber .radio {
  grid-area: radio;
}

.row.withNumber .percentage {
  grid-area: numberInput;
}

.row.withNumber .fixedInput {
  grid-area: numberInput;
}

.row.withNumber .error {
  grid-area: error;
}

.row.withNumber .error:global(.field-error.visible) {
  height: auto;
}

.row .popup {
  width: 300px;
}
