.typeIcon {
  padding: 11px;
  border-radius: 50%;
  margin-right: 9px;
  background-position: center;
}

.shopifyIcon {
  --height: 19.2px;
  --image: url("../../../../component/SignUp/assets/shopify.svg");
  --width: 19.2px;
  border: 1px solid var(--DISABLED-BORDER-COLOR);
}

.customIcon {
  --image: url("./assets/custom-store.svg");
  --width: 24px;
  --height: 24px;
  background-size: 14px 14px;
  background-color: var(--DISABLED-BORDER-COLOR);
}
