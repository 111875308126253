.tag {
  background-color: #e9f1f8;
  color: #5ebada;
}

.content {
  display: flex;
  padding: 0 50px 0 70px;
}

.steps {
  margin: 0 40px;
  flex: 1;
}

.step {
  padding: 18px;
  padding-left: 3px;
  font-size: 16px;
  font-weight: 500;
  color: var(--GRAY-MID);
  position: relative;
  display: flex;
  align-items: center;
}

.step .dot {
  width: 7px;
  height: 7px;
  border-radius: 7px;
  background-color: var(--GRAY-MID);
  margin-right: 20px;
}

.step .check {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  padding: 4px;
  background-color: var(--PRIMARY-BLUE);
  margin-right: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -6px;
}

.step::before {
  content: "";
  width: 1px;
  height: calc(50% - 12px);
  position: absolute;
  left: 6px;
  top: 0;
  background-color: var(--GRAY-MID);
}

.step::after {
  content: "";
  width: 1px;
  height: calc(50% - 12px);
  position: absolute;
  left: 6px;
  top: calc(50% + 12px);
  background-color: var(--GRAY-MID);
}

.step:first-of-type::before {
  display: none;
}

.step:last-of-type::after {
  display: none;
}

.step.active {
  background-color: #e7eaff;
  color: var(--BLACK);
  margin: 8px 0 8px -20px;
  padding-left: 20px;
}

.step.active .dot {
  background-color: var(--PRIMARY-BLUE);
}

.step.active::before,
.step.active::after {
  display: none;
}

.step.completed::before,
.step.completed::after {
  background-color: var(--PRIMARY-BLUE);
  height: calc(50% - 18px);
}

.step.completed::after {
  top: calc(50% + 18px);
}

.step .button {
  margin-top: 12px;
}

.list {
  padding-left: 60px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  position: relative;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.title::before {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 7px;
  left: -24px;
  top: 50%;
  margin-top: -4px;
  background-color: var(--PRIMARY-BLUE);
}

.desc {
  font-size: 14px;
  margin-bottom: 24px;
}

.list .button {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.list .button .helper {
  font-size: 14px;
  color: var(--GRAY-MID);
  margin-left: 24px;
}
