.incomingMessage {
  --image: url("./assets/trigger/incomingMessage.svg");
  --width: 40px;
  --height: 40px;
}

.schedule {
  --image: url("./assets/trigger/schedule.svg");
  --width: 40px;
  --height: 40px;
}

.updatedContact {
  --image: url("./assets/trigger/updatedContact.svg");
  --width: 40px;
  --height: 40px;
}

.shopifyAbandonedCart {
  --image: url("./assets/trigger/shopify/abandonedCart.svg");
  --width: 40px;
  --height: 40px;
}

.shopifyFulfillmentUpdate {
  --image: url("./assets/trigger/shopify/fulfillmentUpdate.svg");
  --width: 40px;
  --height: 40px;
}

.shopifyOrderUpdate {
  --image: url("./assets/trigger/shopify/orderUpdate.svg");
  --width: 40px;
  --height: 40px;
}

.shopifyUpdatedCustomers {
  --image: url("./assets/trigger/shopify/updatedCustomers.svg");
  --width: 40px;
  --height: 40px;
}

.facebookPostComment {
  --image: url("./assets/trigger/facebookPostComment.svg");
  --width: 40px;
  --height: 40px;
}

.instagramPostComment {
  --image: url("./assets/trigger/instagramPostComment.svg");
  --width: 40px;
  --height: 40px;
}

.customize {
  --image: url("./assets/customizeRule.svg");
  --width: 40px;
  --height: 40px;
}

.template {
  --image: url("./assets/applyTemplate.svg");
  --width: 40px;
  --height: 40px;
}

.small {
  width: 20px;
  height: 20px;
  --width: 20px;
  --height: 20px;
}

.small.shifted {
  transform: translate(2px, 2px);
}
