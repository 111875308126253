.buttons {
  --iconColor: #9aa8bd;
  gap: 8px 6px;
  margin: 14px 0 0 auto;
  display: flex;
  flex-direction: column;
}

.buttons.any {
  grid-template-columns: 1fr;
}

.buttons.three {
  grid-template-columns: 1fr 1fr;
}

.buttons :global(.ui.button) {
  margin: 0;
}
.buttons.three .last {
  grid-column: 1 / -1;
}

.button :global(._iconGlobalOverride) {
  margin-left: 5px;
}

.buttons .button {
  width: 280px;
  border: 1px solid var(--DISABLED-BORDER-COLOR) !important;
  text-align: center;
  padding: 10px 0 !important;
  font-weight: 400 !important;
  justify-content: center;
  line-height: 1.6 !important;
}
