.root {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  flex-flow: column nowrap;
}

.pic {
  margin: 0 0 16px;
}

.pic img {
  user-select: none;
}

.header {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 8px;
}
.text {
  font-size: 1em;
}

.actions {
  padding: 16px 0 0;
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  align-items: center;
  justify-content: center;
}
